<template>
  <form
    style="display: contents"
    @submit.prevent="save"
  >
    <BcfDialog
      ref="dialog"
      v-dialog-config="{
        overlay,
        beforeShow: onShow
      }"
      :overlay="overlay"
      wide
      :title="$t(`account.account.myOrders.designChange.title`)"
    >
      <div
        style="padding-bottom: 1em;"
      >
        <template v-if="o">
          <div
            style="padding-bottom: 1em;"
          >
            <div
              class="section__image"
            >
              <Badge>
                {{ $t('account.account.myOrders.designChange.currentdesign') }}
              </Badge>
              <img
                :src="o.orderline.image ? o.orderline.image : `https://images.cyclingfactory.be/${o.orderline.externalid}_image_400x.jpg`"
                :alt="o.orderline.externalid"
                @error="imgFailed"
              >
            </div>
            <div
              v-if="order.newconfigimage"
              class="section__image"
              style="text-align: center;"
            >
              <img
                src="@/assets/img/down.png"
                style="width:35px;height:35px; margin-right:2px"
              >
            </div>
            <div
              v-if="order.newconfigimage"
              class="section__image"
            >
              <Badge>
                {{ $t('account.account.myOrders.designChange.newdesign') }}
              </Badge>
              <img
                :src="order.newconfigimage"
                :alt="o.orderline.externalid"
                @error="imgFailed"
              >
            </div>
            <div class="section__title">
              <h1>
                {{ $t('account.account.myOrders.designChange.subTitle') }}
              </h1>
            </div>
            <p>
              <small
                style="color: #8d99af;"
                v-html="$t('account.account.myOrders.designChange.explanation')"
              />
            </p>
          </div>
          <span class="row m-t-5 m-l-2" />
          <div class="row">
            <div
              v-if="!getstatus"
              class="form-group col"
            >
              <button
                class="button button--primary m-r-6 m-l-2"
                @click.prevent="changeDesign()"
              >
                {{ $t('account.account.myOrders.designChange.button') }}
              </button>
            </div>
          </div>

          <div class="row">
            <div
              v-if="!getstatus"
              class="form-group col"
              style="text-align: center;"
            >
              <strong>{{ $t('account.account.myOrders.designChange.or') }}</strong>
            </div>
          </div>
          <div class="row">
            <div
              class="form-group col"
            >
              <div style="white-space: nowrap">
                <b-form-input
                  v-model="order.newconfigurationId"
                  type="text"
                  :readonly="getstatus"
                  :placeholder="$t('account.account.myOrders.designChange.fieldplaceholder')"
                  style="width: 60%; vertical-align: middle; margin-left: 2em; margin-bottom: 0em;"
                />
                <button
                  class="button button--outline m-r-6 m-l-2 m-t-1"
                  style="width: 20%;"
                  :disabled="!hasValue"
                  @click.prevent="previewDesign()"
                >
                  {{ $t('account.account.myOrders.designChange.preview') }}
                </button>
              </div>
              <div
                class="form-group col m-t-2"
              >
                <div
                  v-if="errorMessage"
                  style="width: 94%"
                >
                  <Message
                    type="error"
                    :icon="true"
                    :content="$t(`account.account.myOrders.designChange.${errorMessage}`)"
                  />
                </div>
              </div>
              <div
                class="form-group col"
              >
                <div
                  v-if="successMessage"
                  style="width: 94%"
                >
                  <Message
                    type="success"
                    :icon="true"
                    :content="$t(`account.account.myOrders.designChange.${successMessage}`)"
                  />
                </div>
                <div
                  v-if="!getstatus && (order.newconfigurationId !== '')"
                  style="width: 94%"
                >
                  <Message
                    type="warning"
                    :icon="true"
                    :content="$t(`account.account.myOrders.designChange.confirmationContent`)"
                    :show-close="true"
                  >
                    <strong>{{ $t(`account.account.myOrders.designChange.confirmationTitle1`) }}</strong> {{ $t(`account.account.myOrders.designChange.confirmationTitle2`) }}
                  </Message>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <template v-slot:footer>
        <button
          v-if="getstatus"
          class="button"
          type="button"
          @click="close"
        >
          {{ $t('general.close') }}
        </button>
        <button
          v-if="!getstatus"
          class="button"
          type="button"
          @click="close"
        >
          {{ $t('general.cancel') }}
        </button>
        <button
          v-if="!getstatus"
          class="button button--primary"
          type="submit"
          @click.prevent="save"
        >
          {{ $t('general.apply') }}
        </button>
      </template>
    </BcfDialog>
  </form>
</template>
<script>

import {
  BFormInput,
} from 'bootstrap-vue';
import {
  GetItemByid, GetCustomizerModel, AddChangeDesignRequest,
} from '@/api/api';
import Message from '@/components/Message.vue';
import Badge from '@/elements/Badge.vue';
import BcfDialog from '@/components/dialog/bcfDialog.vue';

export default {
  components: {
    BFormInput,
    Message,
    Badge,
    BcfDialog,
  },
  props: {
    overlay: { // This provides us control over the modal when spawned via dialogs.show(...)
      type: Object,
      default: () => null,
    },
    o: { // Order
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      order: this.toOrder(this.o || {}),
      errorMessage: '',
      successMessage: '',
      requestSent: false,
      previewActive: false,
    };
  },
  computed: {
    getuser() {
      return this.auth.user.id;
    },
    getstatus() {
      return this.requestSent;
    },
    isPreviewActive() {
      return this.previewActive;
    },
    hasValue() {
      return this.order.newconfigurationId !== '';
    },
  },
  watch: {
    o(val, oldVal) {
      if (val !== oldVal) {
        this.order = this.toOrder(val);
      }
    },
  },
  mounted() {

  },
  methods: {
    async onShow() {
      if (this.$store.state.global.previousRoute) {
        const comingFromConfigurator = this.$store.state.global.previousRoute.includes('configurator');
        if (this.o.newconfigid && !this.o.designchange && !this.o.designdenied && !comingFromConfigurator) {
          this.requestSent = true;
        }
      } else {
        this.requestSent = true;
      }

      if (this.o.newconfigid) {
        this.order.newconfigurationId = this.o.newconfigid;
        const configuration = await GetCustomizerModel(this.order.newconfigurationId);
        this.order.newconfigimage = configuration.images.frame['1x'];
      }
    },
    close(result, source = undefined) {
      if (!result) this.order.newconfigurationId = '';
      return this.overlay.close(result || this, source);
    },
    toOrder(oVal) {
      const o = oVal || {};
      return {
        header: {
          orderid: o.orderid,
          externalorderid: o.externalorderid,
          externalcustomerid: o.externalcustomerid,
          customerid: o.customerid,
        },
        line: o.orderline,
        newconfigurationId: '',
        newconfigimage: undefined,
        user: this.$store.state.auth.user.id, // this.auth.user.id,
      };
    },
    async previewDesign() {
      // console.log(this.order.line.model);
      // console.log(this.order.line.size);
      // const item = await GetItemByid(this.order.line.itemid, 'en', this.order.header.customerid);
      if (this.order.newconfigurationId) {
        const configuration = await GetCustomizerModel(this.order.newconfigurationId);
        if (!configuration) {
          this.errorMessage = 'noSuchConfigurationId';
        } else if (configuration.model.toUpperCase() !== this.order.line.model.split(' ').join('_').replace(/-/g, '_').toUpperCase()) {
          this.errorMessage = 'differentModel';
        } else if (configuration.localizedconfiguration.R020_Size.code.toUpperCase() !== this.order.line.size.toUpperCase()) {
          this.errorMessage = 'differentSize';
        } else {
          if ((configuration.images !== null) && (configuration.images.hasOwnProperty('frame'))) {
            this.order.newconfigimage = configuration.images.frame['1x'];
            this.errorMessage = '';
          } else {
            this.errorMessage = 'noImage';
          }
          if ((this.order.newconfigimage !== undefined || this.o.newconfigid === undefined) && (configuration.images !== null) && (configuration.images.hasOwnProperty('frame'))) {
            this.previewActive = true;
          }
        }
      }
    },
    async changeDesign() {
      const { model, size, customizerAvailable } = this.order.line;
      if (model && size && customizerAvailable) {
        const cleanupModel = model.split(' ').join('_').replace(/-/g, '_');
        this.$router.push(`/items/configurator/${cleanupModel}?colorchange=true&size=${size}&o=${this.order.header.externalorderid}&l=${this.order.line.orderline}`);
        this.close(true, 'redirected');
      } else {
        alert('No model or size supplied for this article! Requesting a desgn change is not possible.');
      }
    },
    async save() {
      this.errorMessage = '';
      this.successMessage = '';
      const { model, size } = this.order.line;
      const configuration = await GetCustomizerModel(this.order.newconfigurationId);

      if (!configuration) {
        this.errorMessage = 'noSuchConfigurationId';
      } else if (configuration.model.toUpperCase() !== model.split(' ').join('_').replace(/-/g, '_').toUpperCase()) {
        this.errorMessage = 'differentModel';
      } else if (configuration.localizedconfiguration.R020_Size.code.toUpperCase() !== size.toUpperCase()) {
        this.errorMessage = 'differentSize';
      } else {
        // Save to table & display success message
        const result = await AddChangeDesignRequest(this.order);
        if (result.status === 200) {
          this.successMessage = 'succesfullySavedRequest';
          this.order.newconfigurationId = '';
          this.requestSent = true;
          this.order.newconfigurationId = this.o.newconfigid;
          this.$emit('fetchData');
          this.close(this);
        }
      }
    },
    imgFailed(event) {
      if (!event.target.failed) {
        event.target.failed = true;
        // eslint-disable-next-line global-require
        event.target.src = require('@/assets/img/product_placeholder.jpg');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";

@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.dialog-overlay__modal__footer{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.section{
  margin-bottom: 2em;
}

.section__reference{
  &:not(:last-child) {
    > div:last-child {
      margin-bottom: 1.5em;
      padding-bottom: 1.5em;
      border-bottom: 1px solid $light-grey;
    }
  }
}

.section__title{
  display: flex;
  flex-direction: row;

  >h1,h2,h3,h4,h5{
    flex: 1 0 auto;

    margin-bottom: 1.5rem;
  }

  >.section__control{
    flex: 0 0 auto;
  }
}

.section__image{
  >img{
    max-width:100%;
    width: 100%;
    object-fit: contain;
    max-height: 20em;
  }
}

.quantity_input{
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
