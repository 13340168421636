<template>
  <BcfDialog
    ref="dialog"
    v-dialog-config="{
      overlay,
      beforeClose: beforeClose // Prevent unsaved changes from getting lost
    }"
    class="assign_customer_dialog"
    :overlay="overlay"
    wide
    :title="orderline && orderline.description"
  >
    <div
      v-if="orderline"
      class="content__wrapper"
    >
      <div class="section__title">
        <h1>
          {{ $t('account.account.myOrders.orderItemReference.title') }}
        </h1>
      </div>
      <p class="section__description">
        {{ $t('account.account.myOrders.orderItemReference.explanation') }}
      </p>
      <orderline-reference
        :reference="reference"
        :order="order"
        :orderline="orderline"
        :item-index="itemIndex"
        @input="updateReferencesList($event)"
      />
      <Message
        v-if="errorMessage"
        type="error"
      >
        {{ errorMessage }}
      </Message>
      <usps
        :title="$t('account.account.myBackOrders.assign.usps.title')"
        :usps="usps"
      />
    </div>
    <template v-slot:footer>
      <button
        class="button"
        type="button"
        @click="close"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        class="button button--primary"
        type="submit"
        @click.prevent="save"
      >
        <loading-overlay :loading="isLoading">
          {{ $t('general.save') }}
        </loading-overlay>
      </button>
    </template>
  </BcfDialog>
</template>
<script>

import Message from '@/components/Message.vue';
import BcfDialog from '@/components/dialog/bcfDialog.vue';
import dialogs from '@/utils/dialogs';
import OrderlineReference from '@/views/account/account/mybackorders/orderlineReference.vue';
import { UpdateOrderlineReferences } from '@/api/api';
import { isEqual } from '@/utils/object';
import { relationOfReference } from '@/views/account/account/mycustomers/utils';
import loadingOverlay from '@/elements/LoadingOverlay.vue';
import usps from '@/components/usps.vue';

export default {
  components: {
    Message,
    BcfDialog,
    OrderlineReference,
    loadingOverlay,
    usps,
  },
  props: {
    overlay: { // This provides us control over the modal when spawned via dialogs.show(...)
      type: Object,
      default: () => null,
    },
    order: {
      type: Object,
      required: true,
    },
    orderline: {
      type: Object,
      required: true,
    },
    itemIndex: {
      type: Number,
      required: true,
    },
    references: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      errorMessage: null,
      referencesList: this.references ? [...this.references] : [],
    };
  },
  computed: {
    reference() {
      return this.referencesList.find((ref) => ref.index === this.itemIndex + 1) || {};
    },
    usps() {
      return [
        {
          icon: 'award',
          content: this.$t('account.account.myBackOrders.assign.usps.registration'),
        },
        {
          icon: 'clock',
          content: this.$t('account.account.myBackOrders.assign.usps.warranty'),
        },
        {
          icon: 'envelope-info',
          content: this.$t('account.account.myBackOrders.assign.usps.updates'),
        },
      ];
    },
  },
  mounted() {
  },
  methods: {
    updateReferencesList(updatedReference) {
      const targetIndex = this.referencesList.findIndex((ref) => ref.index === updatedReference.index);

      if (updatedReference.firstName || updatedReference.lastName || updatedReference.email || updatedReference.phone) {
        if (targetIndex === -1) {
          this.referencesList.push(updatedReference);
        } else {
          this.referencesList[targetIndex] = updatedReference;
        }
      } else {
        this.referencesList.splice(targetIndex, 1);
      }
    },
    async beforeClose(result, source) {
      if (source === 'dismiss') {
        const a = this.references;
        const b = this.referencesList;

        if (!isEqual(a, b)) {
          const confirmed = await dialogs.show({
            title: this.$t('general.unsavedChanges.title'),
            content: this.$t('general.unsavedChanges.content'),
            type: 'cancelYes',
          });
          if (confirmed) {
            return true;
          }
          return false;
        }
      }
      return undefined;
    },
    close(result, source = 'cancel') {
      return this.overlay.close(result, source);
    },
    async save() {
      this.isLoading = true;
      this.errorMessage = null;

      try {
        const cid = this.$store.state.customer.selectedCustomerId;
        const { order_externalid: orderid, orderline: orderlineid } = this.orderline;

        const itemsBySerial = new Map((this.orderline.items || []).map((i) => [i.serial, i]));
        await UpdateOrderlineReferences({ cid }, [{
          orderid,
          orderline: orderlineid,
          items: this.referencesList.map((ref) => {
            // Clean up opts, only pass on values that were actually changed
            const { opts } = ref;
            const rel = relationOfReference(ref);
            const item = itemsBySerial.get(ref.serial);
            const changedOpts = {
              sendEmail: opts?.sendEmail,
            };
            if (item?.registration?.purchaseDate !== opts?.purchaseDate && rel) {
              changedOpts.purchaseDate = opts?.purchaseDate;
            }
            if (opts?.stockStatus && (
                item?.stock?.status !== opts?.stockStatus
                || (item?.stock?.address?.externalid !== opts?.stockAddress)
            )) {
              changedOpts.stockStatus = opts?.stockStatus;
              changedOpts.stockAddress = opts?.stockAddress;
            }

            return {
              ...ref,
              opts: changedOpts,
            };
          }),
        }]);

        this.$emit('save', this.referencesList);// Still needed?
        this.$emit('change', this.referencesList);
        this.close(this.referencesList, 'save');
      } catch (err) {
        this.errorMessage = err.message;
      } finally {
        this.isLoading = false;
      }
    },
    imgFailed(event) {
      if (!event.target.failed) {
        event.target.failed = true;
        // eslint-disable-next-line global-require
        event.target.src = require('@/assets/img/product_placeholder.jpg');
      }
    },
  },
};

</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";

@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.dialog-overlay__modal__footer{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.content__wrapper {
  padding-bottom: 1em;
}

.section{
  margin-bottom: 2em;
}

.section__title{
  display: flex;
  flex-direction: row;

  >h1,h2,h3,h4,h5{
    flex: 1 0 auto;

    margin-bottom: 1.5rem;
  }

  >.section__control{
    flex: 0 0 auto;
  }
}

.section__description {
  color: #8d99af;
}

.quantity_input{
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
