/* eslint-disable no-nested-ternary */
<template>
  <form
    style="display: contents"
    @submit.prevent="save"
  >
    <BcfDialog
      ref="dialog"
      v-dialog-config="{
        overlay,
        beforeClose: beforeClose // Prevent unsaved changes from getting lost
      }"
      :overlay="overlay"
      wide
      :title="orderline && orderline.description"
    >
      <div
        v-if="orderline"
        style="padding-bottom: 1em;"
      >
        <div
          class="section__image"
        >
          <img
            :src="orderline.image ? orderline.image : `https://images.cyclingfactory.be/${orderline.externalid}_image_400x.jpg`"
            :alt="orderline.externalid"
            @error="imgFailed"
          >
        </div>
        <div class="section__title">
          <h1>
            {{ $t('account.account.myOrders.orderItemReference.title') }}
          </h1>
        </div>
        <div>
          <small v-html="$t('account.account.myOrders.orderItemReference.state', {max: orderline.qty, cur: total})" />
        </div>
        <p>
          <small style="color: #8d99af;">
            {{ $t('account.account.myOrders.orderItemReference.explanation') }}
          </small>
        </p>
        <Message
          v-if="alert"
          type="warning"
          :icon="true"
          :content="$t('general.alertLimit', {total: total, totalItems: model.length})"
          :show-close="true"
        >
          <strong>{{ $t('general.titleAlert') }}</strong>
        </Message>
        <div class="section">
          <div
            v-for="olRef in model"
            :key="olRef.key"
            style="display: contents"
            class="section__reference"
          >
            <div
              class="section__title"
            >
              <!-- This div used to only conditionally show v-if="model.length>1 || olRef.serial", no longer seems usefull -->
              <h3>
                <i
                  v-if="olRef.serial"
                  class="uil uil-pricetag-alt"
                />
                <i
                  v-else
                >#</i>
                {{ olRef.serial || `${olRef.index}` }}
              </h3>

              <div>
                <button
                  class="button"
                  type="button"
                  style="padding: 1.5rem;"
                  @click="deleteReference(olRef)"
                >
                  <i class="uil uil-trash" />
                </button>
              </div>
            </div>

            <div class="form-group">
              <label :for="`assignNew_relation`">
                {{ $t('account.account.myCustomers.relation') }}
              </label>

              <relation-input
                :value="relationOfReference(olRef)"
                :state="validationState('email', olRef)"
                @input="setRelationOfReference(olRef, $event)"
              />
            </div>

            <div
              v-if="canSendEmail(olRef)"
              class="form-group form-group--checkbox"
            >
              <checkbox
                v-model="olRef.opts.sendEmail"
              >
                {{ $t("account.account.myCustomers.assignSendEmail") }}
              </checkbox>
            </div>

            <div class="form-group">
              <label :for="`${olRef.key}_quantity`">
                {{ $t('general.quantity') }}
              </label>
              <b-input-group
                :class="{
                  'form-control': true,
                  // 'is-valid': validationState('quantity', olRef)===true,
                  // 'is-invalid': validationState('quantity', olRef)===false,
                }"
                style="width: 50%;"
              >
                <b-input-group-prepend>
                  <button
                    class="button button--outline"
                    :disabled="olRef.quantity <= 1"
                    @click.prevent="decrement(olRef.key)"
                  >
                    -
                  </button>
                </b-input-group-prepend>
                <b-form-input
                  :id="`${olRef.key}_quantity`"
                  v-model="olRef.quantity"
                  class="quantity_input"
                  style="flex: 1 0 auto; width: auto"
                  :state="validationState('quantity', olRef)"
                  type="number"
                  min="1"
                  :max="orderline.qty - total - Number.parseInt(olRef.quantity)"
                  @input="onAmountUpdate(olRef)"
                />
                <b-input-group-append>
                  <button
                    class="button button--outline"
                    :disabled="total >= orderline.qty"
                    @click.prevent="increment(olRef.key)"
                  >
                    +
                  </button>
                </b-input-group-append>
              </b-input-group>
            </div>

            <div
              v-if="olRef.serial"
              class="form-group"
            >
              <label for="stockStatus">
                {{ $t('account.account.myCustomers.setStockStatus') }}
              </label>

              <status-select
                id="stockStatus"
                v-model="olRef.opts.stockStatus"
                :statuses="relationOfReference(olRef)?stockStatusses.assigned :stockStatusses.unassigned"
              />
            </div>

            <div
              v-if="olRef.serial && ['available','reserved'].includes(olRef.opts.stockStatus)"
              class="form-group"
            >
              <location-select
                v-if="locations.length>1"
                class="location-select"
                :value="olRef.opts.stockAddress"
                @input="selectStockLocation(olRef, $event)"
              />
            </div>

            <div
              v-if="olRef.opts.stockStatus === 'sold' && olRef.serial && olRef.email"
              class="form-group"
            >
              <label>
                {{ $t('account.account.myCustomers.purchaseDate') }}
              </label>

              <datepicker
                v-model="olRef.opts.purchaseDate"
                maximum-view="month"
                :language="lang"
                :format="dateFormatter"
              />
            </div>

            <div class="form-group">
              <label :for="`${olRef.key}_note`">
                {{ $t('account.account.myOrders.notes') }}
              </label>
              <textarea
                :id="`${olRef.key}_note`"
                v-model="olRef.note"
                rows="2"
                class="form-control"
                maxlength="255"
              />
            </div>
          </div>

          <button
            :disabled="total >= orderline.qty"
            class="button button--outline w-100 m-b-2"
            type="button"
            @click.prevent="addReference"
          >
            +
          </button>
        </div>

        <Message
          v-if="errorMessage"
          type="error"
        >
          {{ errorMessage }}
        </Message>
      </div>
      <template v-slot:footer>
        <button
          class="button"
          type="button"
          @click="close"
        >
          {{ $t('general.cancel') }}
        </button>
        <button
          class="button button--primary"
          type="submit"
          @click.prevent="save"
        >
          <loading-overlay :loading="isLoading">
            {{ $t('general.save') }}
          </loading-overlay>
        </button>
      </template>
    </BcfDialog>
  </form>
</template>
<script>

import {
  email,
} from 'vuelidate/lib/validators';
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BInputGroupAppend,
} from 'bootstrap-vue';
import Message from '@/components/Message.vue';
import BcfDialog from '@/components/dialog/bcfDialog.vue';
import dialogs from '@/utils/dialogs';
import { formatDate } from '@/utils/dateUtils';
import { isEqual } from '@/utils/object';
import { mapState } from 'vuex';
import { itemIdsOfsOrderline, relationOfReference } from '@/views/account/account/mycustomers/utils';
import statusSelect from '@/views/account/account/mystock/statusSelect.vue';
import { UpdateOrderlineReferences } from '@/api/api';
import loadingOverlay from '@/elements/LoadingOverlay.vue';
import checkbox from '@/elements/checkbox.vue';
import translations from '@/translations';
import Datepicker from 'vuejs-datepicker';
import RelationInput from '../mycustomers/relationInput.vue';
import LocationSelect from '../mystock/locationSelect.vue';

function normalizeInt(value) {
  if (value && typeof (value) !== 'number') {
    return Number.parseInt(value, 10);
  }

  return value;
}

// Elements
export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    Message,
    BcfDialog,
    RelationInput,
    loadingOverlay,
    checkbox,
    statusSelect,
    Datepicker,
    LocationSelect,
  },
  props: {
    overlay: { // This provides us control over the modal when spawned via dialogs.show(...)
      type: Object,
      default: () => null,
    },
    order: {
      type: Object,
      default: () => null,
    },
    orderline: { // Orderline
      type: Object,
      default: () => {
      },
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    return {
      today,
      model: [],
      isLoading: false,
      errorMessage: null,
      stockStatusses: {
        assigned: ['sold', 'reserved'],
        unassigned: ['available', 'sold', 'hidden', 'reserved'],
      },
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language.current,
      user: (state) => state.auth.user,
      customer: (state) => state.customer,
      locations: (state) => state.customer.selectedCustomer.addresses,
    }),
    customerId() {
      return this.customer.selectedCustomerId;
    },
    orderid() {
      return this.order.externalorderid || this.order.id;
    },
    lang() {
      // Need this for the datepicker!
      return translations[this.$store.state.language.current].Datepicker;
    },
    locale() {
      return this.$store.state.language.current;
    },
    total() {
      return this.model.map((x) => (
        Number.isSafeInteger(x.quantity)
          ? Number.parseInt(x.quantity, 10)
          : 1
      ) || 1)
        .reduce((a, b) => a + b, 0);
    },
    alert() {
      if (this.total > this.orderline.qty) {
        console.log({
          total: this.total,
          qt: this.orderline.qty,
        });
      }
      return this.total > this.orderline.qty;
    },
  },
  validations() {
    return {
      model: {
        $each: {
          relationId: {},
          email: {
            email,
          },
          firstName: {},
          lastName: {},
          phone: {},
          note: {},
          quantity: {
            validQuantity: (valueStr) => {
              try {
                const value = Number.parseInt(valueStr, 10);
                if (!Number.isSafeInteger(value)) return false;
                return (value === 1 ? true : this.total <= this.orderline.qty);
              } catch (err) {
                return false;
              }
            },
          },
        },
      },
    };
  },
  watch: {
    value() {
      this.valueToModel();
    },
  },
  mounted() {
    this.valueToModel();
  },
  methods: {
    async beforeClose(result, source) {
      if (source === 'dismiss') {
        // const a = JSON.parse(JSON.stringify(this.model));
        // const b = JSON.parse(JSON.stringify(this.valueToModel(false)));
        const a = this.model;
        const b = this.valueToModel(false);

        if (!isEqual(a, b)) {
          const confirmed = await dialogs.show({
            title: this.$t('general.unsavedChanges.title'),
            content: this.$t('general.unsavedChanges.content'),
            type: 'cancelYes',
          });
          if (confirmed) {
            return true;
          }
          return false;
        }
      }
      return undefined;
    },
    close(result, source = 'cancel') {
      return this.overlay.close(result, source);
    },
    addReference() {
      const { order, orderline } = this;
      const allItemIds = itemIdsOfsOrderline({
        order,
        orderline,
      });

      const refs = this.model;
      const assignable = allItemIds.filter((id) => !refs.find((i) => i.serial === id.serial || (id.index >= i.index && id.index < (i.index + i.quantity))));
      const firstAvailable = assignable[0];
      const itemsBySerial = new Map((this.orderline.items || []).map((i) => [i.serial, i]));

      if (assignable.length === 0) {
        throw new Error('All assignable refs were already added!');
      }
      const { today } = this;
      const item = itemsBySerial.get(firstAvailable.serial);
      this.model = [...refs, {
        key: `${this.orderline.orderline}_${firstAvailable.index}`,
        orderline: this.orderline.orderline,
        index: firstAvailable.index,
        firstName: '',
        lastName: '',
        email: '',
        quantity: 1,
        serial: firstAvailable.serial,
        note: '',

        opts: {
          sendEmail: false,
          purchaseDate: item?.registration?.purchaseDate || item?.stock?.orderdate || today,
          stockStatus: item?.stock?.status || null,
          stockAddress: item?.stock?.address?.externalid,
        },
      }];
    },
    deleteReference(ref) {
      this.model = this.model.filter((x) => x !== ref);
    },
    decrement(keyValue) {
      const objIndex = this.model.findIndex((obj) => obj.key === keyValue);
      this.model[objIndex].quantity -= 1;
    },
    increment(keyValue) {
      const objIndex = this.model.findIndex((obj) => obj.key === keyValue);
      this.model[objIndex].quantity += 1;
    },
    valueToModel(apply = true) {
      const { today } = this;

      const itemsBySerial = new Map((this.orderline.items || []).map((i) => [i.serial, i]));

      const newModel = (this.value || []).map((ref) => {
        const item = itemsBySerial.get(ref.serial);
        return {
          ...ref,

          // Defaults
          opts: {
            sendEmail: false,
            purchaseDate: item?.registration?.purchaseDate || item?.stock?.orderdate || today,
            stockStatus: item?.stock?.status || (relationOfReference(item) ? 'reserved' : null),
            stockAddress: item?.stock?.address?.externalid,
          },

          // Unique key
          key: ref.index || ref.serial,
        };
      });
      if (apply) {
        this.model = newModel;
      }
      return newModel;
    },
    async save() {
      for (const olRef of this.model) {
        if (olRef.quantity && typeof (olRef.quantity) !== 'number') {
          olRef.quantity = Number.parseInt(olRef.quantity, 10);
        }
      }
      this.isLoading = true;
      this.errorMessage = null;

      console.log(this.model);

      try {
        const cid = this.customerId;
        const orderline = this.orderline.navlinenr || this.orderline.id;
        const { orderid } = this;
        const itemsBySerial = new Map((this.orderline.items || []).map((i) => [i.serial, i]));
        const result = await UpdateOrderlineReferences({ cid }, [{
          orderid,
          orderline,
          items: this.model.map((ref) => {
            // Clean up opts, only pass on values that were actually changed
            const { opts } = ref;
            const rel = relationOfReference(ref);
            const item = itemsBySerial.get(ref.serial);
            const changedOpts = {
              sendEmail: opts?.sendEmail,
            };
            if (item?.registration?.purchaseDate !== opts?.purchaseDate && rel) {
              console.log(opts?.purchaseDate);
              changedOpts.purchaseDate = opts?.purchaseDate;
            }
            if (opts?.stockStatus && (
                item?.stock?.status !== opts?.stockStatus
                || (item?.stock?.address?.externalid !== opts?.stockAddress)
            )) {
              changedOpts.stockStatus = opts?.stockStatus;
              changedOpts.stockAddress = opts?.stockAddress;
            }

            return {
              ...ref,
              opts: changedOpts,
            };
          }),
        }]);

        this.$emit('save', this.model);// Still needed?
        this.$emit('change', this.model);
        this.close(this.model, 'save');
      } catch (err) {
        this.errorMessage = err.message;
      } finally {
        this.isLoading = false;
      }
    },
    imgFailed(event) {
      if (!event.target.failed) {
        event.target.failed = true;
        // eslint-disable-next-line global-require
        event.target.src = require('@/assets/img/product_placeholder.jpg');
      }
    },
    validationState(field, ol) {
      const indexOf = ol ? this.model.findIndex((m) => m.key === ol.key) : 0;
      const olState = this.$v.model.$each[indexOf];
      const state = olState && olState[field];
      // eslint-disable-next-line no-nested-ternary
      return (state && (state.$error || state.$invalid))
        ? false // Invalid
        : !state || ([null, undefined, ''].includes(state.$model))
          ? null // Empty or unrecognized
          : true; // Valid
    },
    onAmountUpdate(olRef) {
      // Force computed properties to be recalculated (mainly this.total field
      olRef.quantity = normalizeInt(olRef.quantity);
      this.$forceUpdate();
    },
    relationOfReference,
    setRelationOfReference(olRef, newRelation) {
      const newOlRef = { ...olRef };
      const oldRelation = relationOfReference(olRef);
      if (newRelation) {
        const isNewRelation = newRelation.id !== newOlRef.relationId;
        newOlRef.relationId = newRelation.id;
        newOlRef.firstName = newRelation.firstName;
        newOlRef.lastName = newRelation.lastName;
        newOlRef.email = newRelation.email;
        newOlRef.phone = newRelation.phone;
        if (!['reserved', 'sold'].includes(newOlRef.opts.stockStatus)) {
          newOlRef.opts.stockStatus = 'reserved';
          newOlRef.opts.stockAddress = this.locations[0]?.externalid;
        }
        newOlRef.opts.sendEmail = isNewRelation;
      } else if (!newRelation) {
        newOlRef.relationId = null;
        newOlRef.firstName = null;
        newOlRef.lastName = null;
        newOlRef.email = null;
        newOlRef.phone = null;
        newOlRef.opts.sendEmail = false;

        if (oldRelation && ['reserved', 'sold'].includes(newOlRef.opts.stockStatus)) {
          newOlRef.opts.stockStatus = 'available';
        }
      }
      this.model = this.model.map((x) => (x.key === olRef.key ? newOlRef : x));
    },
    selectStockLocation(olRef, location) {
      olRef.opts.stockAddress = location;
    },
    canSendEmail(olRef) {
      if (!olRef.email) return false;
      const oldRef = (this.value || []).find((f) => olRef.index === f.index);
      if (!oldRef) return true;
      if (JSON.stringify({
        firstName: olRef.firstName,
        lastName: olRef.lastName,
        email: olRef.email,
      }) !== JSON.stringify({
        firstName: oldRef.firstName,
        lastName: oldRef.lastName,
        email: oldRef.email,
      })) return true;
      return false;
    },
    dateFormatter(date) { return formatDate(date, this.locale); },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";

@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.dialog-overlay__modal__footer{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.section{
  margin-bottom: 2em;
}

.section__reference{
  &:not(:last-child) {
    > div:last-child {
      margin-bottom: 1.5em;
      padding-bottom: 1.5em;
      border-bottom: 1px solid $light-grey;
    }
  }
}

.section__title{
  display: flex;
  flex-direction: row;

  >h1,h2,h3,h4,h5{
    flex: 1 0 auto;

    margin-bottom: 1.5rem;
  }

  >.section__control{
    flex: 0 0 auto;
  }
}

.section__image{
  >img{
    max-width:100%;
    width: 100%;
    object-fit: contain;
    max-height: 20em;
  }
}

.quantity_input{
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
