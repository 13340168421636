<template>
  <div>
    <router-view />

    <bcf-aside
      v-if="isMobile"
      v-show="mobileFiltersOpen"
    >
      <bcfAsideHeader :close="closeMobileFilters">
        {{ $t('items.filters') }}
      </bcfAsideHeader>
      <bcfAsideContent>
        <div class="products__filter">
          <my-invoices-filters v-model="filters" />
        </div>
      </bcfAsideContent>
      <bcfAsideFooter>
        <span />
        <button
          class="button button--primary"
          @click="closeMobileFilters"
        >
          {{ $t('general.apply') }}
        </button>
      </bcfAsideFooter>
    </bcf-aside>

    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <router-link :to="{name: 'account'}">
                <i class="uil uil-arrow-left" />
              </router-link>
              <h1>{{ $t('account.account.myInvoices.title') }}</h1>
              <span
                v-if="isMobile"
                class="main__title__action--right"
                @click="mobileFiltersOpen = true"
              >
                <i class="uil uil-filter" />
                <Badge v-if="filters.filters.length > 0">{{ filters.filters.length }}</Badge>
              </span>
            </div>
          </div>
        </div>
      </header>
    </div>
    <div v-if="inProgress">
      <loader class="m-b-2" />
    </div>
    <div v-if="errorMessage">
      <Message
        class="container m-t-2"
        type="error"
        :icon="true"
        :content="$t(`api.${errorMessage}`)"
        :show-close="true"
        @closeMessage="closeMessage"
      />
    </div>
    <div
      v-if="!inProgress"
      class="container m-t-2"
    >
      <div
        class="row"
      >
        <div class="col-sm-12 col-md-3">
          <my-invoices-filters
            v-if="!isMobile"
            v-model="filters"
          />
        </div>
        <div class="col-sm-12 col-md-9">
          <search
            v-model="searchVal"
            class="m-md-t-2"
          />

          <div>
            <EmptyState
              v-if="invoicesToDisplay.length === 0"
              icon="invoice"
            >
              {{ $t('account.account.myInvoices.noInvoices') }}
            </EmptyState>
            <router-link
              v-for="(invoice) in invoicesToDisplay"
              :key="invoice.externalinvoiceid"
              :to="{name: 'myInvoicesView', params: {id: invoice.externalinvoiceid}}"
              style="    text-decoration: none;"
            >
              <list-item-invoice
                :invoice="invoice.externalinvoiceid"
                :request-from="'myInvoicesView'"
              />
            </router-link>
            <pagination
              class="m-t-4 m-b-4"
              :page="page"
              :pages="pages"
              :items-per-page="itemsPerPage"
              @pageChanged="changePage"
              @itemsPerPageChanged="itemsPerPageChanged"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'; // listMyInvoices
import ListItemInvoice from '@/elements/ListItemInvoice.vue';
import loader from '@/elements/loader.vue';
import Message from '@/components/Message.vue';
import { formatDate } from '@/utils/dateUtils';
import EmptyState from '@/elements/EmptyState.vue';
import pagination from '@/components/Pagination.vue';
import search from '@/components/Search.vue';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import Badge from '@/elements/Badge.vue';

const myInvoicesFilters = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/myinvoices/filters.vue');
export default {
  name: 'MyInvoices',
  metaInfo() {
    return {
      title: this.$t('account.account.myInvoices.title'),
    };
  },
  components: {
    ListItemInvoice,
    loader,
    Message,
    EmptyState,
    pagination,
    search,
    myInvoicesFilters,
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    bcfAsideFooter,
    Badge,
  },
  data() {
    return {
      isMobile: window.innerWidth < 768,
      invoicesResult: [],
      itemsPerPage: 20,
      page: 1,
      filters: { sort: this.$route.query.sort ? this.$route.query.sort : 'invoicedate', filters: [] },
      mobileFiltersOpen: false,
    };
  },
  computed: {
    ...mapState({
      errorMessage: (state) => state.myInvoices.errorMessage,
      inProgress: (state) => state.myInvoices.inProgress,
    }),
    ...mapGetters('myInvoices', {
      listMyInvoices: 'listMyInvoices',
    }),
    invoicesToDisplay() {
      return this.invoicesResult.slice(
        this.page * this.itemsPerPage - this.itemsPerPage,
        this.page * this.itemsPerPage,
      );
    },
    pages() {
      const pages = [];
      for (
        let i = 0;
        i < this.invoicesResult.length / this.itemsPerPage;
        i += 1
      ) {
        pages.push(i + 1);
      }
      return pages;
    },
    searchVal: {
      get() {
        return this.$route.query.search;
      },
      set(newVal) {
        this.$router.push({
          name: this.$router.currentRoute.name,
          query: {
            ...this.$route.query,
            search: newVal,
          },
        });
        this.page = 1;
        this.search();
      },
    },
  },
  watch: {
    filters: {
      handler() { this.search(); },
      deep: true,
    },
  },
  async mounted() {
    await this.$store.dispatch('myInvoices/get');// As discussed: reload 14/01/2020- JanVst, Nand, GunterC
    this.search();
    // this.$store.dispatch('myorders/get');
  },
  created() {
    window.addEventListener('resize', this.updateWindowSize);
    this.updateWindowSize();
  },
  destroyed() {
    window.removeEventListener('resize', this.updateWindowSize);
  },
  methods: {
    closeMobileFilters() {
      this.mobileFiltersOpen = false;
    },
    updateWindowSize() {
      this.isMobile = window.innerWidth < 768;
    },
    changePage(page) {
      this.page = parseInt(page, 10);
      this.invoicesResult.slice(
        this.page * this.itemsPerPage - this.itemsPerPage,
        this.page * this.itemsPerPage,
      );
    },
    itemsPerPageChanged(itemsPerPage) {
      this.itemsPerPage = parseInt(itemsPerPage, 10);
      this.changePage(1);
    },

    closeMessage() {
      this.$store.dispatch('myInvoices/clear');
    },
    customFormatter(date) {
      return formatDate(date);
    },
    search() {
      this.page = 1;
      const filters = {};
      this.filters.filters.forEach((f) => {
        if (!filters[f.type]) {
          filters[f.type] = [];
        }
        filters[f.type].push(f.value);
      });
      let allInvoices = this.listMyInvoices.filter((i) => {
        if (filters.status) {
          if (!filters.status.includes(i.paid)) {
            return false;
          }
        }
        return true;
      });
      console.log(this.listMyInvoices);
      // allInvoices = this.listMyInvoices;
      if (this.filters.sort === 'duedate') {
        allInvoices = allInvoices.sort((a, b) => {
          if (a.duedate > b.duedate) {
            return -1;
          } if (a.duedate < b.duedate) {
            return 1;
          }
          return 0;
        });
      }
      if (this.filters.sort === 'invoicedate') {
        allInvoices = allInvoices.sort((a, b) => {
          if (a.postingdate > b.postingdate) {
            return -1;
          } if (a.postingdate < b.postingdate) {
            return 1;
          }
          return 0;
        });
      }
      if (this.$route.query.search) {
        this.$search(this.$route.query.search, allInvoices, {
          keys: ['externalinvoiceid', 'totalincl'],
          defaultAll: true,
          caseSensitive: false,
          shouldSort: true,
          tokenize: false,
          matchAllTokens: true,
          findAllMatches: true,
          threshold: 0.6,
          location: 0,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 1,
        }).then((results) => {
          this.invoicesResult = results;
        });
      } else {
        this.invoicesResult = allInvoices;
      }
    },

  },
};
</script>
