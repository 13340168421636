<template>
  <div>
    <router-view />
    <div class="m-t-4 m-b-2 m-md-t-0 m-md-b-4">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <a
                @click="back"
              >
                <i class="uil uil-arrow-left" />
              </a>
              <h1>{{ $t('account.about.socialFeed.title') }}</h1>
            </div>
          </div>
        </div>
      </header>
    </div>

    <div v-if="!loaded">
      <loader class="m-b-2" />
    </div>
    <div v-if="loaded && errStatus">
      <Message
        class="container m-t-2"
        type="error"
        :icon="true"
        :content="errorMessage.apiMessage"
        :show-close="false"
        @closeMessage="closeMessage"
      />
    </div>
    <div
      v-if="(loaded) && (!errStatus)"
      class="container"
    >
      <div
        v-if="(socialFeeds === undefined)"
        class="row"
      >
        <div class="col">
          <EmptyState icon="award">
            {{ $t('account.about.socialFeed.nosocialfeeds') }}
          </EmptyState>
        </div>
      </div>

      <div
        v-else
        class="container"
      >
        <div class="row">
          <div
            v-for="(feed, index) in socialFeeds.data"
            :key="index"
            class="col-md-4 card-promo"
          >
            <MediaTeaser
              :url="`/account/socialFeeds/${feed.id}`"
              :date="feed.created_time"
              :title="feed.from.name"
              :summary="feed.message"
              :image="feed.full_picture?feed.full_picture:placeholder"
              :logo="require('@/assets/img/icons/Facebook_logo.svg')"
              :permalink="feed.permalink_url"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import EmptyState from '@/elements/EmptyState.vue';
import MediaTeaser from '@/components/MediaTeaser.vue';
import loader from '@/elements/loader.vue';
import Message from '@/components/Message.vue';
import { NoResponseApiError } from '@/api/apiErrors';
import { getFacebookFeeds } from '@/api/socialmedia';
import placeholder from '@/assets/img/product_placeholder.jpg';

export default {
  components: {
    EmptyState,
    MediaTeaser,
    loader,
    Message,
  },
  data() {
    return {
      errStatus: false,
      objMessage: {},
      socialFeeds: [],
      loaded: false,
      placeholder,
    };
  },
  computed: {
    ...mapState({
      selectedCustomer: (state) => state.customer.selectedCustomer,
      errorMessage: (state) => state.socialfeeds.error,
    }),
  },
  async mounted() {
    try {
      // start - loading social media feeds from store
      this.socialFeeds = await getFacebookFeeds();
      this.loaded = true;
      // End - loading social media feeds from store
    } catch (error) {
      if (error instanceof NoResponseApiError) {
        this.errStatus = true;
        this.objMessage = {
          type: 'error',
          title: 'apiNoResponseTitle',
          content: error.apiMessage,
        };
      }
    }
  },
  methods: {
    back() {
      this.$router.push(this.$store.state.global.previousRoute ? this.$store.state.global.previousRoute : { name: 'account' });
    },
    closeMessage() {
      this.loaded = true;
      this.errorMessage = '';
    },
  },
  metaInfo() {
    return {
      title: this.$t('account.about.socialFeed.title'),
    };
  },
};
</script>
