<template>
  <form
    enctype="multipart/form-data"
    @submit.prevent="sendFile"
  >
    <div
      v-if="!isUploaded"
      class="form-control-file"
    >
      <input
        ref="file"
        type="file"
        @change="selectFile"
      >
    </div>
    <div
      v-if="!isUploaded"
    >
      <small
        v-if="!$v.file.required && !message"
        class="form-text--error"
      >{{ $t(`api.singleupload`) }}</small><br>
<!--       <small><b>required:</b> {{ $v.file.required }}</small><br>
      <small><b>dirty:</b> {{ $v.file.$dirty }}</small><br>
      <small><b>error:</b> {{ $v.file.$error }}</small><br>
      <small><b>invalid:</b> {{ $v.file.$invalid }}</small> -->

      <div v-if="message && !error">
        <small>{{ file.name }}</small>
        <small class="form-text--success"> {{ $t(`api.${message}`) }}</small>
      </div>
      <div v-else-if="message && error">
        <small class="form-text--error"> {{ $t(`api.${message}`) }}</small>
      </div>
    </div>
    <div v-if="proof">
      <small>
        {{ proof }}
      </small>
    </div>
    <!--     <button
      v-if="!isUploaded || error"
      class="w-100 button button--small button--primary"
    >
      Upload file
    </button> -->
  </form>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { v4 as uuidv4 } from 'uuid';
// import uploadSingleFile from '@/api/upload';

export default {
  name: 'SimpleUpload',
  props: {
    cusId: {
      type: String,
      default: '',
    },
    proof: {
      type: String,
      default: null,
    },
    isUploaded: {
      type: Boolean,
      default: false,
    },
    multipleUploadFilenames: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      file: '',
      response: {},
      message: '',
      error: false,
    };
  },
  validations: {
    file: {
      required,
    },
  },
  methods: {
    selectFile() {
      // eslint-disable-next-line prefer-destructuring
      const file = this.$refs.file.files[0];
      const fileExt = file.name.slice(file.name.lastIndexOf('.'));
      const blob = file.slice(0, file.size, file.type);
      const newFile = new File([blob], `${this.cusId}_${uuidv4().toString()}${fileExt}`, { type: file.type });
      const allowedTypes = process.env.VUE_APP_MIMETYPE.split(',');
      const tooLarge = newFile.size > process.env.VUE_APP_MAX_SIZE;

      const isSameFilename = this.multipleUploadFilenames.includes(newFile.name.toLowerCase());
      if (allowedTypes.includes(newFile.type) && !tooLarge && !isSameFilename) {
        this.file = newFile;
        this.error = false;
        this.message = '';
        this.$emit('file-data', 'single', newFile);
      } else {
        this.error = true;
        if (isSameFilename) {
          this.message = 'LIMIT_SAME_FILENAME';
          return;
        }
        if (tooLarge) {
          this.message = 'LIMIT_FILE_SIZE';
          return;
        }
        if (!allowedTypes.includes(newFile.type)) {
          this.message = 'LIMIT_FILE_TYPES_SINGLE';
        }
      }
    },
  },
};
</script>
