import { render, staticRenderFns } from "./ListItemVoucher.vue?vue&type=template&id=c5cf8e86&scoped=true"
import script from "./ListItemVoucher.vue?vue&type=script&lang=js"
export * from "./ListItemVoucher.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5cf8e86",
  null
  
)

export default component.exports