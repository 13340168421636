<template>
  <form
      @submit.prevent="saveLocation"
  >
    <BcfDialog
      v-dialog-config="{
        overlay,
        // beforeShow: beforeShow, // Load SearchInput result
        // beforeClose: beforeClose // Prevent unsaved changes from getting lost
      }"
      wide
      :title="$t(`account.account.myStats.customLocation.title`)"
    >
      <div
        style="padding-bottom: 1em;"
      >
        <div class="section__title">
          <h2>
            {{ $t('account.account.myStats.coordinates') }}
          </h2>

          <button
            class="button button--outline section__control"
            @click.prevent="fetchGPS"
          >
            {{ $t('account.account.myStats.fromGPS') }}
            <loader v-if="loadingGPS" />
          </button>
        </div>
        <div class="row section">
          <div class="col">
            <div class="form-group">
              <label for="latitude">{{ $t('account.account.myStats.latitude') }}</label>
              <input
                id="latitude"
                v-model="latitude"
                type="number"
                class="form-control"
                :step=".0000001"
              >
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="longitude">{{ $t('account.account.myStats.longitude') }}</label>
              <input
                id="longitude"
                v-model="longitude"
                type="number"
                class="form-control"
                :step=".0000001"
              >
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section__title">
            <h2>
              {{ $t('account.account.myStats.address') }}
            </h2>
          </div>
          <div class="form-group">
            <label for="search">{{ $t('account.account.myStats.address') }}</label>
            <div class="search-group">
              <input
                id="search"
                v-model="search"
                type="text"
                class="form-control"
              >
              <button
                class="button button--outline section__control"
                @click.prevent="searchAddress"
              >
                {{ $t('account.account.myStats.search') }}
                <loader v-if="searchingAddress" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <button
          class="button button--primary"
          @click="saveLocation"
          type="submit"
        >
          {{ $t('account.account.myStats.customLocation.save') }}
        </button>
      </template>
    </BcfDialog>
  </form>
</template>
<script>
import { searchAddress, resolveAddress } from '@/api/api';
import BcfDialog from '@/components/dialog/bcfDialog.vue';
// Elements
import loader from '@/elements/loader.vue';

export default {
  components: {
    loader,
    BcfDialog,
  },
  props: {
    overlay: {
      // This provides us control over the modal when spawned via dialogs.show(...)
      type: Object,
      default: () => null,
    },
    location: {
      type: Object,
      default: () => {

      },
    },
  },
  data() {
    return {
      latitude: undefined,
      longitude: undefined,
      search: undefined,
      resolved: null,
      loadingGPS: false,
      searchingAddress: false,
    };
  },
  computed: {
  },
  mounted() {
    if (this.location) {
      this.latitude = this.location.latitude;
      this.longitude = this.location.longitude;
      this.search = this.location.formatted;// Weird
      this.resolved = this.location;
    }
  },
  methods: {
    async close(result = undefined) {
      return this.overlay.close(result);
    },
    async fetchGPS() {
      this.loadingGPS = true;
      let watchId;
      try {
        const gps = await new Promise((resolve, reject) => {
          watchId = navigator.geolocation.watchPosition((result) => {
            const timestamp = new Date(result.timestamp);
            const coordinates = [result.coords.latitude, result.coords.longitude]; // Lat then Long, is like Y then X (which may seem weird but is how google does it)

            const geoUpdate = {
              timestamp,
              coordinates,
              altitude: result.coords.altitude,
              accuracy: result.coords.accuracy,
              heading: result.coords.heading,
            };
            navigator.geolocation.clearWatch(watchId);
            resolve(geoUpdate);
          }, (err) => {
            navigator.geolocation.clearWatch(watchId);
            switch (err.code) {
              case 1:
                reject(new Error('Access to GPS was denied'));
                break;
              case 2:
                reject(new Error('Position unavailable'));
                break;
              case 3:
                reject(new Error('GPS Timeout'));
                break;
              default:
                reject(err);
            }
          }, {
            enableHighAccuracy: true,
            maximumAge: 60 * 1000, // 1min interval
          });
        });

        const [lat, lng] = gps.coordinates;
        const address = await resolveAddress({ lat, lng });
        this.search = (address && address.formatted) || '';
        this.latitude = lat;
        this.longitude = lng;
        this.resolved = address;
      } catch (err) {
        console.error(err);
      }
      this.loadingGPS = false;
    },
    saveLocation() {
      const result = {
        ...this.resolved,
        latitude: this.latitude,
        longitude: this.longitude,
        search: this.search,
      };
      this.$emit('save', result);
      this.close(result);
    },

    async searchAddress() {
      this.searchingAddress = true;
      try {
        const address = await searchAddress({ q: this.search });
        if (address && address.latitude && address.longitude) {
          this.latitude = address.latitude;
          this.longitude = address.longitude;
          this.resolved = address;
          this.search = address.formatted;
        }
      } catch (err) {
        console.error(err);
      }
      this.searchingAddress = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";

@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.dialog-overlay__modal__footer{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.section{
  margin-bottom: 2em;
}

.section__title{
  display: flex;
  flex-direction: row;

  >h1,h2,h3,h4,h5{
    flex: 1 0 auto;

    margin-bottom: 1.5rem;
  }

  >.section__control{
    flex: 0 0 auto;
  }
}

.search-group{
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
}

</style>
