<template>
  <div class="products__filter m-md-t-2">
    <fieldset>
      <collapse :open="true">
        <template v-slot:header="{open}">
          <div
            class="section-title section-title--collapse"
            :class="{'open': open}"
          >
            {{ $t('account.account.myInvoices.options') }}
          </div>
        </template>

        <div class="form-group">
          <select v-model="valueInt.sort">
            <option value="invoicedate">
              {{ $t('account.account.myInvoices.sort.invoicedate') }}
            </option>
            <option value="duedate">
              {{ $t('account.account.myInvoices.sort.duedate') }}
            </option>
          </select>
        </div>
      </collapse>
    </fieldset>
    <fieldset>
      <collapse :open="true">
        <template v-slot:header="{open}">
          <div
            class="section-title section-title--collapse"
            :class="{'open': open}"
          >
            {{ $t('account.account.myInvoices.invoiceStatus') }}
          </div>
        </template>
        <ul>
          <li>
            <checkbox
              v-model="valueInt.filters"
              :checked-value="{ type: 'status', value: false }"
            >
              <span>{{ $t('account.account.myInvoices.paid.open') }}</span>
            </checkbox>
          </li>

          <li>
            <checkbox
              v-model="valueInt.filters"
              :checked-value="{ type: 'status', value: true }"
            >
              <span>{{ $t('account.account.myInvoices.paid.paid') }}</span>
            </checkbox>
          </li>
        </ul>
      </collapse>
    </fieldset>
  </div>
</template>
<script>
import collapse from '@/components/collapse.vue';
import checkbox from '@/elements/checkbox.vue';

export default {
  components: {
    collapse,
    checkbox,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        sort: 'invoicedate',
        filters: [],
      }),
    },
  },
  computed: {
    valueInt: {
      get() {
        return this.value;
      },
      set(newval) {
        this.$emit('input', newval);
      },
    },
  },
};
</script>
