<template>
  <bcf-aside @close="close">
    <bcfAsideHeader :close="close">
      {{ $t('account.about.helpAndSupport.contactRep') }}
    </bcfAsideHeader>
    <bcfAsideContent>
      <div v-if="rep">
        <ListItem icon-before>
          <i class="uil uil-user-square" />
          {{ rep.name }}
        </ListItem>
        <ListItem
          v-if="rep.phone"
          icon-before
        >
          <i class="uil uil-phone" />
          <a :href="'tel:'+rep.phone">{{ rep.phone }}</a>
        </ListItem>
        <ListItem
          v-if="rep.email"
          icon-before
        >
          <i class="uil uil-envelope" />
          <a :href="'mailto:'+rep.email">{{ rep.email }}</a>
        </ListItem>
      </div>

      <EmptyState
        v-else
        icon="user"
      >
        {{ $t('account.about.helpAndSupport.noRep') }}
      </EmptyState>
    </bcfAsideContent>
  </bcf-aside>
</template>

<style scoped>
</style>

<script>
import { mapState } from 'vuex';
import bcfAside from '@/components/bcfAside.vue';
import EmptyState from '@/elements/EmptyState.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import ListItem from '@/elements/ListItem.vue';

export default {
  components: {
    bcfAside,
    EmptyState,
    bcfAsideHeader,
    bcfAsideContent,
    ListItem,
  },
  computed: {
    ...mapState({
      rep: state => state.customer.selectedCustomer.attributes.representative,
    }),
  },
  methods: {
    close() {
      this.$router.push({ name: 'helpAndSupport' });
    },
  },
};
</script>
