<template>
  <div
    class="list__item list__item--invoice"
    :class="classes"
  >
    <div class="list__item__icon">
      <i class="uil uil-invoice" />
    </div>
    <div class="list__item__content">
      {{ invoiceHeader[0] && invoiceHeader[0].externalinvoiceid }}
      <small>{{ $t('account.account.myInvoices.bookingsdate') }}: {{ customFormatter(invoiceHeader[0] && invoiceHeader[0].postingdate) }}</small>
    </div>
    <div class="list__item__price">
      <strong>
        <Badge
          v-if="!(invoiceHeader[0] && invoiceHeader[0].paid)"
          type="unpaid"
        >{{ $t(`account.account.myInvoices.paid.${invoiceHeader[0] && invoiceHeader[0].status}`) }}</Badge>
        <Badge
          v-else
          type="paid"
        >{{ $t(`account.account.myInvoices.paid.${invoiceHeader[0] && invoiceHeader[0].status}`) }}</Badge>
        <price
          :price="invoiceHeader[0] && invoiceHeader[0].totalincl"
          :currency="invoiceHeader[0] && invoiceHeader[0].currency"
        />
      </strong>
      <small style="text-align: right;">
        {{ `${$t('account.account.myInvoices.expirationdate')}: ${customFormatter(invoiceHeader[0] && invoiceHeader[0].duedate)} ` }}
      </small>
    </div>
  </div>
</template>

<script>
import Badge from '@/elements/Badge.vue';
import { formatPrice, floatValue } from '@/utils/pricingUtils';
import price from '@/elements/Price.vue';
import { formatDate } from '@/utils/dateUtils';

export default {
  name: 'ListItemInvoice',
  components: {
    Badge,
    price,
  },
  inheritAttrs: true,
  props: {
    invoice: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      invoiceHeader: [],
    };
  },
  computed: {
    classes() {
      return {
        'list__item--small': this.small,
        'list__item--selected': ('selected' in this.$attrs),
        'list__item--arrow-after': ('arrow' in this.$attrs),
      };
    },
  },
  created() {
    this.invoiceHeader = this.$store.getters['myInvoices/myInvoiceHeader'](this.invoice);
  },
  methods: {
    customFormatter(date) {
      return formatDate(date);
    },
    formatPrice,
    floatValue,
  },
};
</script>
<style scoped>
</style>
