<template>
  <div>
    <router-view />

    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <router-link :to="{name: 'account'}">
                <i class="uil uil-arrow-left" />
              </router-link>
              <h1>{{ $t('account.about.targets.title') }}</h1>
              <span />
            </div>
          </div>
        </div>
      </header>
    </div>

    <div class="container m-b-4">
      <div class="row">
        <div class="col">
          <iframe
            title="Push bikes"
            width="1140"
            height="700"
            src="https://app.powerbi.com/reportEmbed?reportId=57d40a9d-fde1-4f1a-8bc7-2bc74e3349ea&autoAuth=true&ctid=b5e9c847-eb36-4480-8695-15888927f387&navContentPaneEnabled=true"
            frameborder="0"
            allowFullScreen="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
export default {
  components: { },
  metaInfo() {
    return {
      title: this.$t('account.about.targets.title'),
    };
  },
};
</script>
