<template>
  <form @submit.prevent="changeLocation(location, 'create')">
    <bcf-aside @close="close">
      <bcfAsideHeader :close="close">
        {{ $t('account.settings.myLocations.newLocation') }}
      </bcfAsideHeader>

      <bcfAsideContent>
        <loading-overlay :loading="inProgress">
          <div
            v-if="isSucces"
            class="m-b-4"
          >
            <Message
              type="success"
              :icon="true"
              :content="$t('account.settings.myLocations.message')"
              :show-close="false"
            >
              <strong>Aanvraag verstuurd</strong>
            </Message>
          </div>

          <div v-else>
            <Message
              v-if="error"
              type="error"
              :icon="true"
              :show-close="false"
            >
              {{ $t(`api.UnexpectedError`) }}
            </Message>

            <div class="form-group">
              <label>{{ $t('account.settings.myLocations.name') }}</label>
              <input
                v-model="location.Name"
                type="text"
                required
              >
            </div>

            <div class="form-group">
              <label>{{ $t('account.settings.myLocations.street') }}</label>
              <input
                v-model="location.street"
                type="text"
                required
              >
            </div>

            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label>{{ $t('account.settings.myLocations.postalcode') }}</label>
                  <input
                    v-model="location.postalcode"
                    type="text"
                    required
                  >
                </div>
              </div>
              <div class="col-sm-8">
                <div class="form-group">
                  <label>{{ $t('account.settings.myLocations.city') }}</label>
                  <input
                    v-model="location.city"
                    type="text"
                    required
                  >
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>{{ $t('account.settings.myLocations.country') }}</label>
              <select
                v-model="location.country"
                required
              >
                <option
                  v-for="(countryValue, countryKey) in availableCountries"
                  :key="countryKey"
                  :value="countryKey"
                >
                  {{ availableCountries[countryKey] }}
                </option>
              </select>
            </div>
            <GmapMap
              :center="position"
              :zoom="2"
              map-type-id="terrain"
            >
              <GmapMarker
                :position="position"
                :clickable="false"
                :draggable="true"
                @position_changed="changedPos"
              />
            </GmapMap>
            <!-- TODO: translate -->

            <Message
              type="update"
              :icon="true"
              class="m-t-4"
            >
              <!-- eslint-disable-next-line max-len -->
              <strong>Let op:</strong> er wordt een aanvraag verstuurd om de wijzigingen door te voeren. Dit kan even duren.
            </Message>
          </div>
        </loading-overlay>
      </bcfAsideContent>

      <bcfAsideFooter v-if="!isSucces">
        <button
          class="button button--outline"
          :disabled="inProgress"
          @click="close()"
        >
          {{ $t('account.settings.myLocations.cancel') }}
        </button>

        <button
          class="button button--primary mr-2"
          type="submit"
          :class="{'button--loading': inProgress}"
          :disabled="inProgress"
        >
          {{ $t('account.settings.myLocations.send') }}
        </button>
      </bcfAsideFooter>
    </bcf-aside>
  </form>
</template>

<style scoped>
</style>

<script>
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import Message from '@/components/Message.vue';
import { RfcLocation } from '@/api/api';
import loadingOverlay from '@/elements/LoadingOverlay.vue';

export default {
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideFooter,
    bcfAsideContent,
    loadingOverlay,
    Message,
  },
  data() {
    return {
      isSucces: false,
      inProgress: false,
      location: {},
      error: null,
      availableCountries: this.$i18n.messages[this.$i18n.locale].countries,
      newPosition: null,
    };
  },
  computed: {
    position() {
      if (this.newPosition) {
        return { lat: this.newPosition.lat, lng: this.newPosition.lng };
      }
      return { lat: 0, lng: 0 };
    },

  },
  methods: {
    changedPos(newVal) {
      const lat = newVal.lat();
      const lng = newVal.lng();

      if (!this.newPosition) {
        this.newPosition = { lat, lng };
      } else if (lat !== this.newPosition.lat || lng !== this.newPosition.lng) {
        this.newPosition = { lat, lng };
      }
    },
    async changeLocation(location, action) {
      this.inProgress = true;
      // create rfc object
      const locationObj = {};
      locationObj.command = action;
      locationObj.name = location.Name;
      locationObj.city = location.city;
      locationObj.country = location.country;
      locationObj.postalcode = location.postalcode;
      locationObj.street = location.street;
      if (this.newPosition) {
        locationObj.position = `${this.newPosition.lat}, ${this.newPosition.lng}`;
      }
      // locationObj.externalid = location.externalid;

      const rfcObj = {};
      rfcObj.userid = this.$store.state.auth.user.id;
      rfcObj.cid = this.$store.state.customer.selectedCustomer.id;
      rfcObj.location = locationObj;
      try {
        this.isSucces = await RfcLocation(rfcObj);
      } catch (e) {
        this.error = e.message;
      } finally {
        this.inProgress = false;
      }
    },
    close() {
      this.$router.push({ name: 'accountLocations' });
    },
  },
};
</script>
<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0 0, 5em;
}
</style>
