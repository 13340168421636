<template>
  <div>
    <router-view />

    <bcf-aside
      v-if="isMobile"
      v-show="mobileFiltersOpen"
    >
      <bcfAsideHeader :close="closeMobileFilters">
        {{ $t('items.filters') }}
      </bcfAsideHeader>
      <bcfAsideContent>
        <div class="products__filter">
          <my-warranties-filters
            v-model="filters"
            :map-status="mapStatus"
          />
        </div>
      </bcfAsideContent>
      <bcfAsideFooter>
        <span />
        <button
          class="button button--primary"
          @click="closeMobileFilters"
        >
          {{ $t('general.apply') }}
        </button>
      </bcfAsideFooter>
    </bcf-aside>

    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <router-link :to="{name: 'account'}">
                <i class="uil uil-arrow-left" />
              </router-link>
              <h1>{{ $t('account.account.myWarranties.title') }}</h1>
              <span />
              <span class="main__title__action--right d-md">
                <router-link :to="{name: 'newWarranty'}">
                  <i class="uil uil-plus-circle" />
                </router-link>
              </span>
              <span
                v-if="isMobile"
                class="main__title__action--right"
                @click="mobileFiltersOpen = true"
              >
                <i class="uil uil-filter" />
                <Badge v-if="filters.length > 0">{{ filters.length }}</Badge>
              </span>
              <router-link
                :to="{name: 'newWarranty'}"
                class="button button--secondary d-md-none"
              >
                {{ $t('account.account.myWarranties.create') }}
              </router-link>
            </div>
          </div>
        </div>
      </header>
    </div>

    <div class="container m-t-2">
      <div
        v-if="inProgress"
        class="row"
      >
        <div class="col">
          <loader />
        </div>
      </div>
      <div
        v-else
        class="row"
      >
        <div class="col-sm-12 col-md-3">
          <my-warranties-filters
            v-if="!isMobile"
            v-model="filters"
            :map-status="mapStatus"
          />
        </div>
        <div class="col-sm-12 col-md-9">
          <search
            v-model="searchVal"
            class="m-md-t-2"
          />

          <div class="products__overview p-t-2">
            <div class="row">
              <div class="col">
                <list-item-warranty
                  v-for="(warranty) in warranties"
                  :key="warranty.warrantyNumber"
                  :item="warranty"
                />

                <EmptyState
                  v-if="warranties.length === 0 && loaded"
                  icon="parcel"
                >
                  {{ $t(`account.account.myWarranties.nowarranties`) }}
                </EmptyState>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'; // listMyInvoices
import ListItemWarranty from '@/elements/ListItemWarranty.vue';
import search from '@/components/Search.vue';
import loader from '@/elements/loader.vue';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import EmptyState from '@/elements/EmptyState.vue';
import Badge from '@/elements/Badge.vue';
import { formatDate } from '@/utils/dateUtils';
// import { fetch } from '@/api/warranty';

import myWarrantiesFilters from '@/views/account/account/mywarranties/filters.vue';

export default {
  components: {
    search,
    ListItemWarranty,
    loader,
    EmptyState,
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    bcfAsideFooter,
    myWarrantiesFilters,
    Badge,
  },
  data() {
    return {
      isMobile: window.innerWidth < 768,
      warranties: [],
      loaded: false,
      filters: { sort: this.$route.query.sort ? this.$route.query.sort : 'claimdate', filters: [] },
      mobileFiltersOpen: false,
      mapStatus: [],
    };
  },
  computed: {
    ...mapState({
      errorMessage: (state) => state.myWarranties.errorMessage,
      inProgress: (state) => state.myWarranties.inProgress,
    }),
    ...mapGetters('myWarranties', {
      listMyWarranties: 'listMyWarranties',
    }),
    searchVal: {
      get() {
        return this.$route.query.search;
      },
      set(newVal) {
        this.$router.push({
          name: this.$router.currentRoute.name,
          query: {
            ...this.$route.query,
            search: newVal,
          },
        });
        this.search();
      },
    },
  },
  watch: {
    filters: {
      handler() { this.search(); },
      deep: true,
    },
    listMyWarranties() {
      this.search();
    },
  },
  async mounted() {
    await this.$store.dispatch('myWarranties/get');
    this.warranties.forEach(
      (item) => {
        if (!this.mapStatus.includes(item.statusmapping)) {
          this.mapStatus.push(item.statusmapping);
        }
      },
    );
    this.loaded = true;
  },
  created() {
    window.addEventListener('resize', this.updateWindowSize);
    this.updateWindowSize();
  },
  destroyed() {
    window.removeEventListener('resize', this.updateWindowSize);
  },
  methods: {
    countNewMessages(warranty) {
      if (warranty.commercialreply && warranty.yiinewchatmessage) {
        const cusidx = warranty.commercialreply.slice().reverse().findIndex((o) => o.type === 'CUSTOMER');
        if (cusidx < 0) {
          return 0;
        }
        const idx = warranty.commercialreply.length - cusidx;
        const len = warranty.commercialreply.length;
        return len - idx;
      }
      return 0;
    },
    formatdate(dte) {
      return formatDate(dte);
    },
    closeMobileFilters() {
      this.mobileFiltersOpen = false;
    },
    updateWindowSize() {
      this.isMobile = window.innerWidth < 768;
    },
    search() {
      const filters = {};
      this.filters.filters.forEach((f) => {
        if (!filters[f.type]) {
          filters[f.type] = [];
        }
        const x = f.value;
        for (let index = 0; index < x.length; index += 1) {
          filters[f.type].push(x[index]);
        }
      });
      let allWarranties = this.listMyWarranties.filter((warranty) => {
        if (filters.status) {
          if (!filters.status.includes(warranty.status)) {
            return false;
          }
        }
        return true;
      });
      if (this.filters.sort === 'claimdate') {
        allWarranties = allWarranties.sort((a, b) => {
          if (a.claimdate > b.claimdate) {
            return -1;
          } if (a.claimdate < b.claimdate) {
            return 1;
          }
          return 0;
        });
      }
      if (this.filters.sort === 'newchatmessage') {
        allWarranties = allWarranties.sort((a, b) => {
          if ((this.countNewMessages(a) > 0) > (this.countNewMessages(b) > 0)) {
            return -1;
          }
          return 1;
        });
      }
      if (this.$route.query.search) {
        this.$search(this.$route.query.search, allWarranties, {
          keys: ['serialnumber', 'warrantynumber', 'description', 'invoicenumber', 'reference', 'status', 'damagedescription'],
          defaultAll: true,
          caseSensitive: false,
          shouldSort: true,
          tokenize: false,
          matchAllTokens: true,
          findAllMatches: true,
          threshold: 0.6,
          location: 0,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 1,
        }).then((results) => {
          this.warranties = results;
        });
      } else {
        this.warranties = allWarranties;
      }
    },
  },
};
</script>
<style scoped>
[v-cloak] > * {
  display: none;
}
[v-cloak]::before {
  content: "loading…";
}
</style>
