<template>
  <span
    v-if="order"
  >
    <div
      class="list__item list__item--order"
    >

      <div class="list__item__icon">
        <i
          v-if="order.navstatus === 'pending'"
          class="uil uil-refresh"
        />
        <i
          v-else
          class="uil uil-box"
        />
      </div>

      <span class="list__item__info">
        <div class="list__item__title">
          <header>
            <!-- Title -->
            <strong>
              <span>{{ order.externalorderid }}</span><!--test:{{ order.orderlines[0].orderstatus[0].date }}-->
            </strong>
            <!-- Badge preorder -->
            <Badge
              v-if="order.preorder"
              type="outlet"
            >{{ $t('account.account.myOrders.preorderLabel') }}</Badge>
            <!-- Badge credit limit vs balance -->
            <span
              v-if="(totalBalance>creditlimit && creditlimit > 0) &&
                ((order.navstatus === 'pending') || (order.orderlines[0].orderstatus[0].date===null && order.navstatus ==='processed')) && isPreviewMode"
              @click.prevent="goToBalance"
            >
              <Badge
                type="danger"
              >{{ $t('account.account.myBalance.status') }}</Badge>
            </span>

            <!-- Badge Shipped -->
            <Badge
              v-if="order.shipped"
              type="shipped"
            >{{ $t('account.account.myOrders.doneStatus.shipped') }}</Badge>

          </header>

          <!-- Order date -->
          <time v-if="order.orderdate">
            {{ formatdate(order.orderdate) }}
          </time>
        </div>
        <small v-if="order.reference">
          {{ order.reference }}
        </small>

        <!-- Overview products -->
        <div class="list__item__products-overview">
          <div
            v-for="(line, i) in order.orderlines"
            :key="i"
            class="list__item__products-overview__item"
          >
            <div class="list__item__products-overview__item__section">
              <div class="list__item__products-overview__item__image">
                <img
                  :src="line.image ? line.image : `https://images.cyclingfactory.be/${line.externalid}_image_400x.jpg`"
                  :alt="line.externalid"
                  @error="imgFailed"
                >
              </div>
              {{ line.qty }}x {{ line.description }}
            </div>
            <div class="list__item__products-overview__item__section">
              <div style="flex-direction: column">
                <small
                  v-for="serial in line.serials"
                  :key="serial"
                ><i class="uil uil-pricetag-alt" /> {{ serial }}</small>
              </div>
              <!--               <small
                v-if="orderlineReferenceText(line)"
                class="orderline__reference"
                :class="{ 'orderline__reference__updated': order.referencesUpdateAvailable }"
              >
                {{ order }}
                {{ orderlineReferenceText(line) }}
              </small> -->

              <small v-if="line.nextstatus"> {{ $t('account.account.myOrders.pendingStatus.' + line.nextstatus) }}</small>

              <small v-if="line.shipped !== true && line.promiseddeliverydate"><i>ETD</i> {{ formatdate(line.promiseddeliverydate) }}</small>
              <small v-if="line.shipped !== true && !line.promiseddeliverydate && line.requesteddeliverydate"><i>RTD</i> {{ formatdate(line.requesteddeliverydate) }}</small>
              <small v-if="line.shipped === true"><i class="uil uil-truck" /> {{ formatdate(line.shipmentdate) }}</small>

            </div>
          </div>
        </div>
        <!-- / Overview products -->

      </span>
    </div></span>
</template>

<script>
import Badge from '@/elements/Badge.vue';
import { formatDate } from '@/utils/dateUtils';
import { mapState } from 'vuex';

export default {
  name: 'ListItemOrder',
  components: {
    Badge,
  },
  inheritAttrs: true,
  props: {
    selected: Boolean,
    order: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      creditlimit: 0,
    };
  },
  computed: {
    ...mapState({
      selectedCustomer: (state) => state.customer.selectedCustomer,
      isPreviewMode: (state) => state.global.isPreviewMode,
    }),
    totalBalance() {
      return Number(this.selectedCustomer.attributes.BalanceProductionOrdersLCY) + Number(this.selectedCustomer.attributes.BalanceOpenInvoicesLCY) + Number(this.order.lineamount);
    },
    done() {
      return this.order.qty > 0 && this.order.qty === this.order.shippedqty;
    },
    classes() {
      return {
        'list__item--small': this.small,
        'list__item--selected': this.order.qty === this.order.shippedqty,
        'list__item--arrow-after': ('arrow' in this.$attrs),
      };
    },
  },
  mounted() {
    this.creditlimit = this.selectedCustomer.attributes.CreditLimitLCY;
    /*     if(this.selectedCustomer.externalid === 'CUS0038') {
      this.creditlimit = 262000;
    } */
  },
  methods: {
    goToBalance() {
      // eslint-disable-next-line no-alert
      // alert('ok works');
      this.$router.push({ name: 'myOrdersBalance' });
    },
    orderlineReferenceText(ol) {
      if (ol && ol.references) {
        return ol.references.map((r) => (
          [r.firstName, r.lastName].filter((x) => x).join(' ')
            || r.note
            || r.email
        )).filter((x) => x).join(',');
      }
      return null;
    },
    formatdate(dte) {
      return formatDate(dte);
    },
    imgFailed(event) {
      if (!event.target.failed) {
        event.target.failed = true;
        // eslint-disable-next-line global-require
        event.target.src = require('@/assets/img/product_placeholder.jpg');
      }
    },
  },
};
</script>
