<template>
  <bcf-aside @close="close">
    <bcfAsideHeader :close="close">
      {{ $t('account.about.helpAndSupport.contactDetails') }}
    </bcfAsideHeader>
    <bcfAsideContent>
      <sectionTitle class="m-b-0">
        {{ $t('account.about.helpAndSupport.general') }}
      </sectionTitle>
      <ListItem icon-before>
        <i class="uil uil-phone" />
        <a
          href="tel:003213673600"
        > +32 (0)13 67 36 00</a>
      </ListItem>
      <ListItem icon-before>
        <i class="uil uil-envelope" />
        <a href="mailto:info@cyclingfactory.be">info@cyclingfactory.be</a>
      </ListItem>

      <sectionTitle class="m-b-0 m-t-2">
        Sales
      </sectionTitle>
      <ListItem icon-before>
        <i class="uil uil-phone" />
        <a
          href="tel:003211278941"
        >+32 (0)11 27 89 41</a>
      </ListItem>
      <ListItem icon-before>
        <i class="uil uil-envelope" />
        <a href="mailto:sales@cyclingfactory.be">sales@cyclingfactory.be</a>
      </ListItem>

      <sectionTitle class="m-b-0 m-t-2">
        Customer service
      </sectionTitle>
      <ListItem icon-before>
        <i class="uil uil-envelope" />
        <a href="mailto:customer.service@cyclingfactory.be">customer.service@cyclingfactory.be</a>
      </ListItem>

      <sectionTitle class="m-b-0 m-t-2">
        Finance
      </sectionTitle>
      <ListItem icon-before>
        <i class="uil uil-envelope" />
        <a href="mailto:finance@cyclingfactory.be">finance@cyclingfactory.be</a>
      </ListItem>

      <sectionTitle class="m-b-0 m-t-2">
        Marketing
      </sectionTitle>
      <ListItem icon-before>
        <i class="uil uil-envelope" />
        <a href="mailto:marketing@cyclingfactory.be">marketing@cyclingfactory.be</a>
      </ListItem>

      <div class="m-t-4">
        <sectionTitle>{{ $t('account.about.helpAndSupport.location') }}</sectionTitle>
        <iframe
          width="100%"
          height="350"
          frameborder="0"
          style="border:0"
          src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJO8DMgWk6wUcR7oxb6RltCsM&key=AIzaSyDMHIzKqR_PDXszdTh67IhHBN_DuCjjh84"
          allowfullscreen
        />
      </div>
    </bcfAsideContent>
  </bcf-aside>
</template>

<style scoped>
</style>

<script>
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import ListItem from '@/elements/ListItem.vue';
import SectionTitle from '@/elements/SectionTitle.vue';

export default {
  components: {
    bcfAside,
    bcfAsideContent,
    bcfAsideHeader,
    ListItem,
    SectionTitle,
  },
  methods: {
    close() {
      this.$router.push({ name: 'helpAndSupport' });
    },
  },
};
</script>
