<template>
  <div class="products__filter m-md-t-2">
    <fieldset>
      <collapse :open="true">
        <template v-slot:header="{open}">
          <div
            class="section-title section-title--collapse"
            :class="{'open': open}"
          >
            {{ $t('account.account.myStock.options') }}
          </div>
        </template>

        <div class="form-group">
          <select
            :value="sortOption.key"
            @change="sortChange($event)"
          >
            <option
              v-for="option of visibleOptions"
              :key="option.key"
              :value="option.key"
              :selected="option.key===sortOption.key"
            >
              <!-- Future note, even though :value binding on select works, without the :selected attr on individual
               options it will not work for options that are added
               (probably to do with vue trying to set the value before adding the option ) -->
              {{ $t(`account.account.myStock.${option.id}`) }} {{ !option.invert? '↓' : '↑' }}
            </option>
          </select>
        </div>
      </collapse>
    </fieldset>
    <fieldset>
      <collapse :open="true">
        <template v-slot:header="{open}">
          <div
            class="section-title section-title--collapse"
            :class="{'open': open}"
          >
            {{ $t('account.account.myStock.filters.status') }}
          </div>
        </template>
        <ul>
          <li>
            <checkbox
              v-for="status of statuses"
              :key="status"
              v-model="valueInt.filters"
              :checked-value="{ type: `status`, value: status }"
            >
              <span>{{ $t(`account.account.myStock.statuses.${status}`) }}</span>
            </checkbox>
          </li>
        </ul>
        <div class="form-group form-group--checkbox">
          <toggle v-model="valueInt.showHidden">
            {{ $t('account.account.myStock.showHidden') }}
          </toggle>
        </div>
      </collapse>
    </fieldset>
    <fieldset v-if="locations.length>2">
      <collapse :open="true">
        <template v-slot:header="{open}">
          <div
            class="section-title section-title--collapse"
            :class="{'open': open}"
          >
            {{ $t('account.account.myStock.address') }}
          </div>
        </template>
        <ul>
          <li>
            <select
              v-model="valueInt.addressid"
              class="location-select"
            >
              <option
                v-for="location of locations"
                :key="location.externalid"
                :value="location.externalid"
              >
                {{ location.Name || $t('account.account.myStock.filters.allAddresses') }}
              </option>
            </select>
          </li>
        </ul>
      </collapse>
    </fieldset>
    <!--<fieldset>
      <collapse :open="true">
        <template v-slot:header="{open}">
          <div
            class="section-title section-title--collapse"
            :class="{'open': open}"
          >
            {{ $t('account.account.myStock.filters.externalid') }}
          </div>
        </template>
        <ul>
          <li>
            <input
              v-model="valueInt.externalid"
              :placeholder="$t('account.account.myStock.filters.externalidPlaceholder')"
              type="text"
            >
          </li>
        </ul>
      </collapse>
    </fieldset>-->
  </div>
</template>
<script>
import collapse from '@/components/collapse.vue';
import checkbox from '@/elements/checkbox.vue';
import toggle from '@/elements/toggle.vue';
import { mapState } from 'vuex';
import { statuses } from './statusSelect.vue';

const sourceSortOptions = [].concat(...[
  [false, 'status'],
  [false, 'lastmodified'],
  [false, 'orderdate'],
  [false, 'shipmentdate'],
  [true, 'itemCode'],
  [true, 'item'],
].map(
  ([hidden, id]) => [false, true].map((invert) => ({
    id,
    hidden,
    invert,
    key: JSON.stringify({ id, invert }),
  })),
));

export default {
  components: {
    collapse,
    checkbox,
    toggle,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        sort: 'status',
        filters: [],
        externalid: '',
        addressid: '',
        showHidden: false,
      }),
    },
  },
  data() {
    return {
      sortOptions: sourceSortOptions,
      statuses: statuses.filter((x) => x !== 'hidden'),
    };
  },
  computed: {
    valueInt: {
      get() {
        return this.value;
      },
      set(newval) {
        this.$emit('input', newval);
      },
    },
    ...mapState({
      customer: (state) => state.customer,
      locations: (state) => [{ externalid: '', Name: null }, ...state.customer.selectedCustomer.addresses],
    }),
    sortOption() {
      const { valueInt, sortOptions } = this;
      return this.sortOptionOf(valueInt.sort) ?? sortOptions[0];
    },
    visibleOptions() {
      const { sortOption, sortOptions } = this;
      return sortOptions.filter((x) => !x.hidden || (x.key === sortOption.key));
    },
  },
  methods: {
    sortOptionOf(sort) {
      const { sortOptions } = this;
      const key = sort?.key ?? (sort ? JSON.stringify({ id: sort.id, invert: !!sort.invert }) : sortOptions[0].key);
      return sortOptions.find((k) => k.key === key);
    },
    sortChange(event) {
      const option = this.sortOptions.find((k) => k.key === event.target.value);
      this.valueInt = {
        ...this.valueInt,
        sort: option,
      };
    },
  },
};
</script>
