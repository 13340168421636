<template>
  <bcf-aside @close="close">
    <BcfAsideHeader :close="close">
      {{ voucher.code }}
    </BcfAsideHeader>
    <BcfAsideContent>
      <div class="list__item__price">
        <strong>
          <Badge
            v-if="!activeVoucher"
            type="danger"
            style="padding:7px;"
          >
            Expired
          </Badge>
          <Badge
            v-else
            type="paid"
          >
            Active
          </Badge>
        </strong>
      </div>
      <div class="m-t-2">
        <table>
          <tr>
            <td colspan="2">
              <small>Description:</small>
              <textarea
                v-model="voucher.description"
                rows="4"
                cols="50"
                maxlength="200"
                placeholder="Enter description, campagne or social media name here..."
                :disabled="!editing"
              />
            </td>
            <td />
          </tr>
        </table>
      </div>
      <section-title>
        Date valid
      </section-title>
      <div class="list__item__content">
        <small>{{ $t('account.settings.vouchers.startDate') }}: </small>
        <datepicker
          v-model="voucher.start"
          placeholder=""
          maximum-view="month"
          :disabled="!editing"
        />
      </div>
      <br>
      <div class="list__item__content">
        <small>
          {{ `${$t('account.settings.vouchers.expiryDate')}:` }}
        </small>
        <datepicker
          v-model="voucher.expire"
          placeholder=""
          maximum-view="month"
          :disabled="!editing"
        />
      </div>
      <loader
        v-if="!loading"
        class="m-t-2"
      />
      <div v-else>
        <section-title>
          Website
        </section-title>
        <span>
          <multiselect
            v-model="websitevalues"
            :options="websiteoptions"
            :multiple="true"
            :close-on-select="false"
            :disabled="!editing"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick some"
            :preselect-first="false"
          />
        </span>
        <section-title>
          Brands
        </section-title>
        <span>
          <multiselect
            v-model="brandsvalues"
            :options="brandsoptions"
            :multiple="true"
            :close-on-select="false"
            :disabled="!editing"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick some"
            label="name"
            :track-by="trackBy"
            :preselect-first="false"
            :object="true"
          />
        </span>
        <section-title>
          Range
        </section-title>
        <span>
          <multiselect
            v-model="rangevalues"
            :options="rangeoptions"
            :multiple="true"
            :close-on-select="false"
            :disabled="!editing"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick some"
            label="name"
            :preselect-first="false"
            :track-by="trackBy"
            :object="true"
          />
        </span>
        <section-title>
          Family
        </section-title>
        <span>
          <multiselect
            v-model="familyvalues"
            :options="familyoptions"
            :multiple="true"
            :close-on-select="false"
            :disabled="!editing"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick some"
            label="name"
            :preselect-first="false"
            :track-by="trackBy"
            :object="true"
          />
        </span>
        <section-title>
          Category
        </section-title>
        <span>
          <multiselect
            v-model="categoryvalues"
            :options="categoryoptions"
            :multiple="true"
            :close-on-select="false"
            :disabled="!editing"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick some"
            label="name"
            :preselect-first="false"
            track-by="name"
            :object="true"
          />
        </span>
        <section-title>
          Items(Regex)
        </section-title>
        <template v-for="(reg, index) in voucher.discount[0].items">
          <Badge
            :key="index"
            type="new"
            style="padding:7px;"
          >
            {{ reg }}
            <i
              v-if="editing"
              class="uil uil-multiply"
              style="cursor: pointer;"
              @click="del('items',index)"
            />
          </Badge>
        </template>
        <table class="table">
          <tr>
            <td>
              <input
                v-model="newRegexItem"
                :disabled="!editing"
                class="form-control"
                type="text"
              ></input>
            </td>
            <td>
              <button
                :disabled="!editing"
                class="button button--primary"
                :class="{'button--loading': inProgress}"
                @click.prevent="Add()"
              >
                Add
              </button>
            </td>
          </tr>
        </table>
        <section-title>
          Discount details
        </section-title>
        <table class="table table--striped m-b-4">
          <tr>
            <td>Type:</td>
            <td>
              <multiselect
                v-model="configuration.config_1.type"
                :options="options"
                :disabled="!editing"
                :searchable="false"
                :close-on-select="true"
                :show-labels="false"
                placeholder="Select one"
              />
            </td>
          </tr>
          <tr>
            <td>Amount:</td>
            <td>
              <input
                ref="default"
                v-model="configuration.config_1.amount"
                :disabled="!editing"
                class="form-control"
                type="text"
              >
            </td>
          </tr>
          <tr>
            <td>Currency:</td>
            <td>
              <multiselect
                v-model="configuration.config_1.currency"
                :options="currencyoptions"
                :disabled="!editing"
                :searchable="false"
                :close-on-select="true"
                :show-labels="false"
                placeholder="Select one"
              />
            </td>
          </tr>
          <tr>
            <td>Max attempts:</td>
            <td>
              <input
                ref="default"
                v-model="configuration.config_1.maxused"
                :disabled="!editing"
                class="form-control"
                type="text"
              >
            </td>
          </tr>
        </table>
        <Message
          v-if="isSuccess"
          class="m-t-2"
          type="success"
          :icon="false"
          :show-close="false"
        >
          This voucher successfully saved.
        </Message>
        <Message
          v-if="isError"
          type="error"
          :icon="false"
          :show-close="false"
        >
          Something went wrong saving this voucher...
        </Message>
        <section-title>
          Options
        </section-title>
        <checkbox
          v-model="voucher.default"
          :disabled="!editing"
          :checked-value="{ type: 'status', value: 'done' }"
        >
          <span><small><b>set as default</b></small></span>
        </checkbox>&nbsp;&nbsp;&nbsp;
        <checkbox
          v-model="configuration.options.cumulative"
          :disabled="!editing"
          :checked-value="{ type: 'status', value: 'done' }"
        >
          <span><small><b>cumulative on campaign</b></small></span>
        </checkbox>&nbsp;&nbsp;&nbsp;
        <checkbox
          v-model="configuration.options.inventoryOnly"
          :disabled="!editing"
          :checked-value="{ type: 'status', value: 'done' }"
        >
          <span><small><b>Inventory items only</b></small></span>
        </checkbox>
        <br> <!-- Add a line break here -->
        <checkbox
          v-model="configuration.config_1.freeShipping"
          :disabled="!editing"
          :checked-value="{ type: 'status', value: 'done' }"
        >
          <span><small><b>Free shipping</b></small></span>
        </checkbox>
      </div>
    </BcfAsideContent>
    <bcf-aside-footer>
      <button
        class="button button--outline"
        @click="close()"
      >
        Close
      </button>
      <button
        v-if="editing"
        class="button button--primary"
        :class="{'button--loading': inProgress}"
        :disabled="inProgress"
        @click.prevent="save()"
      >
        Opslaan
      </button>
      <button
        v-if="!editing"
        class="button button--primary"
        :disabled="inProgress"
        @click.prevent="editDetails()"
      >
        {{ $t('account.settings.companyDetails.change') }}
      </button>
    </bcf-aside-footer>
  </bcf-aside>
</template>
<script>
import loader from '@/elements/loader.vue';
import bcfAside from '@/components/bcfAside.vue';
import BcfAsideHeader from '@/components/bcfAsideHeader.vue';
import BcfAsideContent from '@/components/bcfAsideContent.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import { formatDate } from '@/utils/dateUtils';
import Badge from '@/elements/Badge.vue';
import checkbox from '@/elements/checkbox.vue';
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect';
import {
  UpdateVoucher, GetRanges, GetFamilies, GetCategories, GetBrands,
} from '@/api/api';
import Message from '@/components/Message.vue';

export default {
  components: {
    bcfAside,
    BcfAsideHeader,
    BcfAsideContent,
    BcfAsideHeader,
    bcfAsideFooter,
    SectionTitle,
    Badge,
    Datepicker,
    Multiselect,
    checkbox,
    Message,
    loader,
  },
  props: {
    voucher: {
      type: Object,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      trackBy: 'key',
      isSuccess: false,
      isError: false,
      editing: null,
      // eVoucher: { ...this.voucher },
      inProgress: false,
      websitevalues: [],
      websiteoptions: ['ridley', 'merckx'],
      brandsvalues: null,
      brandsoptions: [],
      rangevalues: null,
      rangeoptions: [],
      categoryvalues: null,
      categoryoptions: [], // [{ name: 'bikes' }, { name: 'framesets' }, { name: 'custom' }, { name: 'accessories' }, { name: 'clothing' }],
      familyvalues: null,
      familyoptions: [],
      // familyoptions: [{ name: 'bikes' }, { name: 'legwear' }, { name: 'jerseys' }, { name: 'other clothing' }, { name: 't-shirts' }, { name: 'socks' }, { name: 'gloves' }],
      values: [],
      options: ['amount', 'percentage', 'discount'],
      currencyoptions: ['EUR', 'GBP', 'USD', 'percentage'],
      newRegexItem: null,
      loading: false,
    };
  },
  computed: {
    activeVoucher() {
      const dateFrom = this.voucher.start;
      const dateTo = this.voucher.expire;
      const dateCheck = new Date();

      const from = Date.parse(dateFrom);
      const to = Date.parse(dateTo);
      if ((dateCheck <= to && dateCheck >= from) && (this.voucher.count < this.voucher.discount[0].config_1.maxused)) {
        return true;
      }
      return false;
    },
    configuration() {
      return this.voucher.discount[0];
    },
  },
  async mounted() {
    const [ranges, families, categories, brands] = await Promise.all([
      GetRanges(),
      GetFamilies(),
      GetCategories(),
      GetBrands(),
    ]);

    this.rangeoptions = ranges;
    this.familyoptions = families;
    this.categoryoptions = categories;
    this.rangevalues = this.configuration.range; // ? this.configuration.range.map((i) => ({ name: i })) : [];
    this.websitevalues = this.configuration.website;
    this.brandsvalues = this.configuration.brand;
    this.brandsoptions = brands;

    this.familyvalues = this.configuration.family; // ? this.configuration.family.map((i) => ({ name: i })) : [];
    this.categoryvalues = this.configuration.category; // ? this.configuration.category.map((i) => ({ name: i.toLowerCase() })) : [];
    this.loading = true;
  },
  methods: {
    Add() {
      if (!this.voucher.discount[0].items) {
        this.voucher.discount[0].items = null;
      }

      this.voucher.discount[0].items.push(this.newRegexItem);
      this.newRegexItem = '';
    },
    del(key, e) {
      this.configuration[key].splice(e, 1);
    },
    async save() {
      this.voucher.discount[0] = {
        website: this.websitevalues,
        brand: this.brandsvalues?.length ? this.brandsvalues : null,
        range: this.rangevalues?.length ? this.rangevalues : null, // ? this.rangevalues.map((r) => r.name) : [],
        family: this.familyvalues?.length ? this.familyvalues : null, // ? this.familyvalues.map((f) => f.name) : [],
        category: this.categoryvalues?.length ? this.categoryvalues : null, // ? this.categoryvalues.map((c) => c.name) : [],
        items: this.configuration.items?.length ? this.configuration.items : null,
        config_1: {
          type: this.configuration.config_1.type,
          amount: this.configuration.config_1.amount,
          currency: this.configuration.config_1.currency,
          maxused: this.configuration.config_1.maxused,
          freeShipping: this.configuration.config_1.freeShipping,
        },
        options: {
          cumulative: this.configuration.options.cumulative,
          inventoryOnly: this.configuration.options.inventoryOnly,
        },
      };
      this.voucher.modifiedby = this.$store.state.auth.user.email || this.$store.state.auth.userexternalid;
      const now = new Date();
      this.voucher.modifiedat = now;
      const result = await UpdateVoucher(this.voucher);
      if (result.status === 200) {
        this.isSuccess = true;
        const $ref = this.$refs.default;
        $ref.focus();
      } else {
        this.isError = true;
      }
      this.editing = false;
    },
    editDetails() {
      this.editing = true;
    },
    customFormatter(date) {
      return formatDate(date);
    },
    close() {
      // this.$router.push({ name: 'accountVouchers', query: { update: true } });
      this.$router.push({ name: 'accountVouchers' });
    },
  },
};
</script>
<style>
.multiselect__input {
    border:none!important;
  }
.multiselect__tag {
    background-color: black;
  }
.multiselect__tag-icon::after {
  color: white;
}
.multiselect__tag-icon:hover {
  background: black;
}
.multiselect__option--highlight {
  background: black;
}
.multiselect__option--highlight::after {
  background: black;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: black;
  color: #fff;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: black;
  content: attr(data-deselect);
  color: #fff;
}
</style>
