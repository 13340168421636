<template>
  <bcf-aside @close="close">
    <bcfAsideHeader :close="close">
      {{ $t('account.account.myContract.title') }}
    </bcfAsideHeader>
    <bcfAsideContent>
      <Message
        v-if="errorMessage"
        type="error"
        :icon="true"
        :content="$t(`api.${errorMessage}`)"
        :show-close="true"
        @closeMessage="closeMessage"
      />

      <div v-if="!loaded">
        <loader class="m-b-2" />
      </div>

      <div
        v-for="contract in contracts"
        :key="contract.key"
      >
        <ListItem icon-before>
          <i class="uil uil-file-edit-alt" />
          <a
            :href="contract.url"
            target="_blank"
          >{{ contract.title }}</a>
        </ListItem>
      </div>
      <EmptyState
        v-if="contracts.length === 0 && loaded"
        icon="file-edit-alt"
      >
        {{ $t('account.account.myContract.noContract') }}
      </EmptyState>
    </bcfAsideContent>
  </bcf-aside>
</template>
<script>
import { mapState } from 'vuex';
import loader from '@/elements/loader.vue';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import ListItem from '@/elements/ListItem.vue';
import { getContracts } from '@/api/api';
import EmptyState from '@/elements/EmptyState.vue';
import Message from '@/components/Message.vue';

export default {
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    ListItem,
    loader,
    EmptyState,
    Message,
  },
  data() {
    return {
      contracts: {},
      loaded: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapState({
      selectedCustomer: state => state.customer.selectedCustomer,
    }),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    close() {
      this.$router.push({ name: 'account' });
    },
    closeMessage() {
      this.errorMessage = '';
    },
    async fetchData() {
      try {
        this.loaded = false;
        const result = await getContracts(this.selectedCustomer.id);
        this.loaded = true;
        this.contracts = result.data.result.map((content) => {
          const match = content.key.match(/CUS[0-9]{4}-contract([0-9]{4}).pdf/);
          const title = (match.length > 0) ? this.$t('account.account.myContract.contractYear', { year: match[1] }) : content.key;
          return {
            ...content,
            title,
          };
        });
      } catch (err) {
        this.loaded = true;
        this.errorMessage = err.message;
      }
    },
  },
};
</script>
<style scoped>
</style>
