<template>
  <div
    class="list__item list__item--invoice"
    :class="classes"
  >
    <div class="list__item__icon">
      <i class="uil uil-brackets-curly" />
    </div>
    <div class="list__item__content">
      <div>
        {{ v.code }} <span style="color: red; font-weight:bold; font-size:small">{{ v.default ? '(Default)' : '' }}</span>
      </div>
      <!-- <small>Description: {{ v.description }}</small> -->
      <small>{{ $t('account.settings.vouchers.startDate') }}: {{ customFormatter(v.start) }}</small>
    </div>
    <div class="list__item__price">
      <strong>
        <Badge
          v-if="!activeVoucher"
          type="danger"
        >
          Expired
        </Badge>
        <Badge
          v-else
          type="paid"
        >
          Active
        </Badge>
      </strong>
      <small style="text-align: right;" :style="{}">
        {{ `${$t('account.settings.vouchers.expiryDate')}: ${customFormatter(v.expire)} ` }}
      </small>
    </div>
  </div>
</template>
<script>
// import Badge from '@/elements/Badge.vue';
import { formatDate } from '@/utils/dateUtils';
import Badge from '@/elements/Badge.vue';

export default {
  name: 'ListItemVoucher',
  components: {
    Badge,
  },
  inheritAttrs: true,
  props: {
    v: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    activeVoucher() {
      const dateFrom = this.v.start;
      const dateTo = this.v.expire;
      const dateCheck = new Date();

      const from = Date.parse(dateFrom);
      const to = Date.parse(dateTo);
      if ((dateCheck <= to && dateCheck >= from) && (this.v.count < this.v.maxused)) {
        return true;
      }
      return false;
    },
    classes() {
      return {
        'list__item--small': this.small,
        'list__item--selected': ('selected' in this.$attrs),
        'list__item--arrow-after': ('arrow' in this.$attrs),
      };
    },
  },
  created() {
  },
  methods: {
    customFormatter(date) {
      return formatDate(date);
    },
  },
};
</script>
<style scoped>
</style>
