<template>
  <bcf-aside @close="close">
    <bcfAsideHeader :close="close">
      {{ code }}
    </bcfAsideHeader>

    <bcfAsideContent>
      <div v-if="!loaded">
        <loader class="m-b-2" />
      </div>
      <div v-if="errorMessage">
        <Message
          type="error"
          :icon="true"
          :content="$t(`api.${errorMessage}`)"
        />
      </div>

      <div class="configurator__form__box">
        <img :src="configuration.images && configuration.images.print && configuration.images.print['1x']">
      </div>
      <div class="configurator__form__box">
        <table class="configurator__summary__table">
          <template v-for="question in Object.keys(configuration.configuration)">
            <tr
              v-if="question !== 'C2D_Background'"
              :key="question"
            >
              <th><span>{{ question }}</span></th>
              <td>
                <small>{{ configuration.configuration[question] }}</small>
              </td>
            </tr>
          </template>
        </table>
      </div>
    </bcfAsideContent>
  </bcf-aside>
</template>
<script>
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import Message from '@/components/Message.vue';
import loader from '@/elements/loader.vue';
import { LoadConfigurationByCode } from '@/api/customizer';

export default {
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    Message,
    loader,
  },
  props: {
    code: {
      type: String,
      default: null,
    },
    useCloseEvent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      configuration: {},
      loaded: false,
      errorMessage: '',
      orderOpen: null,

    };
  },
  computed: {
    getSalesOrderNumbers() {
      return this.invoice.invoicelines.reduce((result, s) => {
        if (result.findIndex((o) => s.externalorderid === o.externalorderid) === -1) {
          result.push(s);
        }
        return result;
      }, []);
    },
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    close() {
      if (this.useCloseEvent) {
        this.$emit('close');
      } else {
        this.$router.push(this.$store.state.global.previousRoute ? this.$store.state.global.previousRoute : { name: 'myOrders' });
      }
    },
    async fetchData() {
      try {
        const result = await LoadConfigurationByCode(
          this.code,
        );
        this.configuration = result;
        // console.log(this.invoice);
        this.loaded = true;
      } catch (error) {
        this.loaded = true;
        this.errorMessage = error.message;
      }
    },
  },
};
</script>
