/* eslint-disable import/prefer-default-export */
export const debounce = (fn, wait) => {
  let timer;
  return (...args) => {
    if (timer) {
      clearTimeout(timer); // clear any pre-existing timer
    }
    const context = this; // get the current context
    timer = setTimeout(() => {
      fn.apply(context, args); // call the function if time expires
    }, wait);
  };
};
