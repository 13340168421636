<template>
  <bcf-aside @close="close">
    <bcfAsideHeader :close="close">
      {{ $t(`account.account.myWarranties.warrantynumber`) }}: {{ warranty.warrantynumber ? warranty.warrantynumber : 'N/A' }}
    </bcfAsideHeader>
    <bcf-aside-content>
      <div v-if="!loaded">
        <loader class="m-b-2" />
      </div>
      <div v-else>
        <table class="table table--striped m-b-4">
          <tr>
            <td>
              <strong>{{ $t(`account.account.myWarranties.claimdate`) }}:</strong>
            </td>
            <td class="text-right">
              {{ warranty.claimdate ? customFormatter(warranty.claimdate) : 'N/A' }}
            </td>
          </tr>
          <tr>
            <td>
              <strong>{{ $t(`account.account.myWarranties.reference`) }}:</strong>
            </td>
            <td class="text-right">
              {{ warranty.reference ? warranty.reference : 'N/A' }}
            </td>
          </tr>
          <tr>
            <td>
              <strong>{{ $t(`account.account.myWarranties.warrantystatus`) }}:</strong>
            </td>
            <td
              v-if="warranty.statusmapping==='rejected'"
              class="text-right"
            >
              <Badge
                type="danger"
              >
                {{ $t(`account.account.myWarranties.status.${warranty.statusmapping}`) }}
              </Badge>
            </td>
            <td
              v-else
              class="text-right"
            >
              <Badge
                type="unpaid"
              >
                {{ $t(`account.account.myWarranties.status.${warranty.statusmapping}`) }}
              </Badge>
            </td>
          </tr>
        </table>

<!--         <collapse
          :open="true"
        >
          <template v-slot:header="{open}">
            <div
              class="section-title section-title--collapse"
              :class="{'open': open}"
              @click.prevent="setAsRead"
            >
              <span>
                {{ $t(`account.account.myWarranties.commercialreply`) }}
                <Badge
                  v-if="warranty.yiinewchatmessage === 'WARRANTY'"
                  class="m-l-1"
                >{{ countNewMessages }}
                </Badge>
              </span>
            </div>
          </template>

          <div class="chat__holder">
            <div
              v-if="warranty.commercialreply"
              class="chat__messages"
            >
              <div
                v-for="(reply, index) in warranty.commercialreply"
                :key="index"
              >
                <template v-if="reply.type==='CUSTOMER'">
                  <div class="bubble follow">
                    <div class="txt">
                      <span class="name alt">{{ $t(`account.account.myWarranties.customer`) }}</span>
                      <span class="timestamp">{{ reply.date }}</span>
                      <span class="message follow">{{ reply.message }}</span>
                    </div>
                  </div>
                  {{ reply.messsage }}
                </template>
                <template v-else-if="reply.type==='REP'">
                  <div class="bubble altfollow">
                    <div class="txt">
                      <span
                        class="name alt"
                        style="color:white;"
                      >{{ reply.username }}</span>
                      <span class="timestamp">{{ reply.date }}</span>
                      <span class="bcfmessage">{{ reply.message }}</span>
                    </div>
                  </div>
                  {{ reply.messsage }}
                </template>
                <template v-else>
                  <div class="bubble altfollow">
                    <div class="txt">
                      <span class="name alt">{{ $t(`account.account.myWarranties.warrantydepartment`) }}</span>
                      <span class="timestamp">{{ reply.date }}</span>
                      <span class="bcfmessage">{{ reply.message }}</span>
                    </div>
                  </div>
                  {{ reply.messsage }}
                </template>
              </div>
            </div>
            <div
              v-else
              class="chat__messages"
            >
              <span class="chat__empty">{{ $t('account.account.myWarranties.notAvailable') }}</span>
            </div>
            <message-reply
              :id="warranty.id"
              :existing-message="warranty.commercialreply ? warranty.commercialreply : []"
              @updatereply="UpdateReply($event)"
            />
          </div>
        </collapse> -->

        <SectionTitle>{{ $t(`account.account.myWarranties.contactinformation`) }}</SectionTitle>
        <table class="table table--striped m-b-4">
          <tr>
            <td width="1">
              <i class="uil uil-phone" />
            </td>
            <td><a :href="'tel:'+warranty.phonenumber">{{ warranty.phonenumber ? warranty.phonenumber : 'N/A' }}</a></td>
          </tr>
          <tr>
            <td><i class="uil uil-envelope" /></td>
            <td><a :href="'mailto:'+warranty.email">{{ warranty.email ? warranty.email : 'N/A' }}</a></td>
          </tr>
        </table>

        <SectionTitle>{{ $t(`account.account.myWarranties.warrantydetails`) }}</SectionTitle>
        <table class="table table--striped m-b-4">
          <tr>
            <td width="50%">
              <strong>{{ $t(`account.account.myWarranties.productdescription`) }}</strong>
            </td>
            <td>{{ warranty.description ? warranty.description : 'N/A' }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t(`account.account.myWarranties.serialNumber`) }}</strong></td>
            <td>{{ warranty.serialnumber ? warranty.serialnumber : 'N/A' }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('account.account.myWarranties.issueWhen') }}</strong></td>
            <td>{{ warranty.purchase ? $t(`account.account.myWarranties.${warranty.purchase}`) : $t(`account.account.myWarranties.notAvailable`) }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('account.account.myWarranties.treatment') }}</strong></td>
            <td>{{ warranty.treatment ? $t(`account.account.myWarranties.${warranty.treatment}`) : $t(`account.account.myWarranties.notAvailable`) }}</td>
          </tr>
        </table>

        <!-- Summary -->
        <div class="m-b-4">
          <SectionTitle>{{ $t(`account.account.myWarranties.summary`) }}</SectionTitle>
          <p>{{ warranty.summary ? warranty.summary : 'N/A' }}</p>
        </div>

        <!-- Pickup info -->
        <div class="m-b-4">
          <SectionTitle>{{ $t(`account.account.myWarranties.pickup`) }}</SectionTitle>
          <p>{{ warranty.pickupname ? warranty.pickupname + ': ' : '' }}{{ warranty.street ? warranty.street : 'N/A' }}, {{ warranty.postalcode ? warranty.postalcode : 'N/A' }} {{ warranty.city ? warranty.city : 'N/A' }} {{ warranty.country ? warranty.country : 'N/A' }}</p>
        </div>

        <!-- Warranty report -->
        <div
          v-if="warranty.warrantyreport"
          class="m-b-4"
        >
          <SectionTitle>{{ $t(`account.account.myWarranties.warrantyreport`) }}</SectionTitle>
          <a
            class="list__item"
            :href="`${AWS_BASE_URL}pdf/warranty_${warranty.warrantynumber}.pdf`"
            target="_blank"
          >
            <span class="list__item__icon">
              <i class="uil uil-file-alt" />
            </span>
            <span class="list__item__content">
              {{ `Warranty_${warranty.warrantynumber}.pdf` }}
            </span>
          </a>
        </div>
        <!-- Pickup document -->
        <div
          v-if="warranty.pickupdocument"
          class="m-b-4"
        >
          <SectionTitle>{{ $t(`account.account.myWarranties.pickupdocument`) }}</SectionTitle>
          <a
            class="list__item"
            :href="`${AWS_BASE_URL}pdf/returns_${warranty.warrantynumber}.pdf`"
            target="_blank"
          >
            <span class="list__item__icon">
              <i class="uil uil-file-alt" />
            </span>
            <span class="list__item__content">
              {{ `Returns_${warranty.warrantynumber}.pdf` }}
            </span>
          </a>
        </div>
        <template v-if="warranty.proofofpurchase">
          <SectionTitle>{{ $t(`account.account.myWarranties.purchaseProof`) }}</SectionTitle>
          <!-- start media -->
          <div
            v-if="(hasProofGallery && !proofGallery.image0.pdf)"
            id="photos"
            class="m-t-4"
          >
            <proof-gallery
              ref="proofGallery"
              :key="warranty.id"
              :images="proofGallery"
              :first="proofGallery.image"
            />
          </div>
          <div
            v-else-if="(hasProofGallery && proofGallery.image0.pdf)"
          >
            <a
              class="doc__item"
              :href="proofGallery.image0.thumb"
              target="_blank"
            >
              <span class="doc__item__icon">
                <i class="uil uil-file-alt" />
              </span>
              <span class="doc__item__content">
                {{ proofGallery.image0.name }}
              </span>
            </a>
          </div>
          <!-- end media -->
        </template>
        <SectionTitle>{{ $t(`account.account.myWarranties.defect`) }}</SectionTitle>
        <template v-if="warranty">
          <!-- start media -->
          <div
            v-if="hasDefectGallery"
            id="photos"
          >
            <defect-gallery
              ref="defectGallery"
              :key="warranty.id"
              :images="defectGallery"
              :first="warranty.defect.image0"
            />
          </div>
          <div v-else>
            <!--galery should always be on the page (not visible), used in lightbox main image-->
            <defect-gallery
              ref="defectGallery"
              :key="warranty.id"
              :images="defectGallery"
              :first="warranty.defect.image0"
            />
          </div>  <!-- end media -->
        </template>
        <template v-else-if="loaded">
          <div
            v-for="(image, index) in warranty.defect.defect"
            :key="index"
          >
            <a
              :href="`${AWS_BASE_URL}${image.key}`"
              target="_blank"
            >
              <img
                class="img-fluid img-thumbnail"
                width="200px"
                loading="lazy"
                :src="`${AWS_BASE_URL}${image.key}`"
              >
            </a>
          </div>
        </template>
      </div>
    </bcf-aside-content>
    <bcf-aside-footer>
      <button
        class="button button--outline"
        @click="close()"
      >
        <!-- TODO: close van maken ipv cancel? -->
        {{ $t(`account.account.myWarranties.cancel`) }}
      </button>
    </bcf-aside-footer>
  </bcf-aside>
</template>
<script>
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import defectGallery from '@/components/mywarranties/defectGallery.vue';
import proofGallery from '@/components/mywarranties/proofGallery.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import Badge from '@/elements/Badge.vue';
import { fetchDetail } from '@/api/warranty';
import { formatDate } from '@/utils/dateUtils';
import loader from '@/elements/loader.vue';
import messageReply from '@/components/mywarranties/messageReply.vue';
import collapse from '@/components/collapse.vue';
import { mapState } from 'vuex';

export default {
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    defectGallery,
    proofGallery,
    bcfAsideFooter,
    SectionTitle,
    Badge,
    loader,
    messageReply,
    collapse,
  },
  data() {
    return {
      warranty: {},
      loaded: false,
      AWS_BASE_URL: process.env.VUE_APP_AWS_BASE_URL,
      defectGallery: [],
      proofGallery: [],
      hasDefectGallery: false,
      hasProofGallery: false,
    };
  },
  computed: {
    ...mapState({
      userType: (state) => state.auth.user.type,
    }),
    countNewMessages() {
      if (this.warranty.commercialreply && this.warranty.yiinewchatmessage) {
        const cusidx = this.warranty.commercialreply.slice().reverse().findIndex(o => o.type === 'CUSTOMER');
        if (cusidx < 0) {
          return this.warranty.commercialreply.length;
        }
        const idx = this.warranty.commercialreply.length - cusidx;
        const len = this.warranty.commercialreply.length;
        return len - idx;
      }
      return 0;
    },
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    setAsRead() {
      if (this.$store.state.auth.user.type === 1) {
        this.warranty.yiinewchatmessage = '';
      }
    },
    UpdateReply(e) {
      if (this.userType === 1) {
        this.setAsRead();
      }
      this.warranty.commercialreply = e;
    },
    openGallery() {
      if (this.$refs.defectGallery) {
        this.$refs.defectGallery.openGallery(0);
      }
    },
    openProofGallery() {
      if (this.$refs.proofGallery) {
        this.$refs.proofGallery.openGallery(0);
      }
    },
    close() {
      this.setAsRead();
      this.$store.dispatch('myWarranties/update');
      this.$router.push({ name: 'myWarranties' });
    },
    customFormatter(date) {
      return (date) ? formatDate(date) : '-';
    },
    async fetch() {
      try {
        const result = await fetchDetail(this.$route.params.id);
        // Media Gallery
        const img = {};
        if (result.warrantynumber) {
          const len = result.defect.length;
          for (let i = 0; i < len; i += 1) {
            const objName = `image${i}`;
            const objValue = {
              thumb: `${this.AWS_BASE_URL}${result.warrantynumber}/${result.defect[i]}`,
              highres: `${this.AWS_BASE_URL}${result.warrantynumber}/${result.defect[i]}`,
            };
            img[objName] = objValue;
          }
        } else {
          const len = result.defect.length;
          for (let i = 0; i < len; i += 1) {
            const objName = `image${i}`;
            const objValue = {
              thumb: `${this.AWS_BASE_URL}${result.s3folder}/${result.defect[i]}`,
              highres: `${this.AWS_BASE_URL}${result.s3folder}/${result.defect[i]}`,
            };
            img[objName] = objValue;
          }
        }
        this.defectGallery = img;
        this.hasDefectGallery = Object.keys(result.defect).length > 0;
        const proofImg = {};
        if (result.proofofpurchase) {
          if (result.warrantynumber) {
            const objName = 'image0';
            const objValue = {
              thumb: `${this.AWS_BASE_URL}${result.warrantynumber}/${result.proofofpurchase}`,
              highres: `${this.AWS_BASE_URL}${result.warrantynumber}/${result.proofofpurchase}`,
              pdf: result.proofofpurchase.includes('.pdf') || result.proofofpurchase.includes('.PDF'),
              name: result.proofofpurchase,
            };
            proofImg[objName] = objValue;
          } else {
            const objName = 'image0';
            const objValue = {
              thumb: `${this.AWS_BASE_URL}${result.s3folder}/${result.proofofpurchase}`,
              highres: `${this.AWS_BASE_URL}${result.s3folder}/${result.proofofpurchase}`,
              pdf: result.proofofpurchase.includes('.pdf') || result.proofofpurchase.includes('.PDF'),
              name: result.proofofpurchase,
            };
            proofImg[objName] = objValue;
          }
        } else {
          proofImg.image = {};
        }
        this.proofGallery = proofImg;
        if (Object.prototype.hasOwnProperty.call(result, 'proofofpurchase')) {
          this.hasProofGallery = true;
        }
        // end Media Gallery
        this.warranty = result;
        this.loaded = true;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
