<template>
  <div>
    <router-view />

    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <span class="main__title__action--left">
                <router-link :to="{name: 'account'}">
                  <i class="uil uil-arrow-left" />
                </router-link>
              </span>
              <h1>{{ $t('account.settings.myLocations.title') }}</h1>

              <span class="main__title__action--right d-none d-md">
                <router-link :to="'/account/locations/new'">
                  <i class="uil uil-plus-circle" />
                </router-link>
              </span>

              <router-link
                :to="'/account/locations/new'"
                class="button button--secondary d-md-none"
              >
                {{ $t('account.settings.myLocations.create') }}
              </router-link>
            </div>
          </div>
        </div>
      </header>
    </div>

    <div class="container m-t-2">
      <div class="row">
        <div class="col">
          <!-- TODO : replace with search input component -->
          <div class="form-group form-group--search">
            <input
              v-model="searchQ"
              type="search"
              :placeholder="$t('search') + '...'"
              @input="search"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col">
          <div class="list">
            <router-link
              v-for="location in locationsResults"
              v-once
              :key="location.externalid"
              :to="'/account/locations/' + location.externalid"
            >
              <ListItemLocation>
                <strong>{{ location.Name }}
                  <span class="m-l-1"><Badge
                    v-if="location.default===1"
                    type="unread"
                  >Default</Badge></span>
                  <span class="m-l-1"><Badge
                    v-if="!location.externalid"
                    type="danger"
                  >Invoicing</Badge></span>
                </strong>
                <small>
                  {{ `${location.street}, ${location.postalcode}
                  ${location.city} (${$t(`countries.${location.country}`)})` }}
                </small>
              </ListItemLocation>
            </router-link>
          </div>

          <div v-if="locationsResults.length === 0">
            <emptystate icon="store">
              Geen locaties...
            </emptystate>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import { mapState } from 'vuex';
import emptystate from '@/elements/EmptyState.vue';
import ListItemLocation from '@/elements/ListItemLocation.vue';
import Badge from '@/elements/Badge.vue';

export default {
  components: { ListItemLocation, emptystate, Badge },
  metaInfo() {
    return {
      title: this.$t('account.settings.myLocations.title'),
    };
  },
  data() {
    return {
      locationsResults: [],
    };
  },
  computed: {
    ...mapState({
      locations: (state) => state.customer.selectedCustomer.addresses,
    }),
  },
  created() {
    this.searchQ = null;
    this.locationsResults = this.$store.state.customer.selectedCustomer.addresses;
  },
  methods: {
    search() {
      if (this.searchQ.length > 0) {
        this.$search(this.searchQ, this.locations, {
          keys: ['Name', 'street', 'city'],
          defaultAll: true,
          shouldSort: false,
          distance: 10,
        }).then((results) => {
          this.locationsResults = results;
        });
      } else {
        this.locationsResults = this.$store.state.customer.selectedCustomer.addresses;
      }
    },
  },
};
</script>
