<template>
  <bcf-aside @close="close">
    <bcfAsideHeader :close="close">
      {{ $t('account.settings.companyDetails.title') }}
    </bcfAsideHeader>
    <bcfAsideContent>
      <loading-overlay :loading="inProgress">
        <div
          v-if="!isSucces && error"
          class="m-b-4"
        >
          <Message
            type="error"
            :icon="true"
          >
            {{ $t(`api.UnexpectedError`) }}
          </Message>
        </div>
        <div
          v-if="isSucces"
          class="m-b-4"
        >
          <Message
            type="success"
            :icon="true"
          >
            {{ $t('account.settings.companyDetails.message') }}
          </Message>
        </div>

        <form v-else>
          <SectionTitle>{{ $t('account.settings.companyDetails.data') }}</SectionTitle>
          <div class="form-group">
            <label>{{ $t('account.settings.companyDetails.companyName') }}</label>
            <input
              v-model="Customer.selectedCustomer.name"
              :disabled="!editing"
              class="form-control"
              type="text"
            >
          </div>
          <div class="form-group">
            <label>{{ $t('account.settings.companyDetails.vat') }}</label>
            <input
              v-model="Customer.selectedCustomer.attributes.enterprisenr"
              :disabled="!editing"
              class="form-control"
              type="text"
            >
          </div>

          <SectionTitle>{{ $t('account.settings.companyDetails.invoiceAddress') }}</SectionTitle>

          <div class="form-group">
            <label>{{ $t('account.settings.companyDetails.street') }}</label>

            <input
              v-model="Customer.selectedCustomer.invoiceaddress.street"
              :disabled="!editing"
              class="form-control"
              type="text"
            >
          </div>
          <div class="row">
            <div class="col-3">
              <div class="form-group">
                <label>{{ $t('account.settings.companyDetails.postalcode') }}</label>

                <input
                  v-model="Customer.selectedCustomer.invoiceaddress.postalcode"
                  :disabled="!editing"
                  class="form-control"
                  type="text"
                >
              </div>
            </div>
            <div class="col-9">
              <div class="form-group">
                <label>{{ $t('account.settings.companyDetails.city') }}</label>

                <input
                  v-model="Customer.selectedCustomer.invoiceaddress.city"
                  :disabled="!editing"
                  class="form-control"
                  type="text"
                >
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>{{ $t('account.settings.companyDetails.country') }}</label>

            <select
              v-model="Customer.selectedCustomer.invoiceaddress.country"
              :disabled="!editing"
            >
              <option
                v-for="(countryValue, countryKey) in availableCountries"
                :key="countryKey"
                class="form-control"
                :value="countryKey"
              >
                {{ availableCountries[countryKey] }}
              </option>
            </select>
          </div>

          <div
            v-if="editing"
            class="m-t-4"
          >
            <!-- TODO: translate -->
            <Message
              type="update"
              :icon="true"
            >
              <!-- eslint-disable-next-line max-len -->
              <strong>Let op:</strong> na het aanpassen wordt een aanvraag verstuurd om de wijzigingen door te voeren. Dit kan even duren.
            </Message>
          </div>
        </form>
      </loading-overlay>
    </bcfAsideContent>
    <bcfAsideFooter v-if="!isSucces">
      <button
        v-if="!editing"
        class="button button--outline"
        :disabled="inProgress"
        @click="close()"
      >
        Cancel
      </button>
      <button
        v-if="editing"
        class="button button--outline"
        :disabled="inProgress"
        @click.prevent="cancelcompanyDetails()"
      >
        {{ $t('account.settings.companyDetails.cancel') }}
      </button>
      <button
        v-if="editing"
        class="button button--primary"
        :class="{'button--loading': inProgress}"
        :disabled="inProgress"
        @click.prevent="save(Customer)"
      >
        {{ $t('account.settings.companyDetails.sent') }}
      </button>
      <button
        v-if="!editing"
        class="button button--primary"
        :disabled="inProgress"
        @click.prevent="editcompanyDetails()"
      >
        {{ $t('account.settings.companyDetails.change') }}
      </button>
    </bcfAsideFooter>
  </bcf-aside>
</template>

<style scoped>
</style>

<script>
import { mapState } from 'vuex';
import bcfAside from '@/components/bcfAside.vue';
import { RfcCustomer } from '@/api/api';
import Message from '@/components/Message.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import loadingOverlay from '@/elements/LoadingOverlay.vue';

export default {
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideFooter,
    bcfAsideContent,
    SectionTitle,
    Message,
    loadingOverlay,
  },
  data() {
    return {
      editing: null,
      isSucces: false,
      availableCountries: this.$i18n.messages[this.$i18n.locale].countries,
      countries: this.$i18n.messages[this.$i18n.locale].countries,
      inProgress: false,
      error: '',
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    Customer() {
      return JSON.parse(JSON.stringify(this.$store.state.customer));
    },
  },
  methods: {
    async save(customer) {
      this.inProgress = true;

      const companyObj = {};
      companyObj.command = 'update';
      companyObj.name = customer.selectedCustomer.name;
      companyObj.city = customer.selectedCustomer.invoiceaddress.city;
      companyObj.country = customer.selectedCustomer.invoiceaddress.country;
      companyObj.postalcode = customer.selectedCustomer.invoiceaddress.postalcode;
      companyObj.street = customer.selectedCustomer.invoiceaddress.street;
      companyObj.vat = customer.selectedCustomer.attributes.enterprisenr;

      const rfcObj = {};
      rfcObj.userid = this.$store.state.auth.user.id;
      rfcObj.cid = this.$store.state.customer.selectedCustomer.id;
      rfcObj.company = companyObj;

      try {
        this.isSucces = await RfcCustomer(rfcObj);
      } catch (e) {
        this.error = e;
      } finally {
        this.inProgress = false;
      }
    },
    editcompanyDetails() {
      this.editing = true;
    },
    cancelcompanyDetails() {
      this.editing = false;
    },
    close() {
      this.$router.push({ name: 'account' });
    },
  },
};
</script>
