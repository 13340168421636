<template>
  <div>
    <router-link
      class="list__item list__item--order"
      :to="{name: 'detail',params: {id: item.id}}"
    >
      <div class="list__item__icon">
        <i class="uil uil-parcel" />
      </div>
      <span class="list__item__info">
        <div class="list__item__title">
          <header>
            <strong v-if="item.warrantynumber">{{ item.warrantynumber }}</strong>
            <strong v-else>-</strong>
            <Badge
              v-if="item.statusmapping==='rejected'"
              class="m-l-1"
              type="danger"
            >{{
              $t(`account.account.myWarranties.status.${item.statusmapping}`) }}</Badge>
            <Badge
              v-if="item.translationkey && item.statusmapping!=='rejected'"
              class="m-l-1"
              type="paid"
            >{{
              $t(`account.account.myWarranties.status.${item.statusmapping}`) }}</Badge>
            <Badge v-if="item.yiinewchatmessage === 'WARRANTY' && countNewMessages > 0">
              <span
                v-b-tooltip.hover.top
                :title="lastBcfMessage"
              >
                {{ countNewMessages }} {{ $t(`account.account.myWarranties.messages`) }}
              </span>
            </Badge>
          </header>

          <time v-if="item.claimdate">
            {{ formatdate(item.claimdate) }}
          </time>
        </div>

        <div class="list__item__products-overview">
          <div
            v-if="item.description"
            class="list__item__products-overview__item"
          >
            <div class="list__item__products-overview__item__section">
              {{ item.description }}
            </div>
          </div>
          <div
            v-if="item.serialnumber"
            class="list__item__products-overview__item"
          >
            <div class="list__item__products-overview__item__section">
              {{ $t(`account.account.myWarranties.serialNumber`) }}: {{ item.serialnumber }}
            </div>
          </div>
          <div
            v-if="item.reference"
            class="list__item__products-overview__item"
          >
            <div class="list__item__products-overview__item__section">
              {{ $t(`account.account.myWarranties.reference`) }}: {{ item.reference }}
            </div>
          </div>
          <div
            v-if="item.invoicenumber"
            class="list__item__products-overview__item"
          >
            <div class="list__item__products-overview__item__section">
              {{ $t(`account.account.myWarranties.invoice`) }}: {{ item.invoicenumber }}
            </div>
          </div>
        </div>
      </span>
    </router-link>
  </div>
</template>
<script>
import Badge from '@/elements/Badge.vue';
import { formatDate } from '@/utils/dateUtils';

export default {
  name: 'ListItemWarranty',
  components: {
    Badge,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    lastBcfMessage() {
      if (this.item.commercialreply && this.item.yiinewchatmessage) {
        const found = this.item.commercialreply.slice().reverse().findIndex((o) => o.type === 'WARRANTY');
        return this.item.commercialreply.slice().reverse()[found].message;
      }
      return 'No BCF message!';
    },
    countNewMessages() {
      if (this.item.commercialreply && this.item.yiinewchatmessage) {
        const cusidx = this.item.commercialreply.slice().reverse().findIndex((o) => o.type === 'CUSTOMER');
        if (cusidx < 0) {
          return this.item.commercialreply.length;
        }
        const idx = this.item.commercialreply.length - cusidx;
        const len = this.item.commercialreply.length;
        return len - idx;
      }
      return 0;
    },
  },
  methods: {
    formatdate(dte) {
      return formatDate(dte);
    },
  },
};
</script>
