<template>
  <div>
    <router-view />

    <bcf-aside
      v-if="isMobile"
      v-show="mobileFiltersOpen"
    >
      <bcfAsideHeader :close="closeMobileFilters">
        {{ $t('items.filters') }}
      </bcfAsideHeader>
      <bcfAsideContent>
        <div class="products__filter">
          <my-customers-filters v-model="filters" />
        </div>
      </bcfAsideContent>
      <bcfAsideFooter>
        <span />
        <button
          class="button button--primary"
          @click="closeMobileFilters"
        >
          {{ $t('general.apply') }}
        </button>
      </bcfAsideFooter>
    </bcf-aside>

    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <router-link :to="{name: 'account'}">
                <i class="uil uil-arrow-left" />
              </router-link>
              <h1>{{ $t('account.account.myCustomers.title') }}</h1>
              <span
                v-if="isMobile"
                class="main__title__action--right"
                @click="mobileFiltersOpen = true"
              >
                <i class="uil uil-filter" />
                <Badge v-if="filterCount > 0">{{ filterCount }}</Badge>
              </span>
            </div>
          </div>
        </div>
      </header>
    </div>

    <div class="container">
      <!-- Loading -->
      <div
        v-if="!customers && inProgress"
        class="row"
      >
        <div class="col">
          <loader />
        </div>
      </div>

      <!-- Table -->
      <div
        v-if="customers"
        class="row"
      >
        <div class="col-sm-12 col-md-3">
          <my-customers-filters
            v-if="!isMobile"
            v-model="filters"
          />
        </div>
        <div class="col-sm-12 col-md-9">
          <div class="customers__controls m-md-t-2">
            <search
              v-model="searchVal"
              style="flex: 1 1 auto"
            />
            <router-link
              :to="{name: 'myCustomersDetail', params: {id:'new'}}"
            >
              <a
                class="button button--secondary m-b-2 customers__add"
              >
                <i class="uil uil-plus" />
              </a>
            </router-link>
          </div>

          <div class="customers__overview p-t-2">
            <div v-if="loadError">
              <Message
                class="m-t-2"
                type="error"
                :icon="true"
                :content="$t(`api.${loadError}`)"
                :show-close="true"
                @closeMessage="closeMessage"
              />
            </div>

            <EmptyState
              v-if="customers.length === 0"
              icon="users-alt"
            >
              {{ noResultText }}
              <loader v-if="inProgress" />
            </EmptyState>

            <table
              v-else-if="customers.length > 0"
              class="table table--striped m-b-4 customers-table"
            >
              <thead>
                <tr>
                  <th
                    :class="headClasses('name')"
                    @click="toggleSort('name')"
                  >
                    {{ $t(('account.account.myCustomers.firstName')) }}
                  </th>
                  <th
                    :class="headClasses('lastName')"
                    @click="toggleSort('lastName')"
                  >
                    {{ $t(('account.account.myCustomers.lastName')) }}
                  </th>
                  <th
                    :class="headClasses('email')"
                    @click="toggleSort('email')"
                  >
                    {{ $t(('account.account.myCustomers.email')) }}
                  </th>
                  <th
                    :class="headClasses('phone')"
                    @click="toggleSort('phone')"
                  >
                    {{ $t(('account.account.myCustomers.phone')) }}
                  </th>
                  <th
                    :class="headClasses('language')"
                    @click="toggleSort('language')"
                  >
                    {{ $t(('account.account.myCustomers.language')) }}
                  </th>
                  <!--<th
                    :class="headClasses('address')"
                    @click="toggleSortOn('address')"
                  >
                    {{ $t(('account.account.myCustomers.address')) }}
                  </th>-->
                </tr>
              </thead>

              <tbody>
                <loader v-if="inProgress" />

                <template
                  v-for="customer in visibleCustomers"
                >
                  <tr
                    :key="`${customer.id}`"
                    :class="{
                      'customer__row': true,
                      'customer__row--loading': customer.loading,
                    }"
                    @click="openCustomer(customer)"
                  >
                    <td
                      class="customers-table__firstName"
                    >
                      {{ customer.firstName }}
                    </td>
                    <td
                      class="customers-table__lastName"
                    >
                      {{ customer.lastName }}
                    </td>
                    <td
                      class="customers-table__email"
                    >
                      {{ customer.email }}
                    </td>
                    <td
                      class="customers-table__phone"
                    >
                      {{ customer.phone }}
                    </td>
                    <td
                      class="customers-table__language"
                    >
                      {{ customer.language }}
                    </td>
                    <td
                      class="customers-table__address"
                    >
                      {{ formatAddress(customer.address) }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <pagination
              class="m-t-4 m-b-4"
              :page="page"
              :pages="pages"
              :items-per-page="itemsPerPage"
              @pageChanged="changePage"
              @itemsPerPageChanged="itemsPerPageChanged"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import search from '@/components/Search.vue';
import loader from '@/elements/loader.vue';
import EmptyState from '@/elements/EmptyState.vue';
import Message from '@/components/Message.vue';
import pagination from '@/components/Pagination.vue';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import Badge from '@/elements/Badge.vue';
import { formatDate } from '@/utils/dateUtils';

import {
  GetCustomerRelations,
} from '@/api/api';
import { mapState } from 'vuex';

const myCustomersFilters = () => import(/* webpackChunkName: "myCustomers" */ '@/views/account/account/mycustomers/filters.vue');

const sortOptions = [
  {
    id: 'modifiedAt',
    value: (a, b) => {
      const sA = new Date(a.modifiedAt);
      const sB = new Date(b.modifiedAt);
      return sB.getTime() - sA.getTime();
    },
  },
  {
    id: 'name',
    value: (a, b) => {
      const valA = [a.firstName || '', a.lastName || ''];
      const valB = [b.firstName || '', b.lastName || ''];
      for (let i = 0; i < 2; ++i) {
        if (valA[i] < valB[i]) return -1;
        if (valA[i] > valB[i]) return 1;
      }
      return 0;
    },
  },
  {
    id: 'email',
    value: (a, b) => {
      const valA = a.email;
      const valB = b.email;
      if (valA < valB) return -1;
      if (valA > valB) return 1;
      return 0;
    },
  },
  {
    id: 'language',
    value: (a, b) => {
      const valA = a.language;
      const valB = b.language;
      if (valA < valB) return -1;
      if (valA > valB) return 1;
      return 0;
    },
  },
  {
    id: 'phone',
    value: (a, b) => {
      const valA = a.phone;
      const valB = b.phone;
      if (valA < valB) return -1;
      if (valA > valB) return 1;
      return 0;
    },
  },
  {
    id: 'createdAt',
    value: (a, b) => {
      const sA = new Date(a.createdAt);
      const sB = new Date(b.createdAt);
      return sB.getTime() - sA.getTime();
    },
  },
  {
    id: 'lastName',
    value: (a, b) => {
      const valA = [a.lastName || '', a.firstName || ''];
      const valB = [b.lastName || '', b.firstName || ''];
      for (let i = 0; i < 2; ++i) {
        if (valA[i] < valB[i]) return -1;
        if (valA[i] > valB[i]) return 1;
      }
      return 0;
    },
  },
];
export default {
  name: 'MyCustomers',
  metaInfo() {
    return {
      title: this.$t('account.account.myCustomers.title'),
    };
  },
  components: {
    search,
    EmptyState,
    loader,
    Message,
    pagination,
    myCustomersFilters,
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    bcfAsideFooter,
    Badge,
  },
  data() {
    return {
      isMobile: window.innerWidth < 768,
      allCustomers: [],
      customers: [],
      inProgress: true,
      loadError: '',
      itemsPerPage: 20,
      page: 1,
      filters: {
        sort: this.$route.query.sort ? this.$route.query.sort : 'modifiedAt',
        filters: [],
      },
      mobileFiltersOpen: false,
      actionLoading: false,
      sortOn: null,
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customer,
    }),
    customerid() {
      return this.customer.selectedCustomerId;
    },
    visibleCustomers() {
      return this.customers.slice(
        this.page * this.itemsPerPage - this.itemsPerPage,
        this.page * this.itemsPerPage,
      );
    },
    searchVal: {
      get() {
        return this.$route.query.search;
      },
      set(newVal) {
        this.$router.replace({
          name: this.$router.currentRoute.name,
          query: {
            ...this.$route.query,
            search: newVal,
          },
        });
        this.page = 1;
        this.search();
      },
    },
    pages() {
      const pages = [];
      for (
        let i = 0;
        i < this.customers.length / this.itemsPerPage;
        i += 1
      ) {
        pages.push(i + 1);
      }
      return pages;
    },
    noResultText() {
      if (this.inProgress) {
        return this.$t('account.account.myCustomers.loading');
      }
      if (this.searchVal || this.filterCount) {
        return this.$t('account.account.myCustomers.noResultsMatchingFilters');
      }
      return this.$t('account.account.myCustomers.noResults');
    },
    filterCount() {
      return Array.from(Object.entries(this.filters))
        .map(([k, v]) => {
          if (k === 'sort') return 0;
          if (v instanceof Array) {
            return v.length;
          }
          return (v) ? 1 : 0;
        })
        .reduce((a, b) => a + b, 0);
    },
  },
  watch: {
    filters: {
      handler() {
        this.search();
      },
      deep: true,
    },
    $route: {
      handler() {
        if (this.$route.query.refresh) {
          this.fetchData()
            .then(() => {
              this.$router.push({
                name: this.$route.name,
                params: this.$route.params,
                query: {
                  ...this.$route.query,
                  refresh: undefined,
                },
              });
            });
        }
      },
    },
  },
  async mounted() {
    this.fetchData();
  },
  created() {
    window.addEventListener('resize', this.updateWindowSize);
    this.updateWindowSize();
  },
  destroyed() {
    window.removeEventListener('resize', this.updateWindowSize);
  },
  methods: {
    async fetchData() {
      try {
        this.allCustomers = await GetCustomerRelations(this.customerid);

        this.search();
        // might want to switch this to use the store
        // await this.$store.dispatch('myStock/get');

        this.inProgress = false;
      } catch (err) {
        this.inProgress = false;
        this.loadError = err.name;
        throw Object.assign(err, { status: 500 });
      }
    },
    openCustomer(customer) {
      this.$router.push({ name: 'myCustomersDetail', params: { id: customer.id } });
    },
    closeMobileFilters() {
      this.mobileFiltersOpen = false;
    },
    updateWindowSize() {
      this.isMobile = window.innerWidth < 768;
    },
    changePage(page) {
      this.page = parseInt(page, 10);
      this.customers.slice(
        this.page * this.itemsPerPage - this.itemsPerPage,
        this.page * this.itemsPerPage,
      );
    },
    itemsPerPageChanged(itemsPerPage) {
      this.itemsPerPage = parseInt(itemsPerPage, 10);
      this.changePage(1);
    },
    closeMessage() {
      // this.$store.dispatch('myorders/clear');
    },
    formatDate(/** Date */ d) {
      return new Date(d).toLocaleString(undefined, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      });
    },
    formatDatetime(/** Date */ d) {
      if (d) {
        const date = new Date(d);
        return [
          formatDate(d),
          [date.getHours(), date.getMinutes()].map((c) => ((`00${c}`).slice(-2)))
            .join(':'),
        ].join(' ');
      }
      return null;
    },
    formatAddress(d) {
      return d ? [
        `${d.street} ${d.number}`.trim(),
        `${d.postalCode} ${d.city}`.trim(),
      ].filter((x) => x).join(', ') : undefined;
    },
    search() {
      this.page = 1;
      const filters = {};
      this.filters.filters.forEach((f) => {
        if (!filters[f.type]) {
          filters[f.type] = [];
        }
        filters[f.type].push(f.value);
      });

      let allCustomers = this.allCustomers.filter((s) =>
        // No filters defined for now
        true);

      const sortCriteria = sortOptions.map((x) => {
        let add = {};
        if (this.filters.sort && this.filters.sort.id === x.id) {
          add = { ...this.filters.sort, order: 0 };
        } else {
          add = { order: Number.MAX_SAFE_INTEGER };
        }
        return {
          ...x,
          ...add,
        };
      })
        .sort((x, y) => x.order - y.order);
      allCustomers = allCustomers.sort((a, b) => {
        for (const sort of sortCriteria) {
          const result = sort.value(a, b);
          if ((result > 0 || result < 0) && Number.isFinite(result)) {
            return sort.invert ? -result : result;
          }
        }
        return 0;
      });

      if (this.$route.query.search) {
        this.$search(this.$route.query.search, allCustomers, {
          keys: ['firstName', 'lastName', 'email'],
          defaultAll: true,
          caseSensitive: false,
          shouldSort: false,
          tokenize: false,
          matchAllTokens: true,
          findAllMatches: true,
          threshold: 0.2,
          location: 2,
          distance: 50,
          maxPatternLength: 32,
          minMatchCharLength: 1,
        })
          .then((results) => {
            this.customers = results;
          });
      } else {
        this.customers = allCustomers;
      }
    },
    toggleSort(field) {
      const { filters } = this;
      let newSort = filters.sort;
      if (!filters.sort || filters.sort.id !== field) {
        newSort = {
          id: field,
          invert: false,
        }; // Basic sort on 1st click
      } else if (!filters.sort.invert) {
        newSort = {
          id: field,
          invert: true,
        }; // Invert the sort on 2nd click
      } else {
        newSort = null; // unset the sort after 3rd click
      }
      this.filters = {
        ...this.filters,
        sort: newSort,
      };
      this.search(); // Update the list
    },
    headClasses(field, type) {
      const { sort: sortOn } = this.filters;
      const classes = {
        [`customers-table__${field}`]: true,
        'customers-table__sorted': sortOn && sortOn.id === field,
        'customers-table__sorted--invert': sortOn && sortOn.id === field && sortOn.invert,
      };
      if (type) {
        classes[`customers-table__${type}`] = true;
      }
      return classes;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/_colors.scss";
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";

$warn_bg: #ffede1;
$warn_border: #f8bf98;

[v-cloak] > * {
  display: none;
}

[v-cloak]::before {
  content: "loading…";
}

.customers {
  &__controls {
    display: flex;
    flex-direction: row;
    justify-items: stretch;
    align-items: stretch;
  }

  &__add {
    padding: 1.5rem;
  }

  &__overview {
    background-color: #fff;
    padding: 1rem 1rem 0 1rem;
    border-radius: 3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.confirm-message {
  background-color: $warn_bg;
  border: 1px solid $warn_border;
  font-size: 1.75rem;
  padding: 2rem;
  color: $red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .button--confirm {
    background-color: $blue;
    color: white;
    font-weight: normal;
  }
}

.customers-table {

  thead > tr > th {
    font-size: 1.25rem;
    text-transform: none;
    font-weight: 600;
    vertical-align: top;

    user-select: none;
    cursor: pointer;
    position: relative;

    &:after {
      //position: absolute;
      //right: 0;
      //top: 0;
      //bottom: 0;
      //padding: 1.5rem .5rem 1.5rem .25rem;
      padding: 0 .5rem 0 .5rem;
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.25rem;
      font-weight: bold;
      color: black;
    }

    &.customers-table__sorted:after {
      content: "↓";
    }

    &.customers-table__sorted--invert:after {
      content: "↑";
    }
  }

  &__half-row {
    &--top {
      padding-bottom: .25rem;
      border-bottom: none;
    }

    &--bottom {
      padding-top: .25rem;
      border-top: none;
    }
  }

  &__select {
    width: 4rem;
  }

  &__code {
    width: 10rem;
  }

  &__detail {
    text-overflow: ellipsis;
  }

  &__status {
    width: 14.5rem;
    text-align: center;
    position: relative;
  }

  &__date {
    width: 10.5rem;
    text-align: center;
  }

  &__visible {
    width: 8rem;
  }

  &__toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    font-size: 2.5rem;
    line-height: 1em;
    cursor: pointer;
  }

  tbody {
    .customers-table {
      &__date {
        font-size: 90%;
      }
    }
  }
}

.location-select{
  width: 100%;
  padding: 0;
}

.customer {
  &__detail {
    display: flex;
    align-items: center;

    &__content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      padding: 0 2rem;

      &__description {
        text-overflow: ellipsis;
      }

      &__salesorder {
        color: blue;
        text-decoration: none;
      }
    }
  }

  &__row {
    position: relative;
    cursor: pointer;
    transition: opacity 100ms;

    &--loading {
      opacity: .75;
    }

    &:hover {
      td{
        background: change-color($yellow, $alpha: .05);
      }
    }
  }

  &__loader {
    position: absolute;
    right: 100%; // offset to the left
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.table--striped {
  tr:last-child{
    td {
      border-bottom: none;
    }
  }

}
</style>
