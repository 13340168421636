<template>
  <bcf-aside @close="close">
    <bcfAsideHeader :close="close">
      {{ $t('account.account.myExports.title') }}
    </bcfAsideHeader>
    <bcfAsideContent>
      <Message
        v-if="errorMessage"
        type="error"
        :icon="true"
        :content="$t(`api.${errorMessage}`)"
        :show-close="true"
        @closeMessage="closeMessage"
      />

      <div v-if="!loaded">
        <loader class="m-b-2" />
      </div>

      <div
        v-for="e in allexports"
        :key="e.key"
      >
        <ListItem icon-before>
          <i class="uil uil-cloud-download" />
          <a
            :href="e.url"
            target="_blank"
          >{{ $t('account.account.exports.' + e.key) }}</a>
        </ListItem>
      </div>
    </bcfAsideContent>
  </bcf-aside>
</template>
<script>
import { mapState } from 'vuex';
import loader from '@/elements/loader.vue';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import ListItem from '@/elements/ListItem.vue';
import { getExports } from '@/api/api';
import Message from '@/components/Message.vue';

export default {
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    ListItem,
    loader,
    Message,
  },
  data() {
    return {
      allexports: [],
      loaded: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapState({
      selectedCustomer: (state) => state.customer.selectedCustomer,
      language: (state) => state.language.currentApi,
    }),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    close() {
      this.$router.push({ name: 'account' });
    },
    closeMessage() {
      this.errorMessage = '';
    },
    async fetchData() {
      try {
        this.loaded = false;
        this.allexports = await getExports(this.selectedCustomer.id, this.language);
        this.loaded = true;
      } catch (err) {
        this.loaded = true;
        this.errorMessage = err.message;
      }
    },
  },
};
</script>
<style scoped>
</style>
