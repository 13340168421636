<template>
  <form class="chat__input">
    <table style="width:100%">
      <tr>
        <td>
          <div class="form-group">
            <input
              v-model="Reply"
              type="text"
              :placeholder="$t('account.account.myWarranties.placeholder')"
            >
          </div>
        </td>
        <td>
          <button
            class="button button--primary button--icon"
            @click.prevent="reply"
          >
            <i class="uil uil-message" />
          </button>
        </td>
      </tr>
      <tr v-if="userType===2">
        <td colspan="3">
          <div class="form-group form-group--checkbox">
            <checkbox v-model="visibleForCustomer">
              {{ $t("account.account.myWarranties.visible") }}
            </checkbox>
          </div>
        </td>
        <td />
      </tr>
    </table>
  </form>
</template>
<script>
// import { formatDate } from '@/utils/dateUtils';
import { mapState } from 'vuex';
import { replyMessage } from '@/api/warranty';
import checkbox from '@/elements/checkbox.vue';

export default {
  components: {
    checkbox,
  },
  props: {
    existingMessage: {
      type: Array,
      required: false,
      default: null,
    },
    id: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      Reply: '',
      visibleForCustomer: false,
    };
  },
  computed: {
    ...mapState({
      userType: (state) => state.auth.user.type,
    }),
  },
  methods: {
    async reply() {
      const message = this.existingMessage;
      const currentDateTime = new Date();
      const curMonth = currentDateTime.getMonth() + 1;
      const formattedDate = `${currentDateTime.getDate()}-${curMonth}-${currentDateTime.getFullYear()} ${currentDateTime.getHours()}:${currentDateTime.getMinutes()}:${currentDateTime.getSeconds()}`;
      if (this.userType === 2) {
        message.push({
          type: 'REP', username: this.$store.state.auth.user.username, showCustomer: this.visibleForCustomer, message: this.Reply, date: formattedDate,
        });
      } else {
        message.push({ type: 'CUSTOMER', message: this.Reply, date: formattedDate });
      }
      this.Reply = '';
      await replyMessage({ reply: message, id: this.id });
      this.$emit('updatereply', message);
    },
  },
};
</script>
