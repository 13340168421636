<template>
  <table class="table table--striped m-b-4">
    <thead>
      <tr>
        <th class="description">
          {{ $t(descriptionLabel) }}
        </th>
        <th />
        <th class="value">
          {{ $t('account.account.myStats.count') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item of items"
        :key="item.code"
      >
        <td class="description">
          {{ item.description }}
        </td>
        <td />
        <td class="value">
          {{ item.count }}
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="totals">
        <td
          colspan="2"
          class="label"
        >
          {{ $t('account.account.myStats.total') }}:
        </td>
        <td class="value">
          {{ items.map(c=>c.count).reduce((a,b)=>a+b,0) }}
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      default: null,
    },
    descriptionLabel: {
      type: String,
      default: 'Model',
    },
  },
  data: () => ({

  }),
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.description{
  min-width: 12em;
}
.value{
  width: 5em;
  text-align: right;
}
.totals{
  text-align: right;
}
tr>.value, .totals>.label{
  font-weight: 600;
}
</style>
