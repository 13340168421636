<template>
  <div>
    <router-view @updateOverview="updateOverview" />

    <!-- Mobile filters -->
    <bcf-aside
      v-if="isMobile"
      v-show="mobileFiltersOpen"
    >
      <bcfAsideHeader :close="closeMobileFilters">
        {{ $t('items.filters') }}
      </bcfAsideHeader>
      <bcfAsideContent>
        <div class="products__filter">
          <my-orders-filters
            v-model="filters"
          />
        </div>
      </bcfAsideContent>
      <bcfAsideFooter>
        <span />
        <button
          class="button button--primary"
          @click="closeMobileFilters"
        >
          {{ $t('general.apply') }}
        </button>
      </bcfAsideFooter>
    </bcf-aside>

    <!-- Main view -->
    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <router-link :to="{name: 'account'}">
                <i class="uil uil-arrow-left" />
              </router-link>
              <h1>{{ $t('account.account.myOrders.title') }}</h1>
              <div class="main__title__actions">
                <!-- Excel link -->
                <a
                  v-if="excel"
                  class="main__title__action__icon"
                  :href="excel"
                >
                  <i class="uil uil-cloud-download" />
                </a>
                <!-- Mobile filter toggle -->
                <span
                  v-if="isMobile"
                  class="main__title__action__icon"
                  @click="mobileFiltersOpen = true"
                >
                  <i class="uil uil-filter" />
                  <Badge v-if="filters.filters.length > 0">{{ filters.filters.length }}</Badge>
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>

    <div class="container">
      <!-- Loading -->
      <div
        v-if="myOrderInProgress"
        class="row"
      >
        <div class="col">
          <loader />
        </div>
      </div>

      <!-- List -->
      <div
        v-else
        class="row"
      >
        <!-- Filter column -->
        <div class="col-sm-12 col-md-3">
          <my-orders-filters
            v-if="!isMobile"
            v-model="filters"
          />
        </div>
        <!-- Order content -->
        <div class="col-sm-12 col-md-9">
          <!-- Search field -->
          <search
            v-model="searchVal"
            class="m-md-t-2"
          />
          <!-- Order list -->
          <div class="products__overview p-t-2">
            <!-- Error msg -->
            <div v-if="myOrderError">
              <Message
                class="m-t-2"
                type="error"
                :icon="true"
                :content="$t(`api.${myOrderError}`)"
                :show-close="true"
                @closeMessage="closeMessage"
              />
            </div>
            <!-- Orders -->
            <OrderList
              v-if="allOrders"
              :orders="allOrders"
              :filters="filters"
              :search-val="searchVal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import search from '@/components/Search.vue';
import loader from '@/elements/loader.vue';
import Message from '@/components/Message.vue';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import Badge from '@/elements/Badge.vue';

import { GetOrders } from '@/api/api';

const myOrdersFilters = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/myorders/filters.vue');
const OrderList = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/myorders/orderList.vue');

export default {
  name: 'MyOrders',
  metaInfo() {
    return {
      title: this.$t('account.account.myOrders.title'),
    };
  },
  components: {
    search,
    loader,
    Message,
    myOrdersFilters,
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    bcfAsideFooter,
    Badge,
    OrderList,
  },
  data() {
    return {
      isMobile: window.innerWidth < 768,
      allOrders: [],
      myOrderInProgress: true,
      myOrderError: '',
      // eslint-disable-next-line no-nested-ternary
      filters: { sort: 'orderdate', filters: [{ type: 'bikesonly', value: true }] },
      mobileFiltersOpen: false,
      excel: null,
    };
  },
  computed: {
    searchVal: {
      get() {
        return this.$route.query.search;
      },
      set(newVal) {
        this.$router.push({
          name: this.$router.currentRoute.name,
          query: {
            ...this.$route.query,
            search: newVal,
          },
        });
      },
    },
  },
  async mounted() {
    this.fetchOrders();
  },
  created() {
    window.addEventListener('resize', this.updateWindowSize);
    this.updateWindowSize();
  },
  destroyed() {
    window.removeEventListener('resize', this.updateWindowSize);
  },
  methods: {
    async updateOverview() {
      await this.fetchOrders();
    },
    async fetchOrders() {
      try {
        const result = await GetOrders(this.$store.state.customer.selectedCustomerId);
        this.allOrders = result.data;
        this.excel = result.excel;
        await this.$store.dispatch('myInvoices/get');
        this.myOrderInProgress = false;
      } catch (err) {
        this.myOrderInProgress = false;
        this.myOrderError = err.name;
        throw Object.assign(err, { status: 500 });
      }
    },
    closeMobileFilters() {
      this.mobileFiltersOpen = false;
    },
    updateWindowSize() {
      this.isMobile = window.innerWidth < 768;
    },
    closeMessage() {
      this.$store.dispatch('myorders/clear');
    },
  },
};
</script>
<style scoped>
[v-cloak] > * {
  display: none;
}
[v-cloak]::before {
  content: "loading…";
}
</style>
