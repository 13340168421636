import { render, staticRenderFns } from "./newsAndUpdates.vue?vue&type=template&id=6b5d9664&scoped=true"
import script from "./newsAndUpdates.vue?vue&type=script&lang=js"
export * from "./newsAndUpdates.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b5d9664",
  null
  
)

export default component.exports