<template>
  <!-- TODO: Translate -->
  <bcf-aside @close="close">
    <bcfAsideHeader :close="close">
      {{ $t('account.account.myPoints.title') }}
    </bcfAsideHeader>
    <bcfAsideContent>
      <div class="points__banner m-b-4">
        <span class="points__amount">292</span>
      </div>

      <SectionTitle>Hoe werkt het?</SectionTitle>
      <!-- eslint-disable-next-line max-len -->
      <p>Je kan punten verdienen door pre-orders te plaatsen. 3% van deze omzet wordt omgezet in punten. Je kan deze punten gebruiken om promomateriaal aan te kopen.</p>

      <router-link
        to="#"
        class="button button--danger w-100"
      >
        Bekijk promomateriaal
      </router-link>
    </bcfAsideContent>
  </bcf-aside>
</template>

<style scoped>
</style>

<script>
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import SectionTitle from '@/elements/SectionTitle.vue';

export default {
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    SectionTitle,
  },
  methods: {
    close() {
      this.$router.push({ name: 'account' });
    },
  },
};
</script>
