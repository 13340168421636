<template>
  <!-- TODO: Translate -->
  <bcf-aside @close="close">
    <div
      v-if="isLoaded"
    >
      <bcfAsideHeader :close="close">
        {{ $t('account.account.myBalance.title') }}
      </bcfAsideHeader>
      <bcfAsideContent>
        <SectionTitle>{{ $t('account.account.myBalance.creditlimit') }}</SectionTitle>
        <!-- eslint-disable-next-line max-len -->
        <p>
          <price
            :price="creditlimit"
            :currency="currency"
          />
        </p>

        <SectionTitle>{{ $t('account.account.myBalance.balance') }}</SectionTitle>
        <!-- eslint-disable-next-line max-len -->
        <p
          v-if="totalBalance < creditlimit && isCreditlimit"
          class="form-text--success"
        >
          <price
            :price="totalBalance"
            :currency="currency"
          />
        </p>
        <p
          v-else
          class="form-text--error"
        >
          <price
            :price="totalBalance"
            :currency="currency"
          />
        </p>
        <Message
          v-if="totalBalance >= creditlimit && isCreditlimit && isPreviewMode"
          type="error"
          :icon="true"
          :content="$t('account.account.myBalance.message')"
          :show-close="false"
        >
          <strong>{{ $t('account.account.myBalance.messagetitle1') }}</strong> {{ $t('account.account.myBalance.messagetitle2') }}
        </Message>
      </bcfAsideContent>
    </div>
  </bcf-aside>
</template>

<style scoped>
</style>

<script>
import { mapGetters, mapState } from 'vuex';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import Message from '@/components/Message.vue';
import price from '@/elements/Price.vue';

export default {
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    SectionTitle,
    Message,
    price,
  },
  data() {
    return {
      creditlimit: 0,
      currency: '',
      isLoaded: false,
    };
  },
  computed: {
    totalBalance() {
      const { exchangerate } = this.selectedCustomer.attributes;
      if (this.currency !== 'EUR') {
        return ((this.selectedCustomer.attributes.BalanceProductionOrdersLCY + this.selectedCustomer.attributes.BalanceOpenInvoicesLCY) * exchangerate)
          + this.generated.toPay;
      }
      return this.selectedCustomer.attributes.BalanceProductionOrdersLCY
          + this.selectedCustomer.attributes.BalanceOpenInvoicesLCY + this.generated.toPay;
    },
    isCreditlimit() {
      return this.selectedCustomer.attributes.CreditLimitLCY > 0;
    },
    ...mapState({
      selectedCustomer: (state) => state.customer.selectedCustomer,
      isPreviewMode: (state) => state.global.isPreviewMode,
    }),
    ...mapGetters('cart', {
      generated: 'cartGenerated',
    }),
  },
  mounted() {
    this.currency = this.selectedCustomer.attributes.currency;
    const { exchangerate } = this.selectedCustomer.attributes;
    if (this.currency !== 'EUR') {
      this.creditlimit = this.selectedCustomer.attributes.CreditLimitLCY * exchangerate;
    } else {
      this.creditlimit = this.selectedCustomer.attributes.CreditLimitLCY;
    }
    this.isLoaded = true;
  },
  methods: {
    close() {
      if (this.useCloseEvent) {
        this.$emit('close');
      } else {
        this.$router.push(this.$store.state.global.previousRoute ? this.$store.state.global.previousRoute : { name: 'myOrders' });
      }
    },
  },
};
</script>
