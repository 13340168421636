<template>
  <div>
    <div
      v-for="(holiday, index) in holidays"
      :key="index"
      class="holiday-period"
    >
      <Datepicker
        :placeholder="$t('account.settings.myLocations.delivery.holidayfrom')"
        maximum-view="month"
        :disabled-dates="disabledFromDateForRow(index)"
        :language="lang"
        :format="dateFormatter"
        :value="loadDatePickerValue(index, 'from')"
        @selected="dateSelected(index, 'from', $event)"
      />
      <span class="holiday-period__divider">
        -
      </span>
      <Datepicker
        :placeholder="$t('account.settings.myLocations.delivery.holidayuntil')"
        maximum-view="month"
        :disabled-dates="disabledToDateForRow(index)"
        :language="lang"
        :format="dateFormatter"
        :value="loadDatePickerValue(index, 'to')"
        @selected="dateSelected(index, 'to', $event)"
      />
      <div class="holiday-period__icons">
        <i
          class="uil uil-minus-circle"
          @click.prevent="removeHoliday(index)"
        />
        <i
          v-if="index == holidays.length - 1"
          class="uil uil-plus-circle"
          @click.prevent="addHoliday"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import translations from '@/translations';
import { formatDate, toISODate } from '@/utils/dateUtils';

export default {
  components: {
    Datepicker,
  },
  props: {
    data: {
      type: Array,
      default: () => [{
        from: null,
        to: null,
      }],
    },
  },
  data() {
    return {
      holidays: this.data,
    };
  },
  computed: {
    lang() {
      return translations[this.$store.state.language.current].Datepicker;
    },
  },
  methods: {
    addHoliday() {
      this.holidays.push({
        to: null,
        from: null,
      });
    },
    removeHoliday(index) {
      if (this.holidays.length === 1) {
        this.holidays[0].from = null;
        this.holidays[0].to = null;
      } else {
        this.holidays.splice(index, 1);
      }
    },
    dateFormatter(date) {
      return formatDate(date);
    },
    disabledFromDateForRow(index) {
      // If there is a to date, from date must be before it.
      if (this.holidays[index].to) {
        return {
          from: new Date(this.holidays[index].to),
        };
      }
      return {};
    },
    disabledToDateForRow(index) {
      // If there is a from date, to date must be after it.
      if (this.holidays[index].from) {
        return {
          to: new Date(this.holidays[index].from),
        };
      }
      return {};
    },
    loadDatePickerValue(index, type) {
      // returns the state for the given index and type as a Date object for the DatePicker.
      return this.holidays[index][type] ? new Date(this.holidays[index][type]) : null;
    },
    dateSelected(index, type, date) {
      this.holidays[index][type] = toISODate(date);
      this.$emit('update:holidays', this.holidays);
    },
  },
};
</script>

<style scoped lang="scss">
.holiday-period {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.holiday-period__divider {
  margin: 0 0.75rem;
}

.holiday-period__icons {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 50px;
  flex-shrink: 0;
}

.date-input {
  &::placeholder {
    color: #c0c5cd;
  }

  &[disabled]::placeholder {
    color: #ebedf1;
  }
}

i {
  font-size: 2.4rem;
  color: #666666;

  &.uil-minus-circle {
    margin-left: 0.75rem;
  }
}
</style>
