<script>
import { Line } from 'vue-chartjs';
// import {generatePalette} from '@/utils/colorUtils';
import Color from 'color';

export default {
  extends: Line,
  props: {
    items: {
      type: Array,
      default: null,
    },
    range: {
      type: Array,
      default: () => {
        const t = new Date();
        return [new Date(t.getFullYear(), t.getMonth(), t.getDate() - 7), new Date(t.getFullYear(), t.getMonth(), t.getDate())];
      },
    },
    granularity: {
      type: String,
      default: 'day',
    },
  },
  data: () => ({
    chartdata: {
      labels: [],
      datasets: [
      ],
    },
    options: {
      scales: {
        xAxes: [{
          // stacked: true,
        }],
        yAxes: [{
          // stacked: true
        }],
      },
      responsive: true,
      maintainAspectRatio: false,
    },
  }),
  watch: {
    items(newResult) {
      this.prepareData(newResult);
    },
  },
  mounted() {
    try {
      this.prepareData(this.items);
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    prepareData(newResult) {
      try {
        const bins = [];
        let cur = new Date(this.range[0].getTime());
        while (cur.getTime() < this.range[1].getTime()) {
          const next = this.granularity === 'month'
            ? new Date(cur.getFullYear(), cur.getMonth() + 1, 1)
            : new Date(cur.getFullYear(), cur.getMonth(), cur.getDate() + 1); // Not all days are going to be 24hrs!! (Thanks to DST)
          const bin = {
            date: cur,
            label: this.granularity === 'month'
              ? `${(`00${cur.getMonth() + 1}`).slice(-2)}/${(`0000${cur.getFullYear()}`).slice(-4)}`
              : `${(`00${cur.getDate()}`).slice(-2)}/${(`00${cur.getMonth() + 1}`).slice(-2)}`,
            limits: [cur, next],
          };
          bins.push(bin);
          cur = next;
        }

        // Generate some colors
        const colorStep = {
          h: 360 / 8,
          s: -40,
          l: -25,
        };
        const colorCount = newResult.length;
        const colors = newResult.map((v, i) => {
          if (colorCount < 8) {
            return Color.hsl(
              i * colorStep.h,
              70,
              50,
            );
          } if (colorCount < 16) {
            return Color.hsl(
              Math.floor(i / 2) * colorStep.h,
              70 + (i % 2) * colorStep.s,
              50,
            );
          }
          return Color.hsl(
            Math.floor(i / 4) * colorStep.h,
            70 + Math.floor((i % 4) / 2) * colorStep.s,
            50 + (i % 2) * colorStep.l,
          );
        });

        const datasets = newResult.map((bike, bikeIndex) => {
          const datapoints = bins.map(() => 0);
          const color = colors[bikeIndex];
          bike.data.forEach(({ count, date }) => {
            const binIndex = bins.findIndex((bin) => date.getTime() >= bin.limits[0].getTime() && date.getTime() < bin.limits[1].getTime());
            if (binIndex > -1) {
              datapoints[binIndex] += count;
            }
          });
          const newDataset = {
            ...{
              borderColor: color.hex(),
              pointBorderColor: color.hex(),
              pointBackgroundColor: color.alpha(0.85).rgb().toString(),
              backgroundColor: color.alpha(0.4).rgb().toString(),
              hoverBackgroundColor: color.alpha(0.75).rgb().toString(),
              fill: false, // its weird, dont do this
            },
            label: bike.description,
            data: datapoints,
          };
          return newDataset;
        });

        this.chartdata.labels = bins.map((b) => b.label);
        this.chartdata.datasets = datasets;

        this.renderChart(this.chartdata, this.options);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
