<template>
  <div>
    <router-view />

    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <router-link :to="{name: 'account'}">
                <i class="uil uil-arrow-left" />
              </router-link>
              <h1>{{ $t('account.about.helpAndSupport.title') }}</h1>
              <span />
            </div>
          </div>
        </div>
      </header>
    </div>

    <div class="container m-b-4">
      <div class="row">
        <div class="col">
          <div class="m-t-2">
            <ListItem icon-before>
              <i class="uil uil-user-square" />
              <router-link
                to="/account/support/rep"
              >
                {{ $t('account.about.helpAndSupport.contactRep') }}
              </router-link>
            </ListItem>

            <ListItem icon-before>
              <i class="uil uil-phone" />
              <router-link
                to="/account/support/contact"
              >
                {{ $t('account.about.helpAndSupport.contactDetails') }}
              </router-link>
            </ListItem>

            <ListItem icon-before>
              <i class="uil uil-clock" />
              <router-link
                to="/account/support/hours"
              >
                {{ $t('account.about.helpAndSupport.openingHours') }}
              </router-link>
            </ListItem>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import ListItem from '@/elements/ListItem.vue';

export default {
  components: { ListItem },
  metaInfo() {
    return {
      title: this.$t('account.about.helpAndSupport.title'),
    };
  },
};
</script>
