<template>
  <bcf-aside @close="close">
    <bcfAsideHeader :close="close">
      {{ $t("account.about.helpAndSupport.openingHours") }}
      Cycling Factory
    </bcfAsideHeader>

    <bcfAsideContent>
      <table class="table table--striped m-b-4">
        <tr>
          <td>Maandag</td>
          <td>
            08:00 - 12:00 & 13:00 -
            17:00
          </td>
        </tr>
        <tr>
          <td>Dinsdag</td>
          <td>
            08:00 - 12:00 & 13:00 -
            17:00
          </td>
        </tr>
        <tr>
          <td>Woensdag</td>
          <td>
            08:00 - 12:00 & 13:00 -
            17:00
          </td>
        </tr>
        <tr>
          <td>Donderdag</td>
          <td>
            08:00 - 12:00 & 13:00 -
            17:00
          </td>
        </tr>
        <tr>
          <td>Vrijdag</td>
          <td>
            08:00 - 12:00 & 13:00 -
            17:00
          </td>
        </tr>
        <tr>
          <td class="color--muted">
            Zaterdag & zondag
          </td>
          <td class="color--muted">
            Gesloten
          </td>
        </tr>
      </table>

      <sectionTitle>
        {{
          $t("account.about.helpAndSupport.contactUs")
        }}
      </sectionTitle>
      <router-link
        class="button button--primary w-100"
        to="/account/support/contact"
      >
        {{ $t("account.about.helpAndSupport.contactDetails") }}
      </router-link>
    </bcfAsideContent>
  </bcf-aside>
</template>

<style scoped>
</style>

<script>
import bcfAside from '@/components/bcfAside.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';

export default {
  components: {
    bcfAside,
    SectionTitle,
    bcfAsideContent,
    bcfAsideHeader,
  },
  methods: {
    close() {
      this.$router.push({ name: 'helpAndSupport' });
    },
  },
};
</script>
