<template>
  <div>
    <router-view />

    <bcf-aside
      v-if="isMobile"
      v-show="mobileFiltersOpen"
    >
      <bcfAsideHeader :close="closeMobileFilters">
        {{ $t('items.filters') }}
      </bcfAsideHeader>
      <bcfAsideContent>
        <div class="products__filter">
          <my-stock-filters v-model="filters" />
        </div>
      </bcfAsideContent>
      <bcfAsideFooter>
        <span />
        <button
          class="button button--primary"
          @click="closeMobileFilters"
        >
          {{ $t('general.apply') }}
        </button>
      </bcfAsideFooter>
    </bcf-aside>

    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <router-link :to="{name: 'account'}">
                <i class="uil uil-arrow-left" />
              </router-link>
              <h1>{{ $t('account.account.myStock.title') }}</h1>
              <span
                v-if="isMobile"
                class="main__title__action--right"
                @click="mobileFiltersOpen = true"
              >
                <i class="uil uil-filter" />
                <Badge v-if="filterCount > 0">{{ filterCount }}</Badge>
              </span>
            </div>
          </div>
        </div>
      </header>
    </div>

    <div class="container">
      <!-- Loading -->
      <div
        v-if="!stockResult && myStockInProgress"
        class="row"
      >
        <div class="col">
          <loader />
        </div>
      </div>

      <!-- Table -->
      <div
        v-if="stockResult"
        class="row"
      >
        <div class="col-sm-12 col-md-3">
          <my-stock-filters
            v-if="!isMobile"
            v-model="filters"
          />
        </div>
        <div class="col-sm-12 col-md-9">
          <div
            v-if="itemsToConfim.length"
            class="m-b-2"
          >
            <div class="confirm-message">
              {{ $t('account.account.myStock.confirmNotModified.message', { amount: itemsToConfim.length }) }}
              <button
                class="button button--confirm"
                @click="onConfirmItems"
              >
                <loader v-if="actionLoading" />
                {{ $t('account.account.myStock.confirmNotModified.action') }}
              </button>
            </div>
          </div>

          <div class="stock__controls m-md-t-2">
            <search
              v-model="searchVal"
              style="flex: 1 1 auto"
            />
            <router-link
              :to="{name: 'myStockNew', params: filters.addressid? {addressid: filters.addressid} : {}}"
            >
              <a
                class="button button--secondary m-b-2 stock__add"
              >
                <i class="uil uil-plus" />
              </a>
            </router-link>
            <a
              target="_blank"
              :href="`https://www.facebook.com/sharer/sharer.php?u=${baseB2cUrl}/inventory?p=key=inventory_value=dealerstock_cus=${selectedCustomer.externalid}`"
              class="button button--outline m-l-2"
            >
              <img :src="require('@/assets/img/icons/Facebook_logo.svg')">
              {{ $t('account.about.socialFeed.share') }}
            </a>
          </div>

          <div class="stock__overview p-t-2">
            <div v-if="myStockError">
              <Message
                class="m-t-2"
                type="error"
                :icon="true"
                :content="$t(`api.${myStockError}`)"
                :show-close="true"
                @closeMessage="closeMessage"
              />
            </div>

            <EmptyState
              v-if="stockResult.length === 0"
              icon="truck-loading"
            >
              {{ noResultText }}
              <loader v-if="myStockInProgress" />
            </EmptyState>

            <table
              v-else-if="stockResult.length > 0"
              class="table table--striped m-b-4 stock-table"
            >
              <thead>
                <tr>
                  <!--<th class="stock-table__select">
                    <checkbox />
                  </th>-->
                  <th
                    :class="headClasses('itemCode')"
                    @click="toggleSortOn('itemCode')"
                  >
                    {{ $t(('account.account.myStock.itemCode')) }}
                  </th>
                  <th
                    :class="headClasses('item')"
                    @click="toggleSortOn('item')"
                  >
                    {{ $t(('account.account.myStock.item')) }}
                  </th>
                  <th
                    :class="headClasses('status')"
                    @click="toggleSortOn('status')"
                  >
                    {{ $t(('account.account.myStock.status')) }}
                  </th>
                  <th
                    :class="headClasses('orderdate', 'date')"
                    @click="toggleSortOn('orderdate')"
                  >
                    {{ $t(('account.account.myStock.purchaseDate')) }}
                  </th>
                  <th
                    :class="headClasses('lastmodified', 'date')"
                    @click="toggleSortOn('lastmodified')"
                  >
                    {{ $t(('account.account.myStock.lastModified')) }}
                  </th>
                  <!--<th
                    :class="headClasses('visibleb2c', 'visible')"
                    @click="toggleSortOn('visibleb2c')"
                  >
                    {{ $t(('account.account.myStock.visibleB2C')) }}
                  </th>-->
                </tr>
              </thead>

              <tbody>
                <loader v-if="myStockInProgress" />

                <template
                  v-for="stock in stockToDisplay"
                >
                  <tr
                    :key="`1_${stock.serial}`"
                    :class="{
                      'stock-item__row': true,
                      'stock-item__row--loading': stock.loading,
                      'stock-item__row--marked': statusNeedsConfirmation(stock)
                    }"
                  >
                    <!--<td
                      class="stock-table__select"
                      :rowspan="locations.length>1? 2 : 1"
                    >
                      <checkbox />
                    </td>-->
                    <td
                      class="stock-table__code"
                      :rowspan="locations.length>1? 2 : 1"
                    >
                      <!-- Code/Serial -->
                      <div class="stock-item__codes">
                        <div class="stock-item__codes__externalid">
                          {{ stock.externalid }}
                        </div>

                        <div
                          style="margin-bottom: 3px; display: inline-block; max-width: 150px; text-overflow: ellipsis;
                          white-space: nowrap; overflow: hidden; line-height:1.5rem; font-size:1.4rem; font-family: monospace;
                          letter-spacing: 0.07em; background: black; padding: 0.5rem 0.5rem 0.5rem 1.2rem;"
                          class="tag stock-item__codes__serial"
                        >
                          <i
                            class="uil uil-pricetag-alt"
                            style="margin: -0.5em;"
                          /> {{ stock.serial }}
                        </div>
                      </div>
                    </td>
                    <td
                      class="stock-table__detail"
                      :rowspan="locations.length>1? 2 : 1"
                    >
                      <!-- Item thumbnail/name -->
                      <div class="stock-item__detail">
                        <div class="stock-item__detail__image">
                          <img
                            v-if="stock.image || !canReplaceImage(stock,imagesFailed[getImageFor(stock).thumb]) || !imagesFailed[getImageFor(stock).thumb]"
                            :src="getImageFor(stock).thumb"
                            :alt="stock.externalid"
                            @error="imgFailed"
                            @click="openLightboxImage(stock, $event)"
                          >
                          <image-input
                            v-else
                            v-model="stock.image"
                            class="photo-input"
                            :fit="'cover'"
                            :default="getImageFor(stock).thumb"
                            @input="onImageInput(stock, $event)"
                            @error="imgFailed"
                          />
                        </div>
                        <div class="stock-item__detail__content">
                          <div class="stock-item__detail__content__detail">
                            {{ stock.description }}
                          </div>
                          <router-link
                            class="stock-item__detail__content__salesorder stock-item__detail__content__link"
                            :to="{name: 'stockOrderView', params: {id:stock.externalorderid}}"
                          >
                            <i class="uil uil-link" />
                            {{ $t('account.account.myStock.order') }}
                            {{ stock.externalorderid }}
                          </router-link>

                          <a
                            v-if="stock.reference && relationOfReference(stock.reference)"
                            class="stock-item__detail__content__relation stock-item__detail__content__link"
                            :href="$router.resolve({name: 'myCustomersDetail', params: {id:stock.reference.relationId}}).href"
                            @click.prevent.stop="editAssignment(stock, $event)"
                          >
                            <div class="stock-item__detail__content__references__icon">
                              <img
                                src="@/assets/img/references.png"
                                style="width:17px;height:17px;"
                              >
                            </div>
                            <!--<i class="uil uil-link" />-->
                            {{ $t('account.account.myStock.relation') }}
                            {{ [stock.reference.firstName, stock.reference.lastName].filter(x=>x).join(' ') || stock.reference.email }}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td
                      class="stock-table__status"
                      :class="{
                        'stock-stock-table__status': true,
                        'stock-table__half-row': locations.length>1,
                        'stock-table__half-row--top': locations.length>1
                      }"
                    >
                      <!-- Status -->
                      <status-select
                        :value="stock.status"
                        @input="onStockChange(stock, 'status', $event)"
                      />

                      <!-- not ideal -->
                      <loader
                        v-if="stock.loading"
                        class="stock-item__loader"
                      />
                    </td>
                    <td
                      class="stock-table__date"
                      :class="{
                        'stock-table__date': true,
                        'stock-table__half-row': locations.length>1,
                        'stock-table__half-row--top': locations.length>1
                      }"
                    >
                      {{ formatDate(stock.orderdate) }} <!-- Purchase date -->
                    </td>
                    <td
                      class="stock-table__date"
                      :rowspan="locations.length>1? 2 : 1"
                    >
                      {{ formatDatetime(stock.lastmodified) }} <!-- Last modified  -->
                    </td>
                    <!--<td
                      class="stock-table__visible"
                      :rowspan="locations.length>1? 2 : 1"
                    >
                      <-- Visible in B2C  ->
                      <toggle
                        :value="stock.visibleb2c"
                        class="stock-table__toggle"
                        @input="onStockChange(stock, 'visibleb2c', $event)"
                      >
                        <i :class="{'uil': true, 'uil-eye': stock.visibleb2c, 'uil-eye-slash': !stock.visibleb2c}" />
                      </toggle>
                    </td>-->
                  </tr>
                  <tr
                    v-if="locations.length>1"
                    :key="`2_${stock.serial}`"
                    :class="{
                      'stock-item__row': true,
                      'stock-item__row--marked': statusNeedsConfirmation(stock)
                    }"
                  >
                    <td
                      class="stock-table__status stock-table__half-row stock-table__half-row--bottom"
                      colspan="2"
                    >
                      <!-- Location -->
                      <location-select
                        v-if="locations.length>1"
                        class="location-select"
                        :value="stock.address.externalid"
                        @input="onStockChange(stock, 'address', $event)"
                      />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <pagination
              class="m-t-4 m-b-4"
              :page="page"
              :pages="pages"
              :items-per-page="itemsPerPage"
              @pageChanged="changePage"
              @itemsPerPageChanged="itemsPerPageChanged"
            />
          </div>
        </div>
      </div>
    </div>

    <ItemImageViewer
      ref="lightbox"
      :item="lightboxStock"
      :editable="true"
      @imageselected="onImageSelected($event)"
    />
  </div>
</template>
<script>
import search from '@/components/Search.vue';
import loader from '@/elements/loader.vue';
import EmptyState from '@/elements/EmptyState.vue';
import Message from '@/components/Message.vue';
import pagination from '@/components/Pagination.vue';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import ImageInput from '@/elements/ImageInput.vue';
// import checkbox from '@/elements/checkbox.vue';
import Badge from '@/elements/Badge.vue';
import { formatDate } from '@/utils/dateUtils';

import {
  ConfirmOutdatedStock, GetStock, PatchStock, UploadCustomerStockImage,
} from '@/api/api';
import { mapState } from 'vuex';
import ItemImageViewer from '@/components/item/itemImageViewer.vue';
import { getImageFor } from '@/utils/itemUtils';
import dialogs from '@/utils/dialogs';
import { relationOfReference } from '@/views/account/account/mycustomers/utils';
import StatusSelect from './statusSelect.vue';
import LocationSelect from './locationSelect.vue';
import AssignmentDialog from '../mycustomers/assignmentDialog.vue';
import UnassignDialog from '../mycustomers/unassignDialog.vue';

import { canReplaceImage } from './utils';

/**
 * Stock items and assignable items have roughly the same data but a different format. Not every assignable can be a stock item,
 * but a stock item is easely an assignable.
 * @param item
 */
function stockItemToAssignable(item, customer) {
  const idData = {
    id: item.serial,
    serial: item.serial,
    index: item.reference?.index,
    reference: item.reference,
    stock: {
      id: item.id,
      status: item.status,
      address: item.address,
    },
  };
  return {
    customer: {
      externalid: customer?.externalid,
      name: customer?.name,
      id: customer?.id ?? item.customerid,
    },
    item: {
      attributes: item.attributes,
      description: item.description,
      configurationid: item.configurationid,
      externalid: item.externalid,
      image: item.image,
      // Missing a few: such as frame_family, frame_platform, model, urlPath,..
    },
    order: {
      date: item.orderdate,
      externalid: item.externalorderid,
      orderid: null, // Stock item is never a pending order
      orderlineid: null, // Not known via stock, which is a potential issue
      // We don't reliably have the other status fields
    },
    ...idData,
  };
}

const myStockFilters = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/mystock/filters.vue');

const sortOptions = [
  {
    id: 'status',
    value: (a, b) => {
      const ranking = {
        available: 1,
        reserved: 2,
        sold: 3,
        hidden: 4,
      };
      const sA = ranking[a.status] || 99;
      const sB = ranking[b.status] || 99;
      return sA - sB;
    },
  },
  {
    id: 'created',
    value: (a, b) => {
      const sA = new Date(a.created);
      const sB = new Date(b.created);
      return sB.getTime() - sA.getTime();
    },
  },
  {
    id: 'shipmentdate',
    value: (a, b) => {
      const sA = new Date(a.shippeddate);
      const sB = new Date(b.shippeddate);
      return sB.getTime() - sA.getTime();
    },
  },
  {
    id: 'orderdate',
    value: (a, b) => {
      const sA = new Date(a.orderdate);
      const sB = new Date(b.orderdate);
      return sB.getTime() - sA.getTime();
    },
  },
  {
    id: 'lastmodified',
    value: (a, b) => {
      const sA = new Date(a.lastmodified);
      const sB = new Date(b.lastmodified);
      return sB.getTime() - sA.getTime();
    },
  },
  {
    id: 'itemCode',
    value: (a, b) => ((a.externalid < b.externalid) ? -1 : (a.externalid > b.externalid) ? 1 : 0),
  },
  {
    id: 'item',
    value: (a, b) => ((a.description < b.description) ? -1 : (a.description > b.description) ? 1 : 0),
  },
  {
    id: 'visibleb2c',
    value: (a, b) => ((a.visibleb2c && !b.visibleb2c) ? -1 : (b.visibleb2c && !a.visibleb2c) ? 1 : 0),
  },
];
export default {
  name: 'MyStock',
  metaInfo() {
    return {
      title: this.$t('account.account.myStock.title'),
    };
  },
  components: {
    search,
    EmptyState,
    loader,
    Message,
    pagination,
    myStockFilters,
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    bcfAsideFooter,
    Badge,
    // checkbox,
    StatusSelect,
    LocationSelect,
    ItemImageViewer,
    ImageInput,
  },
  data() {
    return {
      isMobile: window.innerWidth < 768,
      allStock: [],
      stockResult: [],
      myStockInProgress: true,
      myStockError: '',
      itemsPerPage: 20,
      page: 1,
      filters: {
        sort: this.$route.query.sort ? this.$route.query.sort : 'status',
        filters: [],
      },
      mobileFiltersOpen: false,
      actionLoading: false,
      imagesFailed: { },
      lightboxStock: null,
      baseB2cUrl: process.env.VUE_APP_B2C_URL,
    };
  },
  computed: {
    getCurrentLocal() {
      return this.$store.state.language.currentApi === 'default' ? 'en_INT' : this.$store.state.language.currentApi;
    },
    ...mapState({
      selectedCustomer: (state) => state.customer.selectedCustomer,
      customer: (state) => state.customer,
      locations: (state) => state.customer.selectedCustomer.addresses,
    }),
    customerid() {
      return this.customer.selectedCustomerId;
    },
    stockToDisplay() {
      return this.stockResult.slice(
        this.page * this.itemsPerPage - this.itemsPerPage,
        this.page * this.itemsPerPage,
      );
    },
    searchVal: {
      get() {
        return this.$route.query.search;
      },
      set(newVal) {
        this.$router.replace({
          name: this.$router.currentRoute.name,
          query: {
            ...this.$route.query,
            search: newVal,
          },
        });
        this.page = 1;
        this.search();
      },
    },
    pages() {
      const pages = [];
      for (
        let i = 0;
        i < this.stockResult.length / this.itemsPerPage;
        i += 1
      ) {
        pages.push(i + 1);
      }
      return pages;
    },
    noResultText() {
      if (this.myStockInProgress) {
        return this.$t('account.account.myStock.loading');
      }
      if (this.searchVal || this.filterCount) {
        return this.$t('account.account.myStock.noStockMatchingFilters');
      }
      return this.$t('account.account.myStock.noStock');
    },
    itemsToConfim() {
      return (this.allStock || []).filter((x) => this.statusNeedsConfirmation(x));
    },
    filterCount() {
      return Array.from(Object.entries(this.filters))
        .map(([k, v]) => {
          if (k === 'sort') return 0;
          if (v instanceof Array) {
            return v.length;
          }
          return (v) ? 1 : 0;
        })
        .reduce((a, b) => a + b, 0);
    },
  },
  watch: {
    filters: {
      handler() {
        this.search();
      },
      deep: true,
    },
    $route: {
      handler() {
        if (this.$route.query.refresh) {
          this.fetchData()
            .then(() => {
              this.$router.replace({
                name: 'myStock',
                query: {
                  search: this.$route.query.search,
                  refresh: undefined,
                },
              });
            });
        }
      },
    },
  },
  async mounted() {
    this.fetchData();
    await this.$store.dispatch('myInvoices/get');
  },
  created() {
    window.addEventListener('resize', this.updateWindowSize);
    this.updateWindowSize();
  },
  destroyed() {
    window.removeEventListener('resize', this.updateWindowSize);
  },
  methods: {
    async fetchData() {
      try {
        this.allStock = await GetStock(this.customerid);

        this.search();
        // might want to switch this to use the store
        // await this.$store.dispatch('myStock/get');

        this.myStockInProgress = false;
      } catch (err) {
        this.myStockInProgress = false;
        this.myStockError = err.name;
        throw Object.assign(err, { status: 500 });
      }
    },
    closeMobileFilters() {
      this.mobileFiltersOpen = false;
    },
    updateWindowSize() {
      this.isMobile = window.innerWidth < 768;
    },
    changePage(page) {
      this.page = parseInt(page, 10);
      this.stockResult.slice(
        this.page * this.itemsPerPage - this.itemsPerPage,
        this.page * this.itemsPerPage,
      );
    },
    itemsPerPageChanged(itemsPerPage) {
      this.itemsPerPage = parseInt(itemsPerPage, 10);
      this.changePage(1);
    },
    closeMessage() {
      // this.$store.dispatch('myorders/clear');
    },
    formatDate(/** Date */ d) {
      return new Date(d).toLocaleString(undefined, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      });
    },
    formatDatetime(/** Date */ d) {
      if (d) {
        const date = new Date(d);
        return [
          formatDate(d),
          [date.getHours(), date.getMinutes()].map((c) => ((`00${c}`).slice(-2)))
            .join(':'),
        ].join(' ');
      }
      return null;
    },
    search() {
      this.page = 1;
      const filters = {};
      this.filters.filters.forEach((f) => {
        if (!filters[f.type]) {
          filters[f.type] = [];
        }
        filters[f.type].push(f.value);
      });

      let filteredStock = this.allStock.filter((s) => {
        if (filters.status) {
          if (!filters.status.includes(s.status)) {
            return false;
          }
        }
        if (this.filters.externalid) {
          if (!s.externalid.toUpperCase()
            .startsWith(this.filters.externalid.toUpperCase())) {
            return false;
          }
        }
        if (this.filters.addressid) {
          const addressid = s.address && s.address.externalid;
          if (!(addressid === this.filters.addressid)) {
            return false;
          }
        }
        if (s.status === 'hidden' && !this.filters.showHidden) {
          return false;
        }
        return true;
      });

      const sortCriteria = sortOptions.map((x) => {
        let add = {};
        if (this.filters.sort && this.filters.sort.id === x.id) {
          add = { ...this.filters.sort, order: 0 };
        } else {
          add = { order: Number.MAX_SAFE_INTEGER };
        }
        return {
          ...x,
          ...add,
        };
      })
        .sort((x, y) => x.order - y.order);
      filteredStock = filteredStock.sort((a, b) => { // Not perfect, but we will sort on the ETD of the earliest available non-shipped item in case of ETD
        for (const sort of sortCriteria) {
          const result = sort.value(a, b);
          if ((result > 0 || result < 0) && Number.isFinite(result)) {
            return sort.invert ? -result : result;
          }
        }
        return 0;
      });

      if (this.$route.query.search) {
        this.$search(this.$route.query.search, filteredStock, {
          keys: ['serial', 'externalid', 'externalorderid', 'description'],
          defaultAll: true,
          caseSensitive: false,
          shouldSort: false,
          tokenize: false,
          matchAllTokens: true,
          findAllMatches: true,
          threshold: 0.2,
          location: 2,
          distance: 50,
          maxPatternLength: 32,
          minMatchCharLength: 1,
        })
          .then((results) => {
            this.stockResult = results;
          });
      } else {
        this.stockResult = filteredStock;
      }
    },
    imgFailed(event) {
      if (!event.target.failed) {
        event.target.failed = true;
        this.imagesFailed = {
          ...this.imagesFailed,
          [event.target.src]: true,
        };
        // eslint-disable-next-line global-require
        event.target.src = require('@/assets/img/product_placeholder.jpg');
      }
    },
    async editAssignment(item, mode) {
      const relation = relationOfReference(item.reference);
      const value = {
        relation,
        items: [stockItemToAssignable(item, this.customer)],
        stockStatus: item.status,
        stockAddress: item.address?.externalid,
        purchaseDate: item.bikeregistration?.purchaseDate,
        sendEmail: !relation,
        note: item.reference?.note,
      };

      const result = await dialogs.show({
        component: mode === 'unassign' ? UnassignDialog : AssignmentDialog,
        props: mode === 'unassign' ? {
          value,
          relation,
        } : {
          mode: relation ? 'edit' : 'assign',
          allowEditItems: false, // Don't show the input for adding multiple (nor deleting the one we've set)
          allowEmptySave: true, // Allow the assignment to be empty / no changes compared initial value, which in this case will result in just updating stock-status
          value,
          autofocusSearch: !relation, // will focus customer
        },
      });
      if (result) {
        await this.fetchData();// Refresh results
      }
      return result;
    },
    async onStockChange(item, field, value) {
      const changes = { [field]: value };

      if (field === 'address') {
        // Shady, address on id vs full jsonb
        changes[field] = this.locations.find((l) => l.externalid === value);
      }
      changes.lastmodified = new Date();

      item.loading = true;
      this.$forceUpdate();

      let result;
      if (field === 'status'
          && (
            (['sold', 'reserved'].includes(value) && ['available', 'hidden'].includes(item.status))
            || (value === 'sold' && item.status === 'reserved')
          )
      ) {
        // Stock status went from an available state to an assigned state, ask user who to assign to
        try {
          result = await this.editAssignment({
            ...item,
            ...changes,
          });
        } catch (err) {
          console.error(err);
        }
      } else if (
        field === 'status' && value === 'available'
          && item.status !== 'available' && relationOfReference(item.reference)
      ) {
        // Unassign
        try {
          result = await this.editAssignment({
            ...item,
            ...changes,
          }, 'unassign');
        } catch (err) {
          console.error(err);
        }
      } else {
        // Stock change without any side-effects
        try {
          result = await PatchStock(
            this.customerid,
            item.serial,
            changes,
          );
          Object.entries(changes)
            .forEach(([key, newvalue]) => {
              item[key] = newvalue;
            });
        } catch (err) {
          console.error(err);
        }
      }

      item.outdated = false;
      item.loading = false;
      this.search();// Update the list
      this.$forceUpdate();
    },
    statusNeedsConfirmation(item) {
      // Logic was moved to backend and set as the property 'outdated'
      // (this might no longer need to be a function)
      return item.outdated;
    },
    async onConfirmItems() {
      try {
        this.actionLoading = true;
        await ConfirmOutdatedStock(this.customerid, this.itemsToConfim.map((x) => x.serial));
        await this.fetchData(); // refresh the data
      } catch (err) {
        console.error(err);
      }
      this.actionLoading = false;
    },
    toggleSortOn(field) {
      const { filters } = this;
      let newSort = filters.sort;
      if (!filters.sort || filters.sort?.id !== field) {
        newSort = {
          id: field,
          invert: false,
        }; // Basic sort on 1st click
      } else if (!filters.sort.invert) {
        newSort = {
          id: field,
          invert: true,
        }; // Invert the sort on 2nd click
      } else {
        newSort = null; // unset the sort after 3rd click
      }
      this.filters = {
        ...this.filters,
        sort: newSort,
      };
      this.search(); // Update the list
    },
    headClasses(field, type) {
      const { sort: sortOn } = this.filters;
      const classes = {
        [`stock-table__${field}`]: true,
        'stock-table__sorted': sortOn && sortOn.id === field,
        'stock-table__sorted--invert': sortOn && sortOn.id === field && sortOn.invert,
      };
      if (type) {
        classes[`stock-table__${type}`] = true;
      }
      return classes;
    },
    openLightboxImage(stock, event) {
      if (event) {
        if (event.preventDefault) event.preventDefault();
        if (event.stopPropagation) event.stopPropagation();
      }
      this.lightboxStock = stock;
      this.$refs.lightbox.show();
    },
    async onImageSelected({ item, files }) {
      let newStock = item;
      if (files && files.length) {
        const file = files[0];
        const uploaded = await UploadCustomerStockImage(this.customerid, file, item.serial || item.id);
        newStock = { ...item, image: uploaded.url };
        this.allStock = this.allStock.map((s) => (s === item ? newStock : s));
      } else {
        newStock = { ...item, image: null };
        this.allStock = this.allStock.map((s) => (s === item ? newStock : s));
      }
      this.search();
      this.openLightboxImage(newStock);// Refresh lightbox
      this.$forceUpdate();
    },
    async onImageInput(item, fileData) {
      // Emitted from image-input component (when no image could be resolved at all)
      const uploaded = await UploadCustomerStockImage(this.customerid, fileData.file, item.serial || item.id);
      this.allStock = this.allStock.map((s) => (s === item ? { ...s, image: uploaded.url } : s));
      this.search();
      this.$forceUpdate();
    },
    getImageFor,
    canReplaceImage,
    relationOfReference,
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/_colors.scss";
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";

$warn_bg: #ffede1;
$warn_border: #f8bf98;

[v-cloak] > * {
  display: none;
}

[v-cloak]::before {
  content: "loading…";
}

.stock {
  &__controls {
    display: flex;
    flex-direction: row;
    justify-items: stretch;
    align-items: stretch;
  }

  &__add {
    padding: 1.5rem;
  }

  &__overview {
    background-color: #fff;
    padding: 1rem 1rem 0 1rem;
    border-radius: 3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.confirm-message {
  background-color: $warn_bg;
  border: 1px solid $warn_border;
  font-size: 1.75rem;
  padding: 2rem;
  color: $red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .button--confirm {
    background-color: $blue;
    color: white;
    font-weight: normal;
  }
}

.stock-table {

  thead > tr > th {
    font-size: 1.25rem;
    text-transform: none;
    font-weight: 600;
    vertical-align: top;

    user-select: none;
    cursor: pointer;
    position: relative;

    &:after {
      //position: absolute;
      //right: 0;
      //top: 0;
      //bottom: 0;
      //padding: 1.5rem .5rem 1.5rem .25rem;
      padding: 0 .5rem 0 .5rem;
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.25rem;
      font-weight: bold;
      color: black;
    }

    &.stock-table__sorted:after {
      content: "↓";
    }

    &.stock-table__sorted--invert:after {
      content: "↑";
    }
  }

  &__half-row {
    &--top {
      padding-bottom: .25rem;
      border-bottom: none;
    }

    &--bottom {
      padding-top: .25rem;
      border-top: none;
    }
  }

  &__select {
    width: 4rem;
  }

  &__code {
    width: 10rem;
  }

  &__detail {
    text-overflow: ellipsis;
  }

  &__status {
    width: 14.5rem;
    text-align: center;
    position: relative;
  }

  &__date {
    width: 10.5rem;
    text-align: center;
  }

  &__visible {
    width: 8rem;
  }

  &__toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    font-size: 2.5rem;
    line-height: 1em;
    cursor: pointer;
  }

  tbody {
    .stock-table {
      &__date {
        font-size: 90%;
      }
    }
  }
}

.location-select{
  width: 100%;
  padding: 0;
}

.stock-item {
  &__codes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &__externalid {

    }

    &__serial {

    }
  }

  &__detail {
    display: flex;
    align-items: center;

    &__content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      padding: 0 2rem;

      &__description {
        text-overflow: ellipsis;
      }

      &__link {
        color: blue;
        text-decoration: none;
      }
      &__salesorder {
      }
      &__relation {
      }
      &__references__icon{
        display: inline-block;
        vertical-align: text-top;
      }
    }

    &__image {
      background: #FFF;
      position: relative;
      min-width: 10rem;
      max-width: 10rem;
      min-height: 10rem;
      max-height: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $light-grey;
      cursor: pointer;

      img {
        max-width: 10rem;
        max-height: 10rem;
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  &__row {
    position: relative;
    transition: opacity 100ms;

    &--loading {
      opacity: .75;
    }

    &--marked {
      td, &:hover td {
        background-color: $warn_bg;

        &:not(.stock-table__half-row), &.stock-table__half-row--top {
          border-top: 1px solid $warn_border;
        }

        &:not(.stock-table__half-row), &.stock-table__half-row--bottom {
          border-bottom: 1px solid $warn_border;
        }

        &:not(.stock-table__half-row) {
          &:first-child {
            border-left: 1px solid $warn_border;
          }

          &:last-child {
            border-right: 1px solid $warn_border;
          }
        }
      }
    }
  }

  &__loader {
    position: absolute;
    right: 100%; // offset to the left
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.image-select.photo-input{
  width: 100%;
  height: 100%;
}

</style>
