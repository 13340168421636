<template>
  <div>
    <bcf-aside @close="close">
      <bcf-aside-header :close="close">
        New voucher
      </bcf-aside-header>
      <bcf-aside-content>
        <Message
          v-if="nameAlreadyExist"
          class="m-1"
          type="error"
          :icon="false"
          :show-close="false"
        >
          We already have such voucher name. Please try another one?
        </Message>
        <section-title>
          Voucher name
        </section-title>
        <table width="100%">
          <tr width="100%">
            <td>
              <input
                ref="code"
                v-model="eVoucher.code"
                class="form-control"
                type="text"
                width="80"
                @input="someHandler"
              >
            </td>
            <td width="20%">
              <button
                class="button button--primary"
                @click="generate()"
              >
                Generate code
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <small>Description:</small>
              <textarea
                v-model="eVoucher.description"
                rows="4"
                cols="50"
                maxlength="200"
                placeholder="Enter description, campagne or social media name here..."
              />
            </td>
            <td />
          </tr>
        </table>
        <section-title>
          Date valid
        </section-title>
        <div class="list__item__content">
          <small>{{ $t('account.settings.vouchers.startDate') }}: </small>
          <datepicker
            v-model="eVoucher.start"
            placeholder=""
            maximum-view="month"
          />
        </div>
        <br>
        <div class="list__item__content">
          <small>
            {{ `${$t('account.settings.vouchers.expiryDate')}:` }}
          </small>
          <datepicker
            v-model="eVoucher.expire"
            placeholder=""
            maximum-view="month"
          />
        </div>
        <div>
          <section-title>
            Website
          </section-title>
          <span>
            <multiselect
              v-model="websitevalues"
              :options="websiteoptions"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Pick some"
              :preselect-first="false"
            />
          </span>
          <section-title>
            Brands
          </section-title>
          <span>
            <multiselect
              v-model="brandsvalues"
              :options="brandsoptions"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Pick some"
              label="name"
              :track-by="trackBy"
              :preselect-first="false"
              :object="true"
            />
          </span>
          <section-title>
            Range
          </section-title>
          <span>
            <multiselect
              v-model="rangevalues"
              :options="rangeoptions"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Pick some"
              label="name"
              :track-by="trackBy"
              :preselect-first="false"
              :object="true"
            />
          </span>
          <section-title>
            Family
          </section-title>
          <span>
            <multiselect
              v-model="familyvalues"
              :options="familyoptions"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Pick some"
              label="name"
              :track-by="trackBy"
              :preselect-first="false"
            />
          </span>
          <section-title>
            Category
          </section-title>
          <span>
            <multiselect
              v-model="categoryvalues"
              :options="categoryoptions"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Pick some"
              label="name"
              track-by="name"
              :preselect-first="false"
              :object="true"
            />
          </span>
          <section-title>
            Items(Regex)
          </section-title>
          <template v-for="(reg, index) in eVoucher.discount[0].items">
            <Badge
              :key="index"
              type="new"
              style="padding:7px;"
            >
              {{ reg }}
              <i
                class="uil uil-multiply"
                style="cursor: pointer;"
                @click="del('items',index)"
              />
            </Badge>
          </template>
          <table class="table">
            <tr>
              <td>
                <input
                  v-model="newRegexItem"
                  class="form-control"
                  type="text"
                ></input>
              </td>
              <td>
                <button
                  class="button button--primary"
                  @click.prevent="Add()"
                >
                  Add
                </button>
              </td>
            </tr>
          </table>
          <section-title>
            Discount details
          </section-title>
          <table class="table table--striped m-b-4">
            <tr>
              <td>Type:</td>
              <td>
                <multiselect
                  v-model="eVoucher.discount[0].config_1.type"
                  :options="options"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Select one"
                />
              </td>
            </tr>
            <tr>
              <td>Amount:</td>
              <td>
                <input
                  ref="default"
                  v-model="eVoucher.discount[0].config_1.amount"
                  class="form-control"
                  type="text"
                >
              </td>
            </tr>
            <tr>
              <td>Currency:</td>
              <td>
                <multiselect
                  v-model="eVoucher.discount[0].config_1.currency"
                  :options="currencyoptions"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Select one"
                />
              </td>
            </tr>
            <tr>
              <td>Max attempts:</td>
              <td>
                <input
                  v-model="eVoucher.discount[0].config_1.maxused"
                  class="form-control"
                  type="text"
                >
              </td>
            </tr>
          </table>
          <Message
            v-if="isSuccess"
            class="m-t-2"
            type="success"
            :icon="false"
            :show-close="false"
          >
            This voucher successfully saved.
          </Message>
          <Message
            v-if="isError"
            type="error"
            :icon="false"
            :show-close="false"
          >
            Something went wrong saving this voucher...
          </Message>
          <section-title>
            Options
          </section-title>
          <checkbox
            v-model="eVoucher.default"
            :checked-value="{ type: 'status', value: 'done' }"
          >
            <span><small><b>set as default</b></small></span>
          </checkbox>&nbsp;&nbsp;&nbsp;
          <checkbox
            v-model="eVoucher.discount[0].options.cumulative"
            :checked-value="{ type: 'status', value: 'done' }"
          >
            <span><small><b>cumulative on campaign</b></small></span>
          </checkbox>&nbsp;&nbsp;&nbsp;
          <checkbox
            v-model="eVoucher.discount[0].options.inventoryOnly"
            :checked-value="{ type: 'status', value: 'done' }"
          >
            <span><small><b>Inventory items only</b></small></span>
          </checkbox>
          <br>
          <checkbox
            v-model="eVoucher.discount[0].config_1.freeShipping"
            :checked-value="{ type: 'status', value: 'done' }"
          >
            <span><small><b>Free shipping</b></small></span>
          </checkbox>
        </div>
      </bcf-aside-content>
      <bcf-aside-footer>
        <button
          class="button button--outline"
          @click="close()"
        >
          Close
        </button>
        <button
          class="button button--primary"
          :disabled="isSuccess"
          @click.prevent="save()"
        >
          Opslaan
        </button>
      </bcf-aside-footer>
    </bcf-aside>
  </div>
</template>
<script>
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect';
import checkbox from '@/elements/checkbox.vue';
import Badge from '@/elements/Badge.vue';
import {
  CreateVoucher, GetRanges, GetFamilies, GetCategories, GetVoucher, GetBrands, Generate,
} from '@/api/api';
import Message from '@/components/Message.vue';

export default {
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideFooter,
    bcfAsideContent,
    Datepicker,
    SectionTitle,
    Multiselect,
    checkbox,
    Badge,
    Message,
  },
  data() {
    return {
      trackBy: 'key',
      isSuccess: false,
      isError: false,
      nameAlreadyExist: false,
      newRegexItem: '',
      websitevalues: [],
      websiteoptions: ['ridley', 'Eddy Merckx'],
      brandsvalues: null,
      brandsoptions: [],
      rangevalues: null,
      rangeoptions: [],
      familyvalues: null,
      familyoptions: [],
      categoryvalues: null,
      categoryoptions: [],
      eVoucher: {
        code: null,
        description: null,
        start: null,
        expire: null,
        discount: [
          {
            items: null,
            config_1:
              {
                type: null,
                amount: null,
                currency: null,
                maxused: 1,
                freeShipping: null,
              },
            options:
              {
                cumulative: null,
                inventoryOnly: null,
              },
          },
        ],
        default: null,
      },
      options: ['amount', 'percentage', 'discount'],
      currencyoptions: ['EUR', 'GBP', 'USD', 'percentage'],
    };
  },
  async mounted() {
    // this.isSuccess = true;
    const [ranges, families, categories, brands] = await Promise.all([
      GetRanges(),
      GetFamilies(),
      GetCategories(),
      GetBrands(),
    ]);
    this.rangeoptions = ranges;
    this.familyoptions = families;
    this.categoryoptions = categories;
    this.brandsoptions = brands;
  },
  methods: {
    async generate() {
      this.eVoucher.code = await Generate();
    },
    someHandler() {
      const $ref = this.$refs.code;
      this.nameAlreadyExist = false;
      $ref.style.background = 'transparent';
      $ref.focus();
    },
    Add() {
      console.log(this.eVoucher.discount);
      console.log(this.newRegexItem);
      if (this.eVoucher.discount[0].items === null) {
        this.eVoucher.discount[0].items = [];
      }

      this.eVoucher.discount[0].items.push(this.newRegexItem);
      this.newRegexItem = '';
    },
    del(key, e) {
      this.configuration[key].splice(e, 1);
    },
    close() {
      this.$router.push({ name: 'accountVouchers', query: { refresh: true } });
    },
    async save() {
      /* const range = { range: this.rangevalues ? this.rangevalues.map((r) => r.name) : [] };
      const family = { family: this.familyvalues ? this.familyvalues.map((f) => f.name) : [] };
      const category = { category: this.categoryvalues ? this.categoryvalues.map((c) => c.name) : [] }; */
      Object.assign(this.eVoucher.discount[0], { website: this.websitevalues });
      Object.assign(this.eVoucher.discount[0], { brand: this.brandsvalues });
      Object.assign(this.eVoucher.discount[0], { range: this.rangevalues });
      Object.assign(this.eVoucher.discount[0], { family: this.familyvalues });
      Object.assign(this.eVoucher.discount[0], { category: this.categoryvalues });

      const voucher = await GetVoucher(this.eVoucher.code);
      if (voucher.code === this.eVoucher.code) {
        this.nameAlreadyExist = true;
        const $ref = this.$refs.code;
        $ref.style.backgroundColor = 'red';
        $ref.focus();
        // this.$refs.code.focus();
      } else {
        this.eVoucher.createdby = this.$store.state.auth.user.email || this.$store.state.auth.userexternalid;
        const now = new Date();
        this.eVoucher.createdat = now;

        const result = await CreateVoucher(this.eVoucher);
        if (result.status === 200) {
          this.isSuccess = true;
          this.nameAlreadyExist = false;
          const $ref = this.$refs.default;
          $ref.focus();
        } else {
          this.isError = true;
        }
      }
    },
  },
};
</script>
<style>
.multiselect__input {
  border: none !important;
}

.multiselect__tag {
  background-color: black;
}

.multiselect__tag-icon::after {
  color: white;
}

.multiselect__tag-icon:hover {
  background: black;
}

.multiselect__option--highlight {
  background: black;
}

.multiselect__option--highlight::after {
  background: black;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: black;
  color: #fff;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background: black;
  content: attr(data-deselect);
  color: #fff;
}
</style>
