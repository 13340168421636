<template>
  <div>
    <!-- Empty msg -->
    <EmptyState
      v-if="orderResult.length === 0"
      icon="box"
    >
      {{ $t('account.account.myOrders.noOrders') }}
    </EmptyState>
    <router-link
      v-for="(o) in ordersToDisplay"
      :key="o.id"
      :to="{name: 'myOrdersView', params: {id: o.navstatus !== 'pending' ? o.externalorderid : o.id.toString()}}"
      style="    text-decoration: none;"
    >
      <listItemOrder
        :order="o"
        @click.stop
      />
    </router-link>
    <!-- Pagination -->
    <pagination
      class="m-t-4 m-b-4"
      :page="page"
      :pages="pages"
      :items-per-page="itemsPerPage"
      @pageChanged="changePage"
      @itemsPerPageChanged="itemsPerPageChanged"
    />
  </div>
</template>
<script>
import ListItemOrder from '@/elements/ListItemOrder.vue';
import pagination from '@/components/Pagination.vue';
import EmptyState from '@/elements/EmptyState.vue';

export default {
  name: 'Orderlist',
  components: {
    ListItemOrder,
    EmptyState,
    pagination,
  },
  props: {
    orders: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    searchVal: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      orderResult: [],
      itemsPerPage: 20,
      page: 1,
    };
  },
  computed: {
    ordersToDisplay() {
      return this.orderResult.slice(
        this.page * this.itemsPerPage - this.itemsPerPage,
        this.page * this.itemsPerPage,
      );
    },
    pages() {
      const pages = [];
      for (
        let i = 0;
        i < this.orderResult.length / this.itemsPerPage;
        i += 1
      ) {
        pages.push(i + 1);
      }
      return pages;
    },
  },
  watch: {
    filters: {
      handler() {
        this.search();
      },
      deep: true,
    },
    searchVal: {
      handler() {
        this.search();
      },
    },
  },
  mounted() {
    this.search();
  },
  methods: {
    changePage(page) {
      this.page = parseInt(page, 10);
      this.orderResult.slice(
        this.page * this.itemsPerPage - this.itemsPerPage,
        this.page * this.itemsPerPage,
      );
    },
    itemsPerPageChanged(itemsPerPage) {
      this.itemsPerPage = parseInt(itemsPerPage, 10);
      this.changePage(1);
    },
    async search() {
      this.page = 1;
      const filters = {};
      this.filters.filters.forEach((f) => {
        if (!filters[f.type]) {
          filters[f.type] = [];
        }
        filters[f.type].push(f.value);
      });
      if (this.filters.deliveryAddress) {
        filters.deliveryAddress = this.filters.deliveryAddress;
      }

      let searchResult = this.orders.filter((o) => {
        if (filters.preorder) {
          if (!filters.preorder.includes(o.preorder)) {
            return false;
          }
        }
        if (filters.isSent) {
          const isSent = o.shippedqty === o.qty;
          if (!filters.isSent.includes(isSent)) {
            return false;
          }
        }
        if (filters.status) {
          const availableStatusses = o.orderlines.map((x) => x.nextstatus);
          if (!filters.status.some((x) => availableStatusses.includes(x))) {
            return false;
          }
        }
        if (filters.deliveryAddress) {
          const address = o.deliveryaddressid;
          if (filters.deliveryAddress !== address) {
            return false;
          }
        }

        return true;
      });

      if (this.searchVal) {
        searchResult = await this.$search(this.searchVal, searchResult, {
          keys: ['externalorderid', 'reference', 'orderdateFormatted', 'orderlinedescription', 'allserials'],
          defaultAll: true,
          caseSensitive: false,
          shouldSort: true,
          tokenize: false,
          matchAllTokens: true,
          findAllMatches: true,
          threshold: 0.2,
          location: 2,
          distance: 50,
          maxPatternLength: 32,
          minMatchCharLength: 1,
        });
      }

      if (filters.assignable || filters.assigned) {
        searchResult = searchResult.map((x) => {
          const filteredOl = [];
          for (const ol of x.orderlines) {
            const filteredItems = [];
            const olRefs = ol.references || [];
            for (let i = 0; i < ol.qty; ++i) {
              const serial = ol.serials?.[i];
              const index = i + 1;
              const olRef = olRefs.find((r) => (index >= r.index && index < (r.index + r.quantity)) || (serial && r.serial === serial));
              // Assignable ID (just to help with debugging)
              const itemId = {
                externalorderid: x.externalorderid, // Nav order
                orderid: x.externalorderid ? null : x.id, // Unprocessed order
                orderlineid: ol.orderline,
                index,
                serial,
              };

              const assignable = (!olRef || !(olRef.email || olRef.relationId || olRef.firstName || olRef.lastName));
              if ((assignable && filters.assignable) || (!assignable && filters.assigned)) {
                filteredItems.push(itemId);
              }
            }
            if (filteredItems.length > 0) {
              filteredOl.push(filteredItems.length !== ol.qty ? {
                ...ol,
                qty: filteredItems.length,
              } : ol);
            }
          }

          if (filteredOl.length === 0) { return null; }
          return {
            ...x,
            orderlines: filteredOl,
          };
        }).filter((x) => x);
      }

      if (this.filters.sort === 'ETD') {
        searchResult = searchResult.sort((a, b) => { // Not perfect, but we will sort on the ETD of the earliest available non-shipped item in case of ETD
          if ((a.shippedqty === a.qty) > (b.shippedqty === b.qty)) {
            return 1;
          } if ((a.shippedqty === a.qty) < (b.shippedqty === b.qty)) {
            return -1;
          }
          if ((a.shippedqty === a.qty)) { // In case we already shipped, then we will sort on orderdate DESCENDING
            if (a.orderdate > b.orderdate) {
              return -1;
            } if (a.orderdate < b.orderdate) {
              return 1;
            }
            return 0;
          } // In case we haven't shipped yet, we will sort on ETD Ascending (soonest arrivals show up first)
          const calcMinETD = (minETD, x) => {
            if (x.shipped !== true && (!minETD || x.promiseddeliverydate < minETD)) {
              minETD = x.promiseddeliverydate;
            }
            return minETD;
          };
          const aMinETD = a.orderlines.reduce(calcMinETD, null);
          const bMinETD = b.orderlines.reduce(calcMinETD, null);
          if (aMinETD < bMinETD) {
            return -1;
          } if (aMinETD > bMinETD) {
            return 1;
          }
          return 0;
        });
      }

      if (this.filters.sort === 'shipmentdate') {
        searchResult = searchResult.sort((a, b) => { // Not perfect, but we will sort on the ETD of the earliest available non-shipped item in case of ETD
          if ((a.shippedqty === a.qty) > (b.shippedqty === b.qty)) {
            return -1;
          } if ((a.shippedqty === a.qty) < (b.shippedqty === b.qty)) {
            return 1;
          }
          if ((a.shippedqty === a.qty)) { // In case we already shipped, then we will sort on orderdate DESCENDING
            const calcMaxShipmentDate = (shipmentDate, x) => {
              if (x.shipped === true && (!shipmentDate || x.shipmentdate > shipmentDate)) {
                shipmentDate = x.shipmentdate;
              }
              return shipmentDate;
            };
            const aMaxShipmentDate = a.orderlines.reduce(calcMaxShipmentDate, null);
            const bMaxShipmentDate = b.orderlines.reduce(calcMaxShipmentDate, null);
            if (aMaxShipmentDate < bMaxShipmentDate) {
              return 1;
            } if (aMaxShipmentDate > bMaxShipmentDate) {
              return -1;
            }
            return 0;
          } // In case we haven't shipped yet, we will sort on ETD Ascending (soonest arrivals show up first)
          if (a.orderdate > b.orderdate) {
            return -1;
          } if (a.orderdate < b.orderdate) {
            return 1;
          }
          return 0;
        });
      }
      this.orderResult = searchResult;
    },
  },
};
</script>

<style>
</style>
