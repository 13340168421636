<template>
  <bcf-aside @close="close">
    <bcfAsideHeader :close="close">
      {{ $t('account.account.myInvoices.name') }} #{{ invoice && invoice.externalinvoiceid }}
    </bcfAsideHeader>

    <bcfAsideContent>
      <div v-if="!loaded">
        <loader class="m-b-2" />
      </div>
      <div v-if="errorMessage">
        <Message
          type="error"
          :icon="true"
          :content="$t(`api.${errorMessage}`)"
        />
      </div>

      <div
        v-if="loaded && !invoice.paid"
        class="m-b-2"
      >
        <Message
          type="warning"
          :icon="false"
        >
          {{ $t('account.account.myInvoices.payBefore') }}
          <strong>{{ customFormatter(invoice.duedate) }}</strong>.
        </Message>
      </div>

      <div v-if="loaded">
        <section-title>{{ $t('account.account.myInvoices.billingInformation') }}</section-title>

        <table class="table table--striped m-b-4">
          <tr>
            <td>{{ $t('account.account.myInvoices.invoicenumber') }}:</td>
            <td class="text-right">
              {{ invoice && invoice.externalinvoiceid }}
            </td>
          </tr>
          <tr>
            <td>{{ $t('account.account.myInvoices.bookingsdate') }}:</td>
            <td class="text-right">
              {{ customFormatter(invoice.postingdate) }}
            </td>
          </tr>
          <tr>
            <td>{{ $t('account.account.myInvoices.expirationdate') }}:</td>
            <td class="text-right">
              {{ customFormatter(invoice.duedate) }}
            </td>
          </tr>
          <tr>
            <td>{{ $t('account.account.myInvoices.reference') }}:</td>
            <td class="text-right">
              {{ invoice.reference }}
            </td>
          </tr>
          <tr>
            <td>{{ $t('account.account.myInvoices.status') }}</td>
            <td class="text-right">
              <Badge
                v-if="!invoice.paid"
                type="unpaid"
              >
                {{ $t(`account.account.myInvoices.paid.${invoice.status}`) }}
              </Badge>
              <Badge
                v-else
                type="paid"
              >
                {{ $t(`account.account.myInvoices.paid.${invoice.status}`) }}
              </Badge>
            </td>
          </tr>
          <template v-if="!invoice.paid">
            <tr>
              <td>{{ $t('account.account.myInvoices.openAmount') }}:</td>
              <td class="text-right">
                <price
                  :price="invoice.openamount"
                  :currency="invoice.currency"
                />
              </td>
            </tr>
          </template>
        </table>

        <section-title>{{ $t('account.account.myInvoices.prices') }}</section-title>

        <table class="table table--striped m-b-4">
          <tr
            v-for="orderline in invoice.invoicelines"
            :key="orderline.id"
          >
            <td><strong>{{ orderline.qty }} x </strong>{{ orderline.description }}</td>
            <td class="text-right">
              <price
                :price="orderline.lineamountincl ? orderline.lineamountincl.toString() : '0'"
                :currency="invoice.currency"
              />
            </td>
          </tr>
          <tr style="font-weight: bold;">
            <td class="text-right">
              {{ $t('account.account.myInvoices.priceExcl') }}:
            </td>
            <td class="text-right">
              <price
                :price="invoice.total"
                :currency="invoice.currency"
              />
            </td>
          </tr>
          <tr style="font-weight: bold">
            <td class="text-right">
              {{ $t('account.account.myInvoices.vat') }}:
            </td>
            <td class="text-right">
              <price
                :price="invoice.vat ? invoice.vat : '0'"
                :currency="invoice.currency"
              />
            </td>
          </tr>
          <tr style="font-weight: bold">
            <td class="text-right">
              {{ $t('account.account.myInvoices.priceIncl') }}:
            </td>
            <td class="text-right">
              <price
                :price="invoice.totalincl"
                :currency="invoice.currency"
              />
            </td>
          </tr>
        </table>

        <section-title class="m-b-0">
          {{ $t('account.account.myInvoices.orders') }}
        </section-title>

        <div class="m-b-4">
          <div v-if="getSalesOrderNumbers">
            <div
              v-for="(salesorder, index) in getSalesOrderNumbers"
              :key="index"
              @click="openOrder(salesorder.externalorderid)"
            >
              <listItemOrder
                :order="salesorder"
              />
            </div>
          </div>
        </div>

        <section-title class="m-b-0">
          {{ $t('account.account.myInvoices.action') }}
        </section-title>

        <ListItem
          v-if="invoice.$$pdf"
          icon-before
        >
          <i class="uil uil-file-download-alt" />
          <a
            :href="invoice.$$pdf"
            target="_blank"
          >{{ $t('account.account.myInvoices.download') }}</a>
        </ListItem>
        <ListItem
          v-for="cert in invoice.$$ecConformityCertificates"
          :key="cert.serial"
          icon-before
        >
          <i class="uil uil-file-download-alt" />
          <a
            :href="cert.pdf"
            target="_blank"
          >{{ $t('account.account.myInvoices.downloadEcConformityCertificate', {serial: cert.serial}) }}</a>
        </ListItem>
      </div>
    </bcfAsideContent>
    <myOrdersView
      v-if="orderOpen"
      :id="orderOpen"
      use-close-event="true"
      @close="openOrder()"
    />
  </bcf-aside>
</template>
<script>
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import ListItemOrder from '@/elements/ListItemOrder.vue';
import ListItem from '@/elements/ListItem.vue';
import Badge from '@/elements/Badge.vue';
import price from '@/elements/Price.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import Message from '@/components/Message.vue';
import loader from '@/elements/loader.vue';
import { formatPrice, floatValue } from '@/utils/pricingUtils';
import { getInvoiceDetail } from '@/api/api';
// import { mapGetters, mapState } from "vuex";
import { formatDate } from '@/utils/dateUtils';

const myOrdersView = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/myorders/view.vue');

export default {
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    Message,
    SectionTitle,
    ListItemOrder,
    ListItem,
    Badge,
    price,
    loader,
    myOrdersView,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    useCloseEvent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      invoice: {},
      loaded: false,
      errorMessage: '',
      orderOpen: null,

    };
  },
  computed: {
    getSalesOrderNumbers() {
      return this.invoice.invoicelines.reduce((result, s) => {
        if (result.findIndex((o) => s.externalorderid === o.externalorderid) === -1) {
          result.push(s);
        }
        return result;
      }, []);
    },
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    customFormatter(date) {
      return formatDate(date);
    },
    close() {
      if (this.useCloseEvent) {
        this.$emit('close');
      } else {
        this.$router.push(this.$store.state.global.previousRoute ? this.$store.state.global.previousRoute : { name: 'myInvoices' });
      }
    },
    async fetchData() {
      try {
        const result = await getInvoiceDetail(
          this.$store.state.customer.selectedCustomer.id,
          this.id,
        );
        this.invoice = result.result;
        // console.log(this.invoice);
        this.loaded = true;
      } catch (error) {
        this.loaded = true;
        this.errorMessage = error.message;
      }
    },
    formatPrice,
    floatValue,
    openOrder(order) {
      this.orderOpen = order;
    },

  },
};
</script>
