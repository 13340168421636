<template>
  <div>
    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <router-link :to="{name: 'account'}">
                <i class="uil uil-arrow-left" />
              </router-link>
              <h1>{{ $t('account.account.myStats.title') }}</h1>
            </div>
          </div>
        </div>
      </header>

      <div class="container">
        <div
          v-if="locations.length"
          class="filters m-b-4"
        >
          <!-- Locations -->
          <div class="location-filter">
            <b-dropdown
              variant="secondary"
              size="lg"
              :class="{
                'custom-dropdown': true,
                'locations__dropdown': true,
                'no-right-border': user && user.type>=2
              }"
            >
              <template #button-content>
                <ListItem
                  v-if="location"
                  class="filter-dropdown-content location selected"
                >
                  <div class="location-icon">
                    <i class="uil uil-map-marker-alt" />
                  </div>
                  <div class="location-content">
                    <strong>{{ location.Name }}
                    </strong>
                    <small>
                      {{
                        `${location.street}, ${location.postalcode}
                        ${location.city} (${$t(`countries.${location.country}`)})`
                      }}
                    </small>
                  </div>
                </ListItem>
                <div v-else>
                  Select a location
                </div>
              </template>

              <b-dropdown-item
                v-for="location in allLocations"
                :key="location.externalid"
                class="location filter-dropdown-item"
                @click="selectLocation(location)"
              >
                <ListItem
                  class="location filter-dropdown-content"
                >
                  <div class="location-icon">
                    <i class="uil uil-map-marker-alt" />
                  </div>
                  <div class="location-content">
                    <strong>{{ location.Name }}
                    </strong>
                    <small>
                      {{
                        `${location.street}, ${location.postalcode}
                            ${location.city} (${$t(`countries.${location.country}`)})`
                      }}
                    </small>
                  </div>
                </ListItem>
              </b-dropdown-item>
            </b-dropdown>
            <button
              v-if="user && user.type>=2"
              class="button button--outline button--icon custom-address-button"
              @click="setCustomLocation()"
            >
              <div class="gps-icon">
                <i class="uil uil-map-marker" />
              </div>
            </button>
          </div>

          <div class="filter-spacer" />

          <div class="range-filters">
            <!-- Time Range -->
            <b-dropdown
              variant="secondary"
              size="lg"
              class="custom-dropdown timerange__dropdown"
            >
              <template #button-content>
                <ListItem
                  v-if="timerange"
                  class="filter-dropdown-content timerange selected"
                >
                  <i class="uil uil-calender" />
                  <strong>{{ $t(timerange.label) }}</strong>
                </ListItem>
                <div v-else>
                  Select a time-range
                </div>
              </template>

              <b-dropdown-item
                v-for="range in timeranges"
                v-once
                :key="range.key"
                class="timerange filter-dropdown-item"
                @click="selectTimerange(range)"
              >
                <ListItem class="filter-dropdown-content">
                  <i class="uil uil-calender" />
                  <strong>{{ $t(range.label) }}</strong>
                </ListItem>
              </b-dropdown-item>
            </b-dropdown>

            <!-- Distance Range -->
            <b-dropdown
              variant="secondary"
              size="lg"
              class="custom-dropdown distance__dropdown"
            >
              <template #button-content>
                <ListItem
                  v-if="distancerange"
                  icon-big
                  class="filter-dropdown-content distancerange selected"
                >
                  <i class="uil uil-map-pin" />
                  <strong>{{ $t(distancerange.label) }}</strong>
                </ListItem>
                <div v-else>
                  Select a time-range
                </div>
              </template>

              <b-dropdown-item
                v-for="range in distanceranges"
                v-once
                :key="range.key"
                class="distancerange filter-dropdown-item"
                @click="selectDistancerange(range)"
              >
                <ListItem
                  class="filter-dropdown-content"
                >
                  <i class="uil uil-map-pin" />
                  <strong>{{ $t(range.label) }}</strong>
                </ListItem>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div v-if="locations.length === 0">
          <emptystate icon="store">
            Geen locaties...
          </emptystate>
        </div>

        <div class="statistics">
          <!-- Loading -->
          <div
            v-if="!loaded"
            class="row"
          >
            <div class="col">
              <loader />
            </div>
          </div>
          <div
            v-if="loaded"
            class="stats-content"
          >
            <!-- frameplatforms -->

            <div class="stats-category m-b-4">
              <h2>{{ $t('account.account.myStats.perPlatform') }}</h2>
              <div class="stats-category__content">
                <stats-table
                  class="stats-table"
                  :items="stats.frameplatforms"
                  :description-label="'account.account.myStats.platform'"
                />
                <chart
                  class="stats-chart"
                  :items="stats.frameplatforms"
                  :range="timerange.value()"
                  :granularity="timerange.granularity"
                />
              </div>
            </div>

            <!-- framefamilies -->
            <div class="stats-category m-b-4">
              <h2>{{ $t('account.account.myStats.perFamily') }}</h2>
              <div class="stats-category__content">
                <stats-table
                  class="stats-table"
                  :items="stats.framefamilies"
                  :description-label="'account.account.myStats.family'"
                />
                <chart
                  class="stats-chart"
                  :items="stats.framefamilies"
                  :range="timerange.value()"
                  :granularity="timerange.granularity"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { getDealerStats } from '@/api/api';

// Elements
import loader from '@/elements/loader.vue';
import emptystate from '@/elements/EmptyState.vue';
import ListItem from '@/elements/ListItem.vue';

import dialogs from '@/utils/dialogs';
import CustomLocation from './customLocation.vue';
import chart from './chart.vue';
import statsTable from './table.vue';

const timeRanges = [
  {
    key: 'week',
    label: 'account.account.myStats.last7d',
    granularity: 'day',
    value: () => {
      const n = new Date();
      const t = new Date(n.getFullYear(), n.getMonth(), n.getDate());
      return [new Date(t.getFullYear(), t.getMonth(), t.getDate() - 7), t];
    },
  },
  {
    key: 'month',
    label: 'account.account.myStats.last31d',
    granularity: 'day',
    value: () => {
      const n = new Date();
      const t = new Date(n.getFullYear(), n.getMonth(), n.getDate());
      return [new Date(t.getFullYear(), t.getMonth(), t.getDate() - 31), t];
    },
  },
  {
    key: '3months',
    label: 'account.account.myStats.last3months',
    granularity: 'month',
    value: () => {
      const n = new Date();
      const t = new Date(n.getFullYear(), n.getMonth() + 1, 0);
      return [new Date(t.getFullYear(), t.getMonth() - 2, 1), t];
    },
  },
  {
    key: '6months',
    label: 'account.account.myStats.last6months',
    granularity: 'month',
    value: () => {
      const n = new Date();
      const t = new Date(n.getFullYear(), n.getMonth() + 1, 0);
      return [new Date(t.getFullYear(), t.getMonth() - 5, 1), t];
    },
  },
  {
    key: 'year',
    label: 'account.account.myStats.last12months',
    granularity: 'month',
    value: () => {
      const n = new Date();
      const t = new Date(n.getFullYear(), n.getMonth() + 1, 0);
      return [new Date(t.getFullYear(), t.getMonth() - 11, 1), t];
    },
  },
];

function genDistanceranges() {
  return [10, 20, 50, 100].map((r) => (
    {
      key: r.toString(10),
      label: `${r.toString(10)}km`,
      value: r,
    }
  ));
}

export default {
  components: {
    ListItem,
    emptystate,
    loader,
    chart,
    statsTable,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    useCloseEvent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      loading: true,
      location: null,
      timerange: timeRanges[1],
      timeranges: timeRanges,
      distancerange: genDistanceranges()
        .find((k) => k.key === '50'),
      distanceranges: genDistanceranges(),
      stats: {
        frameplatforms: [],
        framefamilies: [],
      },
      errorMessage: null,
      customLocation: null,
    };
  },
  computed: {
    ...mapState({
      locations: (state) => state.customer.selectedCustomer.addresses,
      user: (state) => state.auth.user,
    }),
    allLocations() {
      return [...this.locations, this.customLocation].filter((x) => x);
    },
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async selectLocation(location) {
      if (location !== this.location) {
        this.location = location;
        this.fetchData();
      }
    },
    async selectTimerange(range) {
      if ((range && range.key) !== (this.timerange && this.timerange.key)) {
        this.timerange = range;
        this.fetchData();
      }
    },
    async selectDistancerange(range) {
      if ((range && range.key) !== (this.distancerange && this.distancerange.key)) {
        this.distancerange = range;
        this.fetchData();
      }
    },
    async fetchData() {
      if (!this.location) {
        this.location = this.locations && this.locations[0];
      }
      if (!this.location) {
        console.log('No location to fetch stats for');
        return;
      }
      try {
        this.loading = true;
        const baseParameters = {
          customerId: this.$store.state.customer.selectedCustomer.id,
          range: this.timerange.value(),
          distance: this.distancerange.value,
          granularity: this.timerange.granularity,
          ...(this.location.externalid === 'CUSTOM' ? {
            lat: this.location.latitude,
            lng: this.location.longitude,
          } : { locationId: this.location.externalid }),
        };
        const [families, platforms] = await Promise.all([
          getDealerStats({
            ...baseParameters,
            type: 'framefamilies',
          }),
          getDealerStats({
            ...baseParameters,
            type: 'frameplatforms',
          }),
        ]);
        this.stats = {
          framefamilies: families,
          frameplatforms: platforms,
        };

        this.loading = true;
        this.loaded = true;
      } catch (error) {
        this.loaded = true;
        this.errorMessage = error.message;
      }
    },
    async setCustomLocation() {
      const addr = await dialogs.show({
        component: CustomLocation,
        props: {
          location: this.customLocation,
        },
      });

      if (addr && typeof (addr) === 'object') {
        this.customLocation = {
          ...addr,
          externalid: 'CUSTOM',
          Name: 'Custom',
        };
        this.selectLocation(this.customLocation);
      }

      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";

@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.filters {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
  flex-wrap: wrap;

  @include media-breakpoint-down(md) {
    padding-top: 2rem;
  }

  .location-filter{
    flex: 10 1 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 40em;
    max-width: calc( min( 100%, 40em) );
  }

  margin: -.5rem;
  margin-bottom: 1rem;

  >*{
    margin: .5rem;
  }

  .filter-spacer {
    flex: 1 1 0;
    margin: 0;
  }

  .range-filters{
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;

    margin: 0 .5rem 0 0;

    >*{
      margin: .5rem;
    }
  }
}
.locations__dropdown{
  max-width: calc( 100% - 7.5rem ); /** Ugly fix to get around bootstrap dropdown css not resizing down to fit dropdown+button on a line */
}

.custom-dropdown {
  display: inline-flex;
}

.filter-dropdown-content {
  border-bottom: none;
  font-size: 13.5px;
  line-height: 15px;
  text-align: left;
  display: inline-block;

  &.location {
    display: inline-flex;
    flex: 1 1 auto;
    overflow: hidden;
  }

  &.selected {
  }

  &:not(.selected) {
    padding: 1.25rem 0;
  }

  .uil {
    margin-right: .5rem;
  }
}

.filter-dropdown-item {
  &:not(.selected):not(:last-child) {
    border-bottom: 1px solid $light-grey;
  }
}

.location-content {
  display: inline-flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;

  small{
    text-overflow: ellipsis;
    overflow: hidden
  }
}

@media only screen and (max-width: 450px) {
  &.location {
    max-width: 95vw;
  }
}

@media only screen and (min-width: 450px) {
  .filter-dropdown-content {
    min-width: 12.0rem;

    &.location {
      min-width: 30em;
    }
  }
}

.location-icon {
  background-color: $dark-grey;
  border-radius: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-flex;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 4rem;
  min-height: 4rem;
  color: #FFF;
  margin-right: 2rem;

  .uil {
    margin: 0;
  }
}

.custom-address-button{
  align-self: stretch;
  max-height: initial;

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.gps-icon{
  color: $dark-grey;
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-flex;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  .uil {
    margin: 0;
    font-size: 3rem;
  }
}

.stats-content {
  display: flex;
  flex-direction: column;
}

.stats-category {
  >.stats-category__content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > .stats-table {
      width: auto;
      flex: 1 0 auto;
      margin: 0 auto;
      max-width: 30em;
      padding: 0 .5em;
    }

    > .stats-chart {
      position: relative;
      width: auto;
      flex: 100 100 50%;
      min-width: 20em;
      padding: .5rem .25em .5rem .5em;
      min-height: 15em;
      min-height: calc( min( 100vh - 5em, 15em ) );
      max-height: 80vh;
      max-height: calc( min( 100vh - 5em, 30em ) );
    }
  }

  &:nth-child(2){
    >.stats-category__content {
      flex-wrap: wrap-reverse;
      align-items: flex-end;

      > *:nth-child(1) {
        order: 2;
      }

      > *:nth-child(2) {
        order: 1;
      }
    }
  }
}

</style>
