<template>
  <div>
    <div :class="galleryClass">
      <a
        v-for="(image, index) in lightBoxImages.slice(
          first ? 1 : 0, first ? thumbAmount + 1 : thumbAmount
        )"
        :key="image.key"
        :style="getImageStyle(image)"
        @click.prevent="openGallery(first ? index + 1 : index)"
      />
    </div>
    <LightBox
      ref="lightbox"
      :images="lightBoxImages"
      :show-caption="false"
      :show-light-box="false"
    />
  </div>
</template>
<script>
import LightBox from 'vue-image-lightbox';

export default {
  components: { LightBox },
  props: {
    images: {
      type: Object,
      default: () => ({}),
    },
    first: {
      type: Object,
      default: null,
    },
  },
  data() {
    const thumbAmount = Object.keys(this.images).length < 7 ? Object.keys(this.images).length : 6;
    const lightBoxImages = Object.values(this.images).reduce(
      (images, image, key) => {
        images.push({
          key,
          thumb: image.thumb,
          src: image.highres,
        });
        return images;
      },
      [],
    );
    if (this.first) {
      lightBoxImages.unshift({
        key: 'first',
        thumb: this.first.thumb,
        src: this.first.highres,
      });
    }

    return {
      thumbAmount,
      galleryClass: `gallery gallery--${thumbAmount > 1 ? thumbAmount : 2}`,
      lightBoxImages,
    };
  },
  created() {
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.keyCode === 27) {
        this.close();
      }
    };
  },
  methods: {
    getImageStyle(image) {
      return {
        'background-image': `url("${image.thumb}")`,
      };
    },
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },
  },
};
</script>

<style lang="scss">
@import "../../../node_modules/vue-image-lightbox/dist/vue-image-lightbox.min.css";
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 1 !important;
}
</style>
