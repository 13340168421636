<template>
  <div>
    <router-view />

    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <router-link :to="{name: 'myCustomers'}">
                <i class="uil uil-arrow-left" />
              </router-link>
              <h1>
                {{ $t(`account.account.myCustomers.detail.${isNewRelation ? 'titleNew' : 'title'}`) }}
              </h1>
            </div>
          </div>
        </div>
      </header>
    </div>

    <div class="container">
      <!-- Loading indication -->
      <loading-overlay :loading="isLoading()">
        <div v-if="errorMessage && !relation">
          <Message
            type="error"
            :icon="true"
            :content="$t(`api.${errorMessage}`)"
          />
        </div>

        <div class="h-sections">
          <form
            class="section contact-section"
            autocomplete="off"
            @submit.prevent="onSave"
          >
            <SectionTitle>{{ $t('account.account.myCustomers.contact') }}</SectionTitle>

            <RelationInfo
              v-model="relation"
              class="section"
              @valid="checkValid"
            />

            <div v-if="errorMessage && relation">
              <Message
                type="error"
                :icon="true"
                :content="$t(`api.${errorMessage}`)"
              />
            </div>

            <div class="controls">
              <button
                class="button button--primary"
                :disabled="!isValid || !hasInfoChanges"
                @click.prevent="onSave"
              >
                {{ $t(`account.account.myCustomers.${isNewRelation ? 'create' : 'save'}`) }}
              </button>
            </div>
          </form>

          <div class="v-sections">
            <div class="section">
              <SectionTitle class="m-b-0">
                {{ $t('account.account.myCustomers.info') }}
              </SectionTitle>

              <div class="info-fields">
                <div class="info-field">
                  <label class="info-field__label">
                    {{ $t('account.account.myCustomers.hasAnAccount') }}
                  </label>
                  <span class="info-field__value">
                    {{ relation.b2cUserId === undefined? '-' : $t(relation.b2cUserId > 0? 'general.yes' : 'general.no') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="section">
              <SectionTitle class="m-b-0">
                {{ $t('account.account.myOrders.action') }}
              </SectionTitle>

              <ListItem
                icon-before
                interactive
                :disabled="isNewRelation || relation.b2cUserId > 0 || !relation.email"
                @click.native="onSendInvite"
              >
                <loading-overlay :loading="isLoading('sendInvite')">
                  <i class="uil uil-message" />
                  <span>{{ $t('account.account.myCustomers.sendInviteToMakeAccount') }}</span>
                </loading-overlay>
              </ListItem>

              <ListItem
                icon-before
                interactive
                :disabled="isNewRelation"
                @click.native="onDelete"
              >
                <loading-overlay :loading="isLoading('delete')">
                  <i class="uil uil-trash" />
                  <span>{{ $t('account.account.myCustomers.delete') }}</span>
                </loading-overlay>
              </ListItem>
            </div>
          </div>
        </div>

        <loading-overlay :loading="isLoading('items')">
          <div class="section">
            <SectionTitle>{{ $t('account.account.myCustomers.items') }}</SectionTitle>

            <div class="assigned-items-header m-md-t-2">
              <div class="assigned-items-filters">
                <label v-if="isDesktop">Filter:</label>

                <Search
                  v-model="searchVal"
                  :disabled="isNewRelation"
                  style="flex: 1 1 auto"
                />
              </div>

              <div
                v-if="isDesktop"
                class="assigned-items-controls"
              >
                <label>{{ $t('account.account.myCustomers.assignItems') }}:</label>

                <search-input
                  v-model="search.input"
                  :placeholder="$t('account.account.myCustomers.searchOrSerial')"
                  :items="search.items"
                  :autofocus="false"
                  :loading="!!search.loading"
                  :disabled="isNewRelation"
                  @search="searchEquipment($event)"
                  @selected="addNewItem"
                >
                  <template v-slot:item="{ item }">
                    <OrderedItem
                      :interactive="false"
                      :id-selectable="true"
                      :item="item"
                    />
                  </template>
                </search-input>
              </div>
              <button
                v-else
                class="button button--secondary m-b-2 assigned-items-add"
                @click="addNewItem(null)"
              >
                <i class="uil uil-plus" />
              </button>
            </div>

            <div
              class="items assigned-items"
            >
              <Message
                v-if="!filteredItems || filteredItems.length===0 || isNewRelation"
                type="update"
                :icon="true"
                :content="$t(`account.account.myCustomers.${
                  isNewRelation
                    ? 'saveBeforeAssigning'
                    : searchVal? 'searchNoEquipment':'noEquipment'}`, {
                  search: searchVal
                })"
              />
              <template v-else>
                <div
                  v-for="item of itemsToDisplay"
                  :key="item.serial || item.id"
                  class="assigned-item"
                  @click="editItem(item, $event)"
                >
                  <OrderedItem
                    class="assigned-item__detail"
                    :item="item"
                    :interactive="false"
                  />
                  <div class="assigned-item__info">
                    <div class="assigned-item__info__float">
                      <div
                        v-if="item.stock"
                        class="assigned-item__info__field"
                      >
                        <label class="assigned-item__info__label">{{ $t('account.account.myStock.status') }}</label>
                        <div class="assigned-item__info__value">
                          <status-select
                            v-model="item.stock.status"
                            :disabled="true"
                            :statuses="['sold','reserved']"
                          />
                        </div>
                      </div>
                      <div
                        v-if="(item.registration && item.registration.purchaseDate)"
                        class="assigned-item__info__field"
                      >
                        <label class="assigned-item__info__label">{{ $t('account.account.myCustomers.purchaseDate') }}</label>
                        <div class="assigned-item__info__value">
                          {{ dateFormatter(item.registration.purchaseDate) }}
                        </div>
                      </div>
                    </div>

                    <div class="assigned-item__info__note">
                      <label class="assigned-item__info__label">{{ $t('account.account.myOrders.notes') }}</label>
                      <div class="assigned-item__info__value">
                        {{ item.reference.note }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <pagination
                class="m-t-4 m-b-4"
                :page="page"
                :pages="pages"
                :items-per-page="itemsPerPage"
                @pageChanged="changePage"
                @itemsPerPageChanged="itemsPerPageChanged"
              />
            </div>
          </div>
        </loading-overlay>
      </loading-overlay>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Search from '@/components/Search.vue';
import loadingOverlay from '@/elements/LoadingOverlay.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import ListItem from '@/elements/ListItem.vue';
import Message from '@/components/Message.vue';
import pagination from '@/components/Pagination.vue';
import {
  AddNewCustomerRelation,
  DeleteCustomerRelation,
  GetCustomerRelation,
  QueryAssignableItems,
  UpdateCustomerRelation,
  InviteCustomerRelationToB2C,
} from '@/api/api';
import translations from '@/translations';
import SearchInput from '@/components/searchInput.vue';
import OrderedItem from '@/components/item/orderedItem.vue';
import dialogs from '@/utils/dialogs'; // This is for simple yesNo, cancelDelete, yesNoCancel, ... dialogs
import { formatDate } from '@/utils/dateUtils';
import {
  assignedItemFromSelected,
  makeEquipmentSearchResult,
} from '@/views/account/account/mycustomers/utils';
import statusSelect from '../mystock/statusSelect.vue';
import AssignmentDialog from './assignmentDialog.vue';
import RelationInfo from './relationInfo.vue';

export default {
  components: {
    Message,
    SectionTitle,
    RelationInfo,
    ListItem,
    SearchInput,
    OrderedItem,
    Search,
    // loader,
    loadingOverlay,
    statusSelect,
    pagination,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      relation: {
      },
      filteredItems: [],
      loading: {
        relation: false,
      },
      origRelation: {},
      errorMessage: '',
      search: {
        input: '',
        items: [],
        query: '', // Which searchInput was used that resulted in searchItems
        loading: false,
      },
      page: 1,
      itemsPerPage: 10,
      isMobile: false,
      isDesktop: true,
      isValid: false,
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language.current,
      user: (state) => state.auth.user,
      customer: (state) => state.customer,
    }),
    customerId() {
      return this.customer.selectedCustomerId;
    },
    isNewRelation() {
      return Number.isNaN(Number.parseInt(this.id, 10));
    },
    hasInfoChanges() {
      return ['firstName', 'lastName', 'email', 'phone', 'language'].find((key) => this.origRelation[key] !== this.relation[key]);
    },
    lang() {
      return translations[this.$store.state.language.current].Datepicker;
    },
    searchVal: {
      get() {
        return this.$route.query.search;
      },
      set(newVal) {
        this.$router.push({
          name: this.$router.currentRoute.name,
          query: {
            ...this.$route.query,
            search: newVal,
          },
        });
        this.page = 1;
        this.searchAssigned();
      },
    },
    itemsToDisplay() {
      return this.filteredItems.slice(
        this.page * this.itemsPerPage - this.itemsPerPage,
        this.page * this.itemsPerPage,
      );
    },
    pages() {
      const pages = [];
      for (
        let i = 0;
        i < this.filteredItems.length / this.itemsPerPage;
        i += 1
      ) {
        pages.push(i + 1);
      }
      return pages;
    },
  },
  watch: {
    $route() {
      const rid = Number.isNaN(this.id) ? undefined : Number.parseInt(this.id, 10);
      if (rid !== this.relation.id) {
        this.load();
      }
    },
  },
  mounted() {
    this.resetNew();
    if (!this.isNewRelation) {
      this.load();
      this.searchEquipment();
    }
  },
  created() {
    window.addEventListener('resize', this.updateWindowSize);
    this.updateWindowSize();
  },
  destroyed() {
    window.removeEventListener('resize', this.updateWindowSize);
  },
  methods: {
    checkValid(emailValid) {
      this.isValid = emailValid;
    },
    dateFormatter(date) {
      return formatDate(date);
    },
    markLoading(key = 'relation', value = true) {
      this.loading = {
        ...this.loading,
        [key]: value,
      };
    },
    markLoaded(key = 'relation') {
      this.markLoading(key, false);
    },
    isLoading(key = 'relation') {
      return !!this.loading[key];
    },
    resetNew() {
      this.relation = {
        firstName: '',
        lastName: '',
        email: '',
        address: null,
        phone: '',
        language: this.language,
      };
    },
    async load() {
      const relationId = this.id;
      const { customerId } = this;
      if (Number.isNaN(relationId)) {
        throw new Error(`Invalid relation id: ${relationId}`);
      }
      this.markLoading();
      try {
        const result = await GetCustomerRelation({ cid: customerId, rid: relationId });
        this.origRelation = result;
        this.relation = { ...result };
        this.searchAssigned();
      } catch (err) {
        this.relation = null;
        this.errorMessage = err.message;
      } finally {
        this.markLoaded();
      }
    },
    close() {
      if (this.useCloseEvent) {
        this.$emit('close');
      } else {
        this.$router.push({ name: 'myCustomers' });
      }
    },
    async onSave() {
      const { relation } = this;
      const request = {
        ...relation,
      };
      delete request.items;
      const rid = this.id;
      const cid = this.customerId;
      let result = null;
      try {
        this.errorMessage = null;
        this.markLoading();
        if (this.isNewRelation) {
          try {
            result = await AddNewCustomerRelation(this.customer.selectedCustomerId, request);
            this.relation = result;
          } catch (e) {
            if (e.body && e.status === 409 && e.body.err && e.body.err?.name === 'potentialDuplicate') {
              const duplicates = e.body.err.data;
              const duplicate = duplicates[0];
              const tCtx = {
                duplicate: [
                  [duplicate.firstName, duplicate.lastName].filter((x) => x).join(' '),
                  duplicate.email ? `<a href="mailto:/${duplicate.email}">${duplicate.email}</a>` : null,
                ].filter((x) => x).join(' '),
              };
              // TODO: dialog could use further improvement, we have the list of duplicates (in e.body.err.data)
              const confirmed = await dialogs.show({
                title: this.$t('account.account.myCustomers.confirmDuplicate', tCtx),
                htmlContent: this.$t('account.account.myCustomers.confirmDuplicateContent', tCtx),
                type: 'cancelYes',
              });
              if (confirmed) {
                result = await AddNewCustomerRelation(this.customer.selectedCustomerId, { ...request, duplicateConfirm: true });
              }
            } else {
              throw e;
            }
          }
        } else {
          result = await UpdateCustomerRelation({
            cid, rid,
          }, request);
          this.origRelation = result;
          this.relation = { ...result };
        }
      } catch (err) {
        this.errorMessage = err.message;
      } finally {
        this.markLoaded();
      }

      if (result && result.id !== this.origRelation.id) {
        this.$router.push({
          name: 'myCustomersDetail',
          params: { id: result.id },
        });
      }
    },
    async onDelete() {
      const { relation } = this;
      const tCtx = { name: relation.firstName || relation.lastName || relation.email };
      const confirmed = await dialogs.show({
        title: this.$t('account.account.myCustomers.deleteTitle', tCtx),
        content: this.$t('account.account.myCustomers.deleteContent', tCtx),
        type: 'cancelDelete',
      });
      if (confirmed) {
        try {
          this.markLoading('delete');
          const rid = this.id;
          const cid = this.customerId;
          const result = await DeleteCustomerRelation({
            cid,
            rid,
          });
          if (result) {
            this.$router.push({ name: 'myCustomers' });
          }
        } finally {
          this.markLoaded('delete');
        }
      }
    },
    async onSendInvite() {
      const { relation } = this;
      let result = false;
      try {
        this.markLoading('sendInvite');
        this.errorMessage = null;

        const rid = this.id;
        const cid = this.customerId;
        result = await InviteCustomerRelationToB2C({
          cid,
          rid,
        });

        if (result) {
          const tCtx = { ...relation, name: relation.firstName || relation.lastName || relation.email };
          await dialogs.show({
            title: this.$t('account.account.myCustomers.inviteSent', tCtx),
            content: this.$t('account.account.myCustomers.inviteSentContent', tCtx),
            type: 'ok',
          });
        }
      } catch (err) {
        this.errorMessage = err.message;
      } finally {
        this.markLoaded('sendInvite');
      }
    },
    updateSearchResult(search, result) {
      if (!this.search.loading || (this.search.loading && this.search.loading.search === search)) {
        this.search = {
          ...this.search,
          ...makeEquipmentSearchResult(
            search !== undefined ? search : this.search.input,
            result !== undefined ? result : this.search.result,
          ),
        };
      }
    },
    async searchEquipment(search) {
      this.search = { ...this.search, loading: { search } };
      try {
        const searchResult = (await QueryAssignableItems(
          this.customer.selectedCustomerId,
          {
            search,
          },
        ));

        this.updateSearchResult(search, searchResult);
      } catch (err) {
        this.search = { ...this.search, loading: false };
        throw err;
      }
    },
    searchAssigned() {
      const allItems = this.relation.items;

      if (this.$route.query.search) {
        this.$search(this.$route.query.search, allItems, {
          keys: ['serial', 'item.description', 'order.externalid', 'item.configurationid'],
          defaultAll: true,
          caseSensitive: false,
          shouldSort: false,
          tokenize: false,
          matchAllTokens: true,
          findAllMatches: true,
          threshold: 0.2,
          location: 2,
          distance: 50,
          maxPatternLength: 32,
          minMatchCharLength: 1,
        })
          .then((results) => {
            this.filteredItems = results;
          });
      } else {
        this.filteredItems = allItems;
      }
    },
    async addNewItem(item, origEvent) {
      const result = await dialogs.show({
        component: AssignmentDialog,
        props: {
          relation: this.relation,
          value: {
            items: item ? [assignedItemFromSelected(item, origEvent)] : [],
          },
          autofocusSearch: !item,
          allowEmptySave: true,
        },
      });

      if (result) {
        await Promise.all([
          this.searchEquipment(''),
          this.load(),
        ]);
      }
    },
    async editItem(item) {
      const result = await dialogs.show({
        component: AssignmentDialog,
        props: {
          relation: this.relation,
          mode: 'edit',
          value: {
            items: [item],
            stockStatus: (item.stock?.status) || 'sold',
            stockAddress: (item.stock?.address?.externalid) ?? this.customer.addresses?.[0]?.externalid,
            purchaseDate: (item.registration?.purchaseDate) || item.reference?.purchaseDate || new Date(),
            note: item.reference?.note,
            sendEmail: false, // Not for editing (might just be internal note changed)
          },
        },
      });

      if (result) {
        await Promise.all([
          this.searchEquipment(''),
          this.load(),
        ]);
      }
    },
    changePage(page) {
      this.page = parseInt(page, 10);
    },
    itemsPerPageChanged(itemsPerPage) {
      this.itemsPerPage = parseInt(itemsPerPage, 10);
      this.changePage(1);
    },
    updateWindowSize() {
      this.isMobile = window.innerWidth < 768;
      this.isDesktop = window.innerWidth >= 1024;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";

@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";

.content{
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
.section{
  flex: 0 0 auto;
}

.h-sections{
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}
.v-sections{
  flex-direction: column;
  align-items: stretch;
}

.h-sections, .v-sections{
  display: flex;
  justify-content: stretch;
  margin: -1rem;

  >.section, >.v-sections, >.h-sections{
    flex: 1 1 auto;
    min-width: 20em;
    padding: 1rem;

    &.contact-section{
      flex-basis: 30em;
    }
  }
}

.controls{
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.spacer{
  flex: 1 1 0;
}

.assigned-item{
  display: flex;
  flex-direction: row;
  justify-content: stretch;

  &__detail{
    flex: 1 1 auto;
  }
  &__remove{
    flex: 0 0 auto;
    align-self: center;
  }
}

.assigned-items{
  padding: 1rem .5rem;

  .assigned-item{
    display: grid;
    //grid-template-columns: minmax(2fr, 45em) minmax(20em, 1fr);
    grid-template-columns: minmax(10em, 45em) minmax(20em, 30em);

    padding: 0 1.5rem;
    margin-bottom: 1rem;

    background: $white;
    transition: background 100ms;

    @include shadow-small;

    cursor: pointer;
    &:hover{
      background: $subtle-grey;
    }

    &__detail{
      grid-row: 1;
      grid-column: 1;
    }

    &__info{
      grid-row: 1;
      grid-column: 2;

      padding: 1.5rem 0;

      &__float{
        float: right;
      }

      &__field{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        line-height: 1.5em;
      }
      &__label{
        color: $dark-grey;
        text-align: right;
        margin-right: .3em;
      }
      &__value{
        grid-column: 2;
      }
      &__note{

      }
    }
  }
}

.assigned-items-header{
  display: flex;
  flex-direction: row;
  justify-content: stretch;

  margin: -1rem;
  >div{
    flex: 1 1 auto;
    margin: 1rem;
  }
}

.assigned-items-add{
  flex: 0 0 auto;
  padding: 1.5rem;
  align-self: center;
}

.info-fields{
  display: grid;
  grid-template-columns: minmax(auto, 10em) 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  padding: 1rem;

  .info-field{
    display: contents;

    &__label{
      grid-column: 1;
      color: $dark-grey;
      text-align: right;
    }
    &__value{
      grid-column: 2;
    }
  }
}
</style>
