<template>
  <form>
    <bcf-aside
      @close="close"
    >
      <bcfAsideHeader :close="close">
        {{ $t('account.account.myWarranties.newWarranty') }}
      </bcfAsideHeader>
      <bcf-aside-content ref="top">
        <loading-overlay>
          <div class="steps-progress">
            <div
              v-for="step in steps.total"
              :key="step"
              class="steps-progress__item"
              :class="{ 'steps-progress__item--active' : (step <= steps.active) }"
            >
              <span v-if="step === steps.total"><i class="uil uil-check" /></span>
              <span v-else>{{ step }}</span>
            </div>
          </div>
          <Message
            v-if="!areMandatoryFieldsFilled"
            ref="top"
            type="error"
            :icon="true"
            :show-close="true"
            @closeMessage="closeMessageMandatoryFields"
          >
            <strong>{{ $t('account.account.myWarranties.message.title') }}</strong><br>
            <small>{{ $t('account.account.myWarranties.message.description') }}
            </small>
          </Message>

          <!-- Step 1 -->

          <div v-if="steps.active === 1">
            <SectionTitle>{{ $t('account.account.myWarranties.whatproduct') }}</SectionTitle>
            <div class="form-group--list form-group--list--image">
              <ul>
                <li
                  v-for="(option, index) in optiontypes"
                  :key="index"
                >
                  <radio-button
                    v-model="warrantyData.type"
                    :checked-value="option.value"
                    :name="option.value"
                  >
                    <img
                      v-if="option.value === 'FRAME'"
                      src="../../../../assets/img/icons/icon-bike.svg"
                    >
                    <img
                      v-if="option.value === 'OTHER'"
                      src="../../../../assets/img/icons/icon-parts.svg"
                    >
                    {{ $t(`account.account.myWarranties.${option.text}`) }}
                  </radio-button>
                </li>
              </ul>
            </div>

            <template v-if="warrantyData.type==='FRAME'">
              <div class="m-t-4">
                <SectionTitle>{{ $t('account.account.myWarranties.provideserialnumber') }}</SectionTitle>
                <div
                  class="row"
                >
                  <div
                    class="col-9"
                    :class="{invalid:$v.searchSerial.$invalid}"
                  >
                    <div class="form-group m-b-0">
                      <label>{{ $t('account.account.myWarranties.serialNumber') }}</label>
                      <input
                        v-model="searchSerial"
                        type="text"
                        @input="$v.searchSerial.$touch()"
                      >
                    </div>
                  </div>
                  <div class="col-3">
                    <button
                      class="m-t-2 button button--primary button--icon w-100"
                      @click.prevent="search"
                    >
                      <i class="uil uil-search" />
                      {{ $t('account.account.myWarranties.findSerial') }}
                    </button>
                  </div>
                </div>
              </div>
              <template v-if="checkMessage && inProgress">
                <Message
                  type="warning"
                  class="m-t-2"
                >
                  <strong>{{ $t('account.account.myWarranties.serialtitlerequired') }}</strong><br>
                  <small v-html="$t('account.account.myWarranties.serialrequired')" />
                </Message>
              </template>
              <template v-if="notValid && !inProgress && !isWarranty">
                <Message
                  type="warning"
                  class="m-t-2"
                >
                  <strong>{{ $t('account.account.myWarranties.invalidserialnumber') }}</strong><br>
                  <small>{{ $t('account.account.myWarranties.notvalid') }}</small>
                </Message>
              </template>
              <template v-if="!inProgress &&!isWarranty && !notValid && !alreadyExist">
                <Message
                  type="error"
                  class="m-t-2"
                >
                  <strong>{{ $t('account.account.myWarranties.serialnumberoutofwarranty') }}</strong><br>
                  <small>{{ $t('account.account.myWarranties.expired', { product: warrantyData.description}) }}</small>
                </Message>
              </template>
              <template v-if="alreadyExist">
                <Message
                  type="error"
                  class="m-t-2"
                >
                  <strong>{{ $t('account.account.myWarranties.alreadyExists.title') }}</strong><br>
                  <small v-html="$t('account.account.myWarranties.alreadyExists.message')" />
                </Message>
              </template>
              <template v-if="!notValid && !inProgress && isWarranty">
                <SectionTitle class="m-t-2">
                  {{ warrantyData.description }}
                </SectionTitle>
                <Message type="success">
                  <strong>{{ $t('account.account.myWarranties.serialgoodnews') }}</strong><br>
                  <small>{{ $t('account.account.myWarranties.requestedproduct', { product: warrantyData.description}) }}</small>
                </Message>
              </template>
            </template>
          </div>

          <template v-if="!inProgress || warrantyData.type==='OTHER'">
            <!-- Step 2 -->
            <div v-if="steps.active === 2">
              <div
                class="form-group m-t-2"
                :class="{'form-group--error': $v.warrantyData.reference.$error}"
              >
                <SectionTitle>{{ $t('account.account.myWarranties.provideinformation') }}</SectionTitle>
                <label>{{ $t('account.account.myWarranties.reference') }}</label>
                <input
                  v-model.trim="warrantyData.reference"
                  type="text"
                  class="form-control"
                  @input="$v.warrantyData.reference.$touch()"
                >
                <small
                  v-if="!$v.warrantyData.reference.required"
                  class="form-text--error"
                >
                  {{ $t("account.account.myWarranties.required") }}
                </small>
                <small
                  v-if="!$v.warrantyData.reference.maxlength"
                  class="form-text--error"
                >
                  {{ $t("account.account.myWarranties.maxchars") }}
                </small>
              </div>
              <div
                class="form-group"
                :class="{invalid:!$v.warrantyData.summary.required}"
              >
                <label>{{ $t('account.account.myWarranties.summary') }}</label>
                <textarea
                  v-model="warrantyData.summary"
                  rows="4"
                  cols="50"
                  @input="$v.warrantyData.summary.$touch()"
                />
                <small
                  v-if="!$v.warrantyData.summary.required"
                  class="form-text--error"
                >
                  {{ $t("account.account.myWarranties.required") }}
                </small>
                <small
                  v-if="!$v.warrantyData.summary.maxlength"
                  class="form-text--error"
                >
                  {{ $t("account.account.myWarranties.maxchars") }}
                </small>
              </div>

              <div
                class="form-group--list m-b-4"
                :class="{invalid:!$v.warrantyData.purchase.required}"
              >
                <SectionTitle>{{ $t('account.account.myWarranties.issueWhen') }}</SectionTitle>
                <ul>
                  <li>
                    <radio-button
                      v-model="warrantyData.purchase"
                      checked-value="before"
                      name="purchase"
                      @input="$v.warrantyData.purchase.$touch()"
                    >
                      {{ $t("account.account.myWarranties.before") }}
                    </radio-button>
                  </li>
                  <li>
                    <radio-button
                      v-model="warrantyData.purchase"
                      checked-value="after"
                      name="purchase1"
                      style="margin-top:2px"
                      @input="$v.warrantyData.purchase.$touch()"
                    >
                      {{ $t("account.account.myWarranties.after") }}
                    </radio-button>
                  </li>
                </ul>
                <small
                  v-if="!$v.warrantyData.purchase.required"
                  class="form-text--error"
                >
                  {{ $t("account.account.myWarranties.choice") }}
                </small>
                <div
                  v-if="warrantyData.purchase==='after'"
                  class="m-t-2"
                >
                  <label>{{ $t('account.account.myWarranties.purchaseEndUser') }}</label>
                  <datepicker
                    v-model="warrantyData.purchasedate"
                    placeholder=""
                    maximum-view="month"
                    @input="$v.warrantyData.purchasedate.$touch()"
                  />
                </div>
                <small
                  v-if="warrantyData.purchase==='after' && !$v.warrantyData.purchasedate.required"
                  class="form-text--error"
                >
                  {{ $t("account.account.myWarranties.required") }}
                </small>
              </div>
              <div
                class="form-group--list m-b-4"
                :class="{invalid:!$v.warrantyData.treatment.required}"
              >
                <SectionTitle>{{ $t('account.account.myWarranties.treatment') }}</SectionTitle>
                <ul>
                  <li>
                    <radio-button
                      v-model="warrantyData.treatment"
                      checked-value="replace"
                      name="treatment"
                      @input="$v.warrantyData.treatment.$touch()"
                    >
                      {{ $t('account.account.myWarranties.replace') }}
                    </radio-button>
                  </li>
                  <li>
                    <radio-button
                      v-model="warrantyData.treatment"
                      checked-value="repair"
                      name="treatment1"
                      style="margin-top:2px"
                      @input="$v.warrantyData.treatment.$touch()"
                    >
                      {{ $t('account.account.myWarranties.repair') }}
                    </radio-button>
                  </li>
                </ul>
                <small
                  v-if="!$v.warrantyData.treatment.required"
                  class="form-text--error"
                >
                  {{ $t("account.account.myWarranties.choice") }}
                </small>
              </div>
            </div>
            <div v-if="steps.active === 3">
              <div
                class="m-b-4"
              >
                <SectionTitle>
                  {{ $t('account.account.myWarranties.proofofpurchase.title') }}<i
                    class="uil uil-scenery"
                    style="font-size: 2.5rem; cursor: pointer;"
                    @click="openLightboxImage(invoice, $event)"
                  />
                </SectionTitle>
                <p v-if="!isSingleFileUploaded">
                  {{ $t('account.account.myWarranties.proofofpurchase.description') }}
                </p>
                <div
                  class="form-group"
                >
                  <simple-upload
                    ref="proofofpurchase"
                    v-model="warrantyData.proofofpurchase"
                    :cus-id="customerData.externalcustomerid"
                    :is-uploaded="isSingleFileUploaded"
                    :proof="warrantyData.proofofpurchase"
                    :multiple-upload-filenames="arrMultipleFiles"
                    @file-data="emitProofOfPurchaseFile"
                  />
                </div>
              </div>
              <span
                v-if="warrantyData.type==='FRAME'"
              >
                <div
                  v-if="!isSingleFileUploaded"
                  class="m-b-4"
                >
                  <SectionTitle>{{ $t('account.account.myWarranties.serienumber.title') }}<i
                    class="uil uil-scenery"
                    style="font-size: 2.5rem; cursor: pointer;"
                    @click="openLightboxImage(serialnumber, $event)"
                  /></SectionTitle>
                  <p>
                    {{ $t('account.account.myWarranties.serienumber.description') }}
                  </p>
                  <div
                    class="form-group"
                  >
                    <simple-upload
                      ref="serialProof"
                      :cus-id="customerData.externalcustomerid"
                      :is-uploaded="isSingleFileUploaded"
                      :proof="warrantyData.serialProof"
                      :multiple-upload-filenames="arrMultipleFiles"
                      @file-data="emitExtraWarrantyFiles"
                    />
                  </div>
                </div>
              </span>
              <div
                v-if="!isSingleFileUploaded"
                class="m-b-4"
              >
                <SectionTitle>
                  {{ $t('account.account.myWarranties.sideviewleft.title') }}<i
                    class="uil uil-scenery"
                    style="font-size: 2.5rem; cursor: pointer;"
                    @click="openLightboxImage(sideViewLeft, $event)"
                  />
                </SectionTitle>
                <p>
                  {{ $t('account.account.myWarranties.sideviewleft.description') }}
                </p>
                <div
                  class="form-group"
                >
                  <simple-upload
                    ref="sideviewLeft"
                    :cus-id="customerData.externalcustomerid"
                    :is-uploaded="isSingleFileUploaded"
                    :proof="warrantyData.sideviewLeft"
                    :multiple-upload-filenames="arrMultipleFiles"
                    @file-data="emitExtraWarrantyFiles"
                  />
                </div>
              </div>
              <div
                v-if="!isSingleFileUploaded"
                class="m-b-4"
              >
                <SectionTitle>
                  {{ $t('account.account.myWarranties.sideviewright.title') }}<i
                    class="uil uil-scenery"
                    style="font-size: 2.5rem; cursor: pointer;"
                    @click="openLightboxImage(sideViewRight, $event)"
                  />
                </SectionTitle>
                <p>
                  {{ $t('account.account.myWarranties.sideviewright.description') }}
                </p>
                <div
                  class="form-group"
                >
                  <simple-upload
                    ref="sideviewRight"
                    :cus-id="customerData.externalcustomerid"
                    :is-uploaded="isSingleFileUploaded"
                    :proof="warrantyData.sideviewRight"
                    :multiple-upload-filenames="arrMultipleFiles"
                    @file-data="emitExtraWarrantyFiles"
                  />
                </div>
              </div>
              <div class="m-b-4">
                <SectionTitle>
                  {{ $t('account.account.myWarranties.providedefect.title') }}<i
                    class="uil uil-scenery"
                    style="font-size: 2.5rem; cursor: pointer;"
                    @click="openLightboxImage(defect, $event)"
                  />
                </SectionTitle>
                <p v-if="!isMultiFileUploaded && !error">
                  {{ $t('account.account.myWarranties.providedefect.description') }}
                </p>
                <div
                  class="form-group"
                >
                  <multiple-uploads
                    ref="defect"
                    v-model="warrantyData.defect"
                    :cus-id="customerData.externalcustomerid"
                    :is-uploaded="isMultiFileUploaded"
                    :defect="warrantyData.defect"
                    :uuid="uuid"
                    :simple-upload-filename="simpleFileData.name"
                    @file-data="emitMultiFileData"
                    @setArrMultipleFiles="setArrMultipleFiles"
                  />
                </div>
                <div v-if="!isMultiFileUploaded && error">
                  <small class="form-text--error"> {{ $t('account.account.myWarranties.errorupload') }}</small>
                </div>
              </div>
              <div
                v-if="fileUploadInProgress"
                style="text-align: center;"
              >
                <loader />
                <small>loading...</small>
              </div>
            </div>

            <div v-if="steps.active === 4">
              <div class="form-group--list m-t-4">
                <SectionTitle>{{ $t('account.account.myWarranties.pickupAddress') }}</SectionTitle>
                <ul>
                  <li
                    v-for="location in locations"
                    :key="location.externalid"
                    class="list__item list__item__checkbox"
                  >
                    <radio-button
                      v-model="warrantyData.pickup"
                      name="pickupaddress"
                      :checked-value="location.externalid"
                    >
                      <span>
                        <strong>{{ location.Name }}</strong>
                        <small>
                          {{ `${location.street}, ${location.postalcode} ${location.city} (${$t(`countries.${location.country}`)})` }}
                        </small>
                      </span>
                    </radio-button>
                  </li>
                  <li>
                    <radio-button
                      v-model="warrantyData.pickup"
                      checked-value="OTHER"
                      name="pickupaddress"
                      style="margin-top:2px"
                    >
                      {{ $t("account.account.myWarranties.otheraddress") }}
                    </radio-button>
                  </li>
                </ul>

                <div
                  v-if="warrantyData.pickup==='OTHER'"
                  class="m-l-4"
                >
                  <div
                    class="form-group"
                    :class="{invalid:!$v.warrantyData.pickupname.required }"
                  >
                    <label>{{ $t('account.account.myWarranties.pickupname') }}</label>
                    <input
                      v-model="warrantyData.pickupname"
                      type="text"
                      class="form-control"
                      :maxlength="maxRef"
                      @change="$v.warrantyData.pickupname.$touch"
                    >
                    <small
                      v-if="warrantyData.pickupname && warrantyData.pickupname.length === maxRef"
                      class="form-text--warning"
                    >
                      {{ $t("account.account.myWarranties.maxchars") }}
                    </small>
                  </div>
                  <div
                    class="form-group"
                    :class="{invalid:!$v.warrantyData.street.required }"
                  >
                    <label>{{ $t('account.account.myWarranties.street') }}</label>
                    <input
                      v-model="warrantyData.street"
                      type="text"
                      class="form-control"
                      :maxlength="maxRef"
                      @change="$v.warrantyData.street.$touch"
                    >
                    <small
                      v-if="warrantyData.street && warrantyData.street.length === maxRef"
                      class="form-text--warning"
                    >
                      {{ $t("account.account.myWarranties.maxchars") }}
                    </small>
                  </div>

                  <div class="row">
                    <div class="col-3">
                      <div
                        class="form-group"
                        :class="{invalid:!$v.warrantyData.postalcode.required }"
                      >
                        <label>{{ $t('account.account.myWarranties.postalcode') }}</label>
                        <input
                          v-model="warrantyData.postalcode"
                          type="text"
                          class="form-control"
                          :maxlength="maxRef"
                          @change="$v.warrantyData.postalcode.$touch"
                        >
                        <small
                          v-if="warrantyData.postalcode && warrantyData.postalcode.length === maxRef"
                          class="form-text--warning"
                        >
                          {{ $t("account.account.myWarranties.maxchars") }}
                        </small>
                      </div>
                    </div>
                    <div class="col-9">
                      <div
                        class="form-group"
                        :class="{invalid:!$v.warrantyData.city.required }"
                      >
                        <label>{{ $t('account.account.myWarranties.city') }}</label>
                        <input
                          v-model="warrantyData.city"
                          type="text"
                          class="form-control"
                          :maxlength="maxRef"
                          @change="$v.warrantyData.city.$touch"
                        >
                        <small
                          v-if="warrantyData.city && warrantyData.city.length === maxRef"
                          class="form-text--warning"
                        >
                          {{ $t("account.account.myWarranties.maxchars") }}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div
                    class="form-group"
                    :class="{invalid:!$v.warrantyData.country.required }"
                  >
                    <label>{{ $t('account.account.myWarranties.country') }}</label>
                    <input
                      v-model="warrantyData.country"
                      type="text"
                      class="form-control"
                      :maxlength="maxRef"
                      @change="$v.warrantyData.country.$touch"
                    >
                    <small
                      v-if="warrantyData.country && warrantyData.country.length === maxRef"
                      class="form-text--warning"
                    >
                      {{ $t("account.account.myWarranties.maxchars") }}
                    </small>
                  </div>
                </div>
              </div>

              <!-- ACCOUNT INFORMATION -->
              <SectionTitle> {{ $t("account.account.myWarranties.accountinformation") }}</SectionTitle>
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label>{{ $t('account.account.myWarranties.accountNumber') }}</label>
                    <input
                      v-model="customerData.externalcustomerid"
                      type="text"
                      :disabled="true"
                    >
                  </div>
                </div>
                <div class="col-8">
                  <div
                    class="form-group"
                    :class="{invalid:!$v.customerData.phonenumber.required}"
                  >
                    <label>{{ $t('account.account.myWarranties.phone') }}</label>
                    <input
                      v-model="customerData.phonenumber"
                      type="text"
                      @input="$v.customerData.phonenumber.$touch()"
                    >
                  </div>
                </div>
              </div>
              <div
                class="form-group"
                :class="{invalid:!$v.customerData.email.required || $v.customerData.email.$error}"
              >
                <label>{{ $t('account.account.myWarranties.email') }}</label>
                <input
                  v-model="customerData.email"
                  type="text"
                  @input="$v.customerData.email.$touch()"
                >
                <small
                  v-if="$v.customerData.email.$error"
                  class="form-text--error"
                >{{ $t('account.account.myWarranties.novalidemail') }}</small>
              </div>
            </div>
          </template>
        </loading-overlay>
        <ItemImageViewer
          ref="lightbox"
          :item="lightboxImage"
          :editable="false"
          @imageselected="onImageSelected($event)"
        />
      </bcf-aside-content>
      <bcf-aside-footer>
        <!-- Prev step button -->
        <span
          v-if="steps.active > 1"
          class="button button--primary"
          :class="isMobile ? 'mobile-position-buttons' : null"
          :disabled="fileUploadInProgress"
          @click="prevStep()"
        >
          <i class="uil uil-arrow-left" />
          {{ $t('account.account.myWarranties.previousstep') }}
        </span>
        <!-- Cancel button -->
        <button
          v-if="steps.active === 1"
          class="button button--outline"
          :class="isMobile ? 'mobile-position-buttons' : null"
          :disabled="inProgress"
          @click="close()"
        >
          {{ $t('account.account.myWarranties.cancel') }}
        </button>

        <!-- Next step button -->
        <span
          v-if="steps.total !== steps.active"
          class="button button--primary"
          :class="isMobile ? 'mobile-position-buttons' : null"
          :disabled="fileUploadInProgress"
          @click="nextStep()"
        >
          {{ $t('account.account.myWarranties.nextstep') }} <i class="uil uil-arrow-right" />
        </span>
        <!-- Submit button -->
        <!-- :class="{'button--loading': $v.$invalid}" -->
        <button
          v-if="steps.total === steps.active && !fileUploadInProgress"
          class="button button--primary mr-2"
          :class="isMobile ? 'mobile-position-buttons' : null"
          type="submit"
          :disabled="submitPressed && !fileUploadInProgress"
          @click.prevent="submitForm"
        >
          {{ $t('account.account.myWarranties.send') }}
        </button>
      </bcf-aside-footer>
    </bcf-aside>
  </form>
</template>
<script>
import { mapState } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import {
  required,
  maxLength,
  email,
  requiredUnless,
  requiredIf,
} from 'vuelidate/lib/validators';
import loader from '@/elements/loader.vue';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import Message from '@/components/Message.vue';
import loadingOverlay from '@/elements/LoadingOverlay.vue';
import radioButton from '@/elements/radioButton.vue';
import simpleUpload from '@/elements/SimpleUpload.vue';
import multipleUploads from '@/elements/MultipleUploads.vue';
import validWarranty from '@/utils/warrantyUtils';
import { apiInstance } from '@/api/api';
import { GetWarrantyinfoBySerialnumber, saveWarranty, deleteFolder } from '@/api/warranty';
import { v4 as uuidv4 } from 'uuid';
import ItemImageViewer from '@/components/item/itemImageViewer.vue';

export default {
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    bcfAsideFooter,
    Message,
    SectionTitle,
    loadingOverlay,
    Datepicker,
    radioButton,
    simpleUpload,
    multipleUploads,
    loader,
    ItemImageViewer,
  },
  props: {
    serial: {
      type: String,
      default: null,
    },
    useCloseEvent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMobile: window.innerWidth < 992,
      serialnumber: {
        description: this.$t('account.account.myWarranties.serienumber.lightbox'),
        image: 'https://bcfwarranties-prod.s3.eu-west-1.amazonaws.com/serialnumberexample.png',
      },
      invoice: {
        description: this.$t('account.account.myWarranties.proofofpurchase.lightbox'),
        image: 'https://bcfwarranties-prod.s3.eu-west-1.amazonaws.com/invoiceexample.png',
      },
      sideViewLeft: {
        description: this.$t('account.account.myWarranties.sideviewleft.lightbox'),
        image: 'https://bcfwarranties-prod.s3.eu-west-1.amazonaws.com/sideviewleft.jpg',
      },
      sideViewRight: {
        description: this.$t('account.account.myWarranties.sideviewright.lightbox'),
        image: 'https://bcfwarranties-prod.s3.eu-west-1.amazonaws.com/sideviewright.jpg',
      },
      defect: {
        description: this.$t('account.account.myWarranties.providedefect.lightbox'),
        image: 'https://bcfwarranties-prod.s3.eu-west-1.amazonaws.com/defect.png',
      },
      areMandatoryFieldsFilled: false,
      error: false,
      singleUploadError: false,
      multiUploadError: false,
      singleUploadMessage: false,
      isSingleFileUploaded: false,
      isMultiFileUploaded: false,
      inProgress: true,
      fileUploadInProgress: false,
      checkMessage: false,
      simpleFileData: {},
      simpleFileDataExtra: {},
      multiFileData: [],
      arrMultipleFiles: [],
      steps: {
        total: 4,
        active: 1,
      },
      optiontypes: [
        { text: 'bikesframes', value: 'FRAME' },
        { text: 'accessoires', value: 'OTHER' },
      ],
      searchSerial: '',
      customerData: {
        customerid: 0,
        externalcustomerid: '',
        phonenumber: '',
        email: '',
      },
      warrantyData: {
        reference: '',
        type: 'FRAME',
        summary: '',
        purchase: '',
        treatment: '',
        purchasedate: '',
        pickup: null,
        street: '',
        postalcode: '',
        city: '',
        country: '',
        proofofpurchase: '',
        serialProof: '',
        sideviewLeft: '',
        sideviewRight: '',
        defect: [],
        s3folder: '',
        createdBy: '',
        userName: '',
      },
      errorMessage: '',
      hasReference: false,
      isWarranty: false,
      notValid: false,
      alreadyExist: false,
      maxRef: 50,
      maxSum: 200,
      uuid: '',
      searchPressed: false,
      submitPressed: false,
      lightboxImage: null,
    };
  },
  computed: {
    ...mapState({
      accountnr: (state) => state.customer.selectedCustomer.externalid,
      defaultCustomerInformation: (state) => (state.customer.selectedCustomer.addresses.find((element) => element.default === 1) ? state.customer.selectedCustomer.addresses.find((element) => element.default === 1) : state.customer.selectedCustomer.addresses[0]),
      locations: (state) => state.customer.selectedCustomer.addresses,
    }),
  },
  watch: {
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  created() {
    this.uuid = uuidv4();
    if (this.serial) {
      this.searchSerial = this.serial;
      this.search();
    }

    this.customerData.externalcustomerid = this.accountnr;
    this.customerData.customerid = this.$store.state.customer.selectedCustomerId;
    this.customerData.phonenumber = this.defaultCustomerInformation.phonenr;
    this.customerData.email = this.defaultCustomerInformation.email.trim();
    this.warrantyData.pickup = this.defaultCustomerInformation.externalid;
  },
  validations: {
    searchSerial: {
      required: requiredUnless((vm) => vm.warrantyData.type === 'OTHER'),
    },
    customerData: {
      phonenumber: {
        required,
      },
      email: {
        required,
        email,
      },
    },
    warrantyData: {
      reference: {
        required,
        maxlength: maxLength(50),
      },
      summary: {
        required,
        maxlength: maxLength(250),
      },
      purchase: {
        required,
      },
      purchasedate: {
        required: requiredUnless((vm) => vm.purchase === 'before'),
      },
      treatment: {
        required,
      },
      pickupname: {
        required: requiredIf((vm) => vm.pickup === 'OTHER'),
      },
      street: {
        required: requiredIf((vm) => vm.pickup === 'OTHER'),
      },
      postalcode: {
        required: requiredIf((vm) => vm.pickup === 'OTHER'),
      },
      city: {
        required: requiredIf((vm) => vm.pickup === 'OTHER'),
      },
      country: {
        required: requiredIf((vm) => vm.pickup === 'OTHER'),
      },
      proofofpurchase: {
        required,
      },
      defect: {
        required,
      },
    },
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 992;
    },
    openLightboxImage(example, event) {
      if (event) {
        if (event.preventDefault) event.preventDefault();
        if (event.stopPropagation) event.stopPropagation();
      }
      this.lightboxImage = example;
      this.$refs.lightbox.show();
    },
    closeMessageMandatoryFields() {
      this.areMandatoryFieldsFilled = true;
    },
    validateFormData() {
      if (Object.keys(this.$refs).length === 0) {
        return true;
      }
      if (this.steps.active === 2) {
        if (this.$v.warrantyData.reference.$invalid) {
          this.areMandatoryFieldsFilled = false;
          return true;
        }
        if (this.$v.warrantyData.summary.$invalid) {
          this.areMandatoryFieldsFilled = false;
          return true;
        }
        if (this.$v.warrantyData.treatment.$invalid) {
          this.areMandatoryFieldsFilled = false;
          return true;
        }
        if (this.$v.warrantyData.purchase.$invalid) {
          this.areMandatoryFieldsFilled = false;
          return true;
        }
      }
      // customer data
      if (this.steps.active === 3) {
        // Checks uitgezet klantenkaart data correct invullen is rocket science voor CS
        /*         if (this.$v.customerData.$invalid) {
          this.areMandatoryFieldsFilled = false;
          return true;
        } */
        if (!this.$v.warrantyData.city.required) {
          this.areMandatoryFieldsFilled = false;
          return true;
        }
        if (!this.$v.warrantyData.country.required) {
          this.areMandatoryFieldsFilled = false;
          this.toTop();
          return true;
        }
        if (!this.$v.warrantyData.postalcode.required) {
          this.areMandatoryFieldsFilled = false;
          this.toTop();
          return true;
        }
        if (!this.$v.warrantyData.street.required) {
          this.areMandatoryFieldsFilled = false;
          this.toTop();
          return true;
        }
        if (!this.$v.warrantyData.treatment.required) {
          this.areMandatoryFieldsFilled = false;
          this.toTop();
          return true;
        }
        if (!this.$v.warrantyData.summary.required) {
          this.areMandatoryFieldsFilled = false;
          this.toTop();
          return true;
        }
        if (!this.$v.warrantyData.reference.required) {
          this.areMandatoryFieldsFilled = false;
          this.toTop();
          return true;
        }
        if (!this.$v.warrantyData.purchasedate.required) {
          this.areMandatoryFieldsFilled = false;
          this.toTop();
          return true;
        }
        if (!this.$v.warrantyData.pickupname.required) {
          this.areMandatoryFieldsFilled = false;
          this.scrollToTop();
          return true;
        }
        // file uploads(docs or pics)
        if (!this.$refs.proofofpurchase.$v.file.required) {
          this.areMandatoryFieldsFilled = false;
          this.scrollToTop();
          return true;
        }
        if (this.warrantyData.type === 'FRAME') {
          if (!this.$refs.serialProof.$v.file.required) {
            this.areMandatoryFieldsFilled = false;
            this.scrollToTop();
            return true;
          }
        }
        if (!this.$refs.sideviewLeft.$v.file.required) {
          this.areMandatoryFieldsFilled = false;
          this.scrollToTop();
          return true;
        }
        if (!this.$refs.sideviewRight.$v.file.required) {
          this.areMandatoryFieldsFilled = false;
          this.scrollToTop();
          return true;
        }
        if (!this.$refs.defect.$v.files.required) {
          this.areMandatoryFieldsFilled = false;
          this.scrollToTop();
          return true;
        }
      }
      this.areMandatoryFieldsFilled = true;
      return false;
    },
    scrollToTop() {
      this.$refs.top.$el.scrollTop = 0;
    },
    setArrMultipleFiles(arFiles) {
      this.arrMultipleFiles = arFiles;
    },
    validWarranty,
    async nextStep() {
      if (this.alreadyExist) {
        alert(this.$t('account.account.myWarranties.proceedWarranty'));
        return;
      }
      if ((this.steps.active === 1 && this.warrantyData.type === 'FRAME'
          && this.searchSerial && !this.notValid && this.searchPressed) || (this.steps.active === 1 && this.warrantyData.type === 'OTHER')) {
        this.steps.active += 1;
      } else {
        this.checkMessage = true;
      }

      if (this.steps.active === 2) {
        if (this.$v.warrantyData.reference.$dirty || this.$v.warrantyData.summary.$dirty || !this.$v.warrantyData.treatment.$invalid || !this.$v.warrantyData.purchase.$invalid) {
          if (!this.validateFormData()) {
            this.areMandatoryFieldsFilled = false;
            this.steps.active += 1;
          } else {
            this.areMandatoryFieldsFilled = false;
          }
        }
        // Just to mage sure the message mandatory fields is shown when you klik Next again. touch one field(vuelidate way of making it dirty)
        this.$v.warrantyData.reference.$touch();
      }
      if (this.steps.active === 3) {
        if (this.$v.warrantyData.proofofpurchase.$dirty && this.$v.warrantyData.defect.$dirty) {
          this.steps.active += 1;
        } else if (!this.validateFormData() && this.simpleFileData.uploadType === 'single' && this.multiFileData.uploadType === 'multiple') {
          this.fileUploadInProgress = true;
          if (!this.singleUploadError && !this.multiUploadError) {
            await this.uploadSingleFile();
            await this.uploadMultipleFiles();
            if (!this.error) {
              this.steps.active += 1;
            }
          }
        }
        this.fileUploadInProgress = false;
      }
    },
    prevStep() {
      this.areMandatoryFieldsFilled = true;
      this.steps.active -= 1;
    },
    emitProofOfPurchaseFile(type, fileData) {
      this.simpleFileData = fileData;
      this.simpleFileData.uploadType = type;
    },
    emitExtraWarrantyFiles(type, fileData) {
      this.simpleFileDataExtra = fileData;
      this.simpleFileDataExtra.uploadType = type;
      this.multiFileData.push(this.simpleFileDataExtra);
    },
    emitMultiFileData(type, fileData) {
      this.multiUploadError = false;
      // this.multiFileData = [];
      this.multiFileData = this.multiFileData.concat(fileData);
      this.multiFileData.uploadType = type;

      this.multiFileData.forEach((e) => {
        if (e.invalidMessage) {
          this.multiUploadError = true;
        }
      });
      this.multiFileData.uploadType = type;
    },
    addDataToObject(type, fileData) {
      if (type === 'single') {
        this.warrantyData.proofofpurchase = fileData.key ? fileData.key.substring(fileData.key.indexOf('/') + 1) : '';
        this.$v.warrantyData.proofofpurchase.$touch();
      } else {
        this.warrantyData.defect = fileData.map((file) => file.name);
        this.$v.warrantyData.defect.$touch();
      }
      this.warrantyData.s3folder = this.uuid;
    },
    async submitForm() {
      this.submitPressed = true;
      Object.assign(this.warrantyData, this.customerData);
      if (this.warrantyData.pickup !== 'OTHER') {
        const location = this.locations.find((x) => x.externalid === this.warrantyData.pickup);
        this.warrantyData.pickupname = location.Name;
        this.warrantyData.street = location.street;
        this.warrantyData.postalcode = location.postalcode;
        this.warrantyData.city = location.city;
        this.warrantyData.country = location.country;
      }
      this.warrantyData.createdBy = this.$store.state.auth.user.email;
      this.warrantyData.userName = this.$store.state.auth.user.username;

      await saveWarranty(this.warrantyData);
      await this.$store.dispatch('myWarranties/get');
      this.$router.push({ name: 'myWarranties' });
    },
    async search() {
      try {
        this.inProgress = true;
        this.isWarranty = false;
        this.notValid = false;
        this.alreadyExist = false;
        this.resetWarrantyData();

        const result = await GetWarrantyinfoBySerialnumber(this.searchSerial);
        if (result.data.serialnumber) {
          Object.assign(this.warrantyData, result.data);

          this.isWarranty = !!validWarranty(this.warrantyData.invoicedate);
          this.searchPressed = true;
        } else {
          this.notValid = true;
        }
        this.inProgress = false;
      } catch (error) {
        if (error.status === 422) {
          this.alreadyExist = true;
          this.inProgress = false;
          return;
        }
        this.inProgress = false;
        this.errorMessage = error.message;
      }
    },
    resetWarrantyData() {
      this.warrantyData = {
        reference: '',
        type: 'FRAME',
        summary: '',
        purchase: '',
        treatment: '',
        purchasedate: '',
        pickup: this.defaultCustomerInformation.externalid,
        pickupname: '',
        street: '',
        postalcode: '',
        city: '',
        country: '',
        proofofpurchase: '',
        defect: [],
        s3folder: '',
      };
    },
    async close(event) {
      const r = window.confirm(this.$t('account.account.myWarranties.closealert'));
      if (!r) {
        event.stop();
      }
      if (this.warrantyData.s3folder) {
        await deleteFolder(this.warrantyData.s3folder);
      }
      if (this.useCloseEvent) {
        this.$emit('close');
      } else {
        this.$router.push(this.$store.state.global.previousRoute ? this.$store.state.global.previousRoute : { name: 'myOrders' });
      }
    },
    async uploadSingleFile() {
      const formData = new FormData();
      formData.append('file', this.simpleFileData);
      formData.append('uuid', this.uuid);

      try {
        const result = await apiInstance.post('/v1/warranty/upload', formData);
        if (result.data.code === 'LIMIT_FILE_TYPES'
          || result.data.code === 'LIMIT_FILE_SIZE') {
          this.singleUploadMessage = result.data.code;
          this.singleUploadError = true;
        } else {
          this.singleUploadMessage = 'UPLOAD_SUCCESSFUL';
          // this.response = result;
          this.singleUploadError = false;
          this.isSingleFileUploaded = true;
          this.addDataToObject('single', result.data);
        }
      } catch (error) {
        this.simpleFileData = '';
        this.singleUploadError = true;
        this.isSingleFileUploaded = false;
      }
    },
    async uploadMultipleFiles() {
      // TODO: refactor code toupload.js
      let result = [];
      const formData = new FormData();
      this.multiFileData.forEach((file) => {
        formData.append('file', file);
      });
      formData.append('uuid', this.uuid);
      try {
        result = await apiInstance.post('/v1/warranty/multiple', formData);
        // backend check files
        if (result.data.code === 'LIMIT_FILE_TYPES'
          || result.data.code === 'LIMIT_FILE_SIZE') {
          this.message = result.data.code;
          this.error = true;
        } else if (result.data.length === 0) {
          this.message = 'UPLOAD_FAILED';
          this.error = true;
        } else {
          this.message = 'UPLOAD_SUCCESSFUL';
          this.error = false;
          this.isMultiFileUploaded = true;
          this.addDataToObject('multiple', this.multiFileData);
        }
      } catch (error) {
        this.multiFileData = [];
        // this.message = result.data.error;
        this.fileUploadInProgress = false;
        this.multiUploadError = true;
        this.isMultiFileUploaded = false;
        this.error = true;
      }
    },
  },
};
</script>
<style scoped>
.mobile-position-buttons {
  margin-bottom: 65px;
}
</style>
