<template>
  <div>
    <router-view />
    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <span class="main__title__action--left">
                <router-link :to="{name: 'account'}">
                  <i class="uil uil-arrow-left" />
                </router-link>
              </span>
              <h1>{{ $t('account.settings.vouchers.title') }}</h1>
              <router-link :to="'/account/vouchers/new'">
                <i class="uil uil-plus-circle" />
              </router-link>

              <!--               <router-link
                :to="'/account/vouchers/new'"
                class="button button--secondary d-md-none"
              >
                {{ $t('account.settings.vouchers.create') }}
              </router-link> -->
            </div>
          </div>
        </div>
      </header>
      <div
        v-if="vouchersResult"
        class="container"
      >
        <search
          v-model="searchVal"
          class="m-md-t-2"
        />
        <span
          class="m-l-7"
          style="font-size:small"
        >
          <label v-if="toggleActive">
            <a
              href="#"
              style="color:red;"
              @click="filter()"
            >Show all vouchers</a>
          </label>
          <label v-else>
            <a
              href="#"
              style="color:red;"
              @click="filter()"
            >Show only active vouchers</a>
          </label>
        </span>
        <EmptyState
          v-if="vouchersResult.length === 0 && !checkInProgress"
          icon="invoice"
        >
          No vouchers
        </EmptyState>
        <div v-if="checkInProgress">
          <loader class="m-b-2" />
        </div>
        <router-link
          v-for="voucher in vouchersToDisplay"
          :key="voucher.id"
          :to="{name: 'accountVouchersView', params: {voucher: voucher}}"
          style="text-decoration: none;"
        >
          <ListItemVoucher :v="voucher" />
        </router-link>
        <div>
          <pagination
            class="m-t-4 m-b-4"
            :page="page"
            :pages="pages"
            :items-per-page="itemsPerPage"
            @pageChanged="changePage"
            @itemsPerPageChanged="itemsPerPageChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetVouchers } from '@/api/api';
import loader from '@/elements/loader.vue';
import ListItemVoucher from '@/elements/ListItemVoucher.vue';
import pagination from '@/components/Pagination.vue';
import search from '@/components/Search.vue';
import EmptyState from '@/elements/EmptyState.vue';

export default {
  name: 'Vouchers',
  components: {
    ListItemVoucher,
    loader,
    pagination,
    search,
    EmptyState,
  },
  data() {
    return {
      inProgress: false,
      vouchersResult: [],
      allVouchersResult: [],
      toggleActive: 1,
      itemsPerPage: 5,
      page: 1,
      searchQuery: this.$route.query.search || '',
    };
  },
  computed: {
    checkInProgress() {
      return this.inProgress;
    },
    searchVal: {
      get() {
        return this.$route.query.search;
      },
      set(newVal) {
        this.debouncedSetSearch(newVal);
      },
    },
    vouchersToDisplay() {
      return this.vouchersResult.slice(
        this.page * this.itemsPerPage - this.itemsPerPage,
        this.page * this.itemsPerPage,
      );
    },
    vouchers() {
      return this.fetchVouchers();
    },
    pages() {
      const pages = [];
      for (
        let i = 0;
        i < this.vouchersResult.length / this.itemsPerPage;
        i += 1
      ) {
        pages.push(i + 1);
      }
      return pages;
    },
  },
  watch: {
    filters: {
      handler() { this.search(); },
      deep: true,
    },
    $route: {
      async handler() {
        if (this.$route.query.refresh) {
          await this.fetchVouchers();
        }
      },
    },
  },
  async mounted() {
    await this.fetchVouchers();
    this.search();
  },
  created() {
    this.debouncedSetSearch = this.debounce(this.setSearch, 300); // Adjust the debounce delay as needed
  },
  methods: {
    debounce(func, wait) {
      let timeout;
      return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    },
    setSearch(newVal) {
      this.$router.push({
        name: this.$router.currentRoute.name,
        query: {
          ...this.$route.query,
          search: newVal,
        },
      });
      this.page = 1;
      this.search();
    },
    changePage(page) {
      this.page = parseInt(page, 10);
      this.vouchersResult.slice(
        this.page * this.itemsPerPage - this.itemsPerPage,
        this.page * this.itemsPerPage,
      );
    },
    itemsPerPageChanged(itemsPerPage) {
      this.itemsPerPage = parseInt(itemsPerPage, 10);
      this.changePage(1);
    },
    filter() {
      if (this.toggleActive === 1) {
        this.toggleActive = 0;
      } else {
        this.toggleActive = 1;
      }
      this.fetchVouchers();
    },
    async fetchVouchers() {
      this.inProgress = true;
      this.vouchersResult = await GetVouchers(this.toggleActive);
      this.allVouchersResult = this.vouchersResult;
      this.inProgress = false;
    },
    search() {
      this.page = 1;
      const allVouchers = this.allVouchersResult;
      // allInvoices = this.listMyInvoices;
      if (this.$route.query.search) {
        this.inProgress = true;
        this.vouchersResult = [];
        setTimeout(() => {
          this.$search(this.$route.query.search, allVouchers, {
            keys: ['code'],
            defaultAll: true,
            caseSensitive: false,
            shouldSort: true,
            tokenize: true,
            matchAllTokens: true,
            findAllMatches: false,
            threshold: 0.2,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
          }).then((results) => {
            this.vouchersResult = results;
            this.inProgress = false;
          });
        }, 300);
      } else {
        this.inProgress = true;
        this.vouchersResult = [];
        setTimeout(() => {
          this.vouchersResult = allVouchers;
          this.inProgress = false;
        }, 300);
        // this.vouchersResult = allVouchers;
      }
    },
  },
};
</script>
