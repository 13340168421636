<template>
  <input
    ref="input"
    class="time-input"
    placeholder="hh:mm"
    :value="value"
  >
</template>

<script>

import Cleave from 'cleave.js';

export default {
  name: 'TimeInput',
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      cleave: null,
    };
  },
  mounted() {
    const { input } = this.$refs;
    this.cleave = new Cleave(input, {
      time: true,
      timePattern: ['h', 'm'],
      onValueChanged: this.onInput.bind(this),
    });
  },
  methods: {
    onInput(ev) {
      // NextTick to leave cleave some time to properly format it
      this.$nextTick(() => {
        this.$emit('input', ev.target.value);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.time-input {
  width: 5.7em;

  &::placeholder {
    color: #c0c5cd;
  }

  &[disabled]::placeholder {
    color: #ebedf1;
  }
}
</style>
