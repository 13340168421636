<template>
  <bcf-aside @close="close">
    <bcfAsideHeader :close="close">
      {{ $t('account.about.releaseNotes.title') }}
    </bcfAsideHeader>
    <bcfAsideContent>
      <div
        v-for="version in releaseNotes"
        :key="version.version"
      >
        <SectionTitle>
          {{ $t('account.about.releaseNotes.version') }} {{ version.version }}
        </SectionTitle>

        <div class="release">
          <ul>
            <li
              v-for="note in version.notes"
              :key="note.message"
            >
              <Badge
                v-if="note.type === 'FIX'"
                type="fix"
              >
                BUGFIX
              </Badge>
              <Badge
                v-if="note.type === 'NEW'"
                type="new"
              >
                NEW
              </Badge>
              {{ note.message }}
            </li>
          </ul>
          <time>{{ formatDate(version.date) }}</time>
        </div>
      </div>
    </bcfAsideContent>
  </bcf-aside>
</template>

<style scoped>
</style>

<script>
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import Badge from '@/elements/Badge.vue';
import { formatDate } from '@/utils/dateUtils';
import * as releaseNotes from '../../../../releaseNotes.json';

export default {
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    Badge,
    SectionTitle,
  },
  data() {
    return {
      releaseNotes: releaseNotes.default,
    };
  },
  methods: {
    formatDate,
    close() {
      this.$router.push({ name: 'account' });
    },
  },
};
</script>
