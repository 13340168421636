<template>
  <form @submit.prevent="save">
    <bcf-aside @close="close">
      <bcfAsideHeader :close="close">
        {{ $t("account.settings.edit.title") }}
      </bcfAsideHeader>
      <bcfAsideContent>
        <div v-if="!inProgress && errorMessage">
          <Message
            class="container m-t-2"
            type="error"
            :icon="true"
            :content="$t(`api.${errorMessage}`)"
            :show-close="false"
          />
        </div>
        <loading-overlay :loading="inProgress">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label>{{ $t("account.settings.edit.firstName") }}</label>
                <input
                  v-model="user.firstName"
                  class="form-control"
                  type="text"
                >
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label>{{ $t("account.settings.edit.lastName") }}</label>
                <input
                  v-model="user.lastName"
                  class="form-control"
                  type="text"
                >
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>{{ $t("account.settings.edit.email") }}</label>
            <input
              v-model="user.email"
              class="form-control"
              type="text"
            >
          </div>

          <div class="form-group">
            <label>{{ $t("account.settings.edit.login") }}</label>
            <input
              v-model="user.username"
              class="form-control"
              type="text"
              disabled
            >
          </div>

          <div class="form-group">
            <label>{{ $t('account.settings.edit.languageChooser') }}</label>
            <language-changer />
          </div>
        </loading-overlay>
      </bcfAsideContent>
      <bcfAsideFooter>
        <button
          class="button button--outline"
          :disabled="inProgress"
          @click="close"
        >
          Cancel
        </button>

        <button
          class="button button--primary"
          type="submit"
          :class="{'button--loading': inProgress}"
          :disabled="inProgress"
        >
          {{ $t("account.settings.edit.save") }}
        </button>
      </bcfAsideFooter>
    </bcf-aside>
  </form>
</template>

<style scoped></style>

<script>
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import { userUpdate } from '@/api/user';
import languageChanger from '@/components/languageChanger.vue';
import loadingOverlay from '@/elements/LoadingOverlay.vue';
import Message from '@/components/Message.vue';

export default {
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideFooter,
    bcfAsideContent,
    languageChanger,
    loadingOverlay,
    Message,
  },
  data() {
    return {
      user: {},
      inProgress: false,
      errorMessage: '',
    };
  },
  created() {
    this.user = JSON.parse(JSON.stringify(this.$store.state.auth.user));
  },
  methods: {
    async save() {
      try {
        this.inProgress = true;
        this.user.lang = this.$store.state.language.current;
        await userUpdate(this.user.id, this.user);
        this.$store.dispatch('auth/updateUser', { user: this.user });
        this.$router.push({ name: 'account' });
        this.inProgress = false;
      } catch (err) {
        this.inProgress = false;
        this.errorMessage = err.message;
      }
    },
    close() {
      this.$router.push({ name: 'account' });
    },
  },
};
</script>
