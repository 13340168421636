<template>
  <div>
    <router-view />

    <div class="m-t-4 m-b-2 m-md-t-0 m-md-b-4">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <a
                @click="back"
              >
                <i class="uil uil-arrow-left" />
              </a>
              <h1>{{ $t('account.about.newsAndUpdates.title') }}</h1>
            </div>
          </div>
        </div>
      </header>
    </div>

    <div v-if="!loaded">
      <loader class="m-b-2" />
    </div>
    <div v-if="loaded && errorMessage">
      <Message
        class="container m-t-2"
        type="error"
        :icon="true"
        :content="$t(`api.${errorMessage}`)"
        :show-close="true"
        @closeMessage="closeMessage"
      />
    </div>
    <div
      v-if="(newsAndMediaFeeds.length === 0) && (loaded) && (!errorMessage)"
      class="container"
    >
      <div class="row">
        <div class="col">
          <EmptyState icon="award">
            {{ $t('account.about.newsAndUpdates.nonews') }}
          </EmptyState>
        </div>
      </div>
    </div>

    <div
      v-else-if="(newsAndMediaFeeds.length > 0) && (loaded)"
      class="container"
    >
      <div class="row">
        <div class="col m-b-2">
          <search v-model="searchVal" />
        </div>
      </div>

      <div
        v-for="(feeds, index) in newsAndMediaFeeds"
        :key="index"
      >
        <div class="row">
          <div
            v-for="feed in feeds"
            :key="feed.id"
            class="col-md-4 card-promo"
          >
            <MediaTeaser
              :id="feed.id"
              :url="`/account/newsAndUpdates/${feed.id}`"
              :date="feed.date_online"
              :title="feed.title"
              :summary="feed.summary_text"
              :image="feed.images && feed.images.image && feed.images.image.thumb?feed.images.image.thumb:placeholder"
              :logo="require('@/assets/img/icons/Facebook_logo.svg')"
              :social="feed.social"
              :type="feed.type"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import EmptyState from '@/elements/EmptyState.vue';
import { getMediafeed } from '@/api/api';
import MediaTeaser from '@/components/MediaTeaser.vue';
import loader from '@/elements/loader.vue';
import search from '@/components/Search.vue';
import Message from '@/components/Message.vue';
import placeholder from '@/assets/img/product_placeholder.jpg';

export default {
  components: {
    EmptyState,
    MediaTeaser,
    loader,
    search,
    Message,
  },
  data() {
    return {
      newsAndMediaFeeds: [],
      loaded: false,
      errorMessage: '',
      placeholder,
    };
  },
  computed: {
    ...mapState({
      selectedCustomer: (state) => state.customer.selectedCustomer,
    }),
    searchVal: {
      get() {
        return this.$route.query.search;
      },
      set(newVal) {
        this.$router.push({
          name: this.$router.currentRoute.name,
          query: {
            ...this.$route.query,
            search: newVal,
          },
        });
        this.fetchData();
      },
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    chunk(array, size) {
      const chunkedArr = [];
      const copied = [...array];
      const numOfChild = Math.ceil(copied.length / size);
      for (let i = 0; i < numOfChild; i += 1) {
        chunkedArr.push(copied.splice(0, size));
      }
      return chunkedArr;
    },
    back() {
      this.$router.push(this.$store.state.global.previousRoute ? this.$store.state.global.previousRoute : { name: 'account' });
    },
    closeMessage() {
      this.errorMessage = '';
    },
    async fetchData() {
      try {
        const result = await getMediafeed(
          this.selectedCustomer.id,
          this.$store.state.language.currentApi,
          this.$route.query.search,
        );

        this.loaded = true;

        const arrNewsAndMediaFeeds = result.data.result;
        this.newsAndMediaFeeds = this.chunk(arrNewsAndMediaFeeds, 3);
        // define howmany items we can have on 1 row(in this case 3)
      } catch (error) {
        this.loaded = true;
        this.errorMessage = error.message;
      }
    },
  },
  metaInfo() {
    return {
      title: this.$t('account.about.newsAndUpdates.title'),
    };
  },
};
</script>
<style scoped>
</style>
