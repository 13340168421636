<template>
  <div class="products__filter m-md-t-2">
    <fieldset>
      <collapse :open="true">
        <template v-slot:header="{open}">
          <div
            class="section-title section-title--collapse"
            :class="{'open': open}"
          >
            {{ $t('account.account.myOrders.options') }}
          </div>
        </template>

        <div class="form-group">
          <select v-model="valueInt.sort">
            <option value="claimdate">
              {{ $t('account.account.myWarranties.sort.claimdate') }}
            </option>
            <option value="newchatmessage">
              {{ $t('account.account.myWarranties.sort.newchat') }}
            </option>
          </select>
        </div>
      </collapse>
    </fieldset>
    <fieldset>
      <collapse :open="true">
        <template v-slot:header="{open}">
          <div
            class="section-title section-title--collapse"
            :class="{'open': open}"
          >
            {{ $t('account.account.myWarranties.warrantystatus') }}
          </div>
        </template>
        <ul>
          <li v-if="mapStatus.includes('open')">
            <checkbox
              v-model="valueInt.filters"
              :checked-value="{ type: 'status', value: [0] }"
            >
              <span>{{ $t('account.account.myWarranties.status.open') }}</span>
            </checkbox>
          </li>

          <li v-if="mapStatus.includes('pending')">
            <checkbox
              v-model="valueInt.filters"
              :checked-value="{ type: 'status', value: [1, 2, 3, 5, 6, 7, 8, 9, 12, 13, 16] }"
            >
              <span>{{ $t('account.account.myWarranties.status.pending') }}</span>
            </checkbox>
          </li>
          <li v-if="mapStatus.includes('closed')">
            <checkbox
              v-model="valueInt.filters"
              :checked-value="{ type: 'status', value: [4, 10, 11, 14, 15] }"
            >
              <span>{{ $t('account.account.myWarranties.status.closed') }}</span>
            </checkbox>
          </li>
          <li v-if="mapStatus.includes('rejected')">
            <checkbox
              v-model="valueInt.filters"
              :checked-value="{ type: 'status', value: [17] }"
            >
              <span>{{ $t('account.account.myWarranties.status.rejected') }}</span>
            </checkbox>
          </li>
        </ul>
      </collapse>
    </fieldset>
  </div>
</template>
<script>
import collapse from '@/components/collapse.vue';
import checkbox from '@/elements/checkbox.vue';

export default {
  components: {
    collapse,
    checkbox,
  },
  props: {
    mapStatus: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({
        sort: 'claimdate',
        filters: [],
      }),
    },
  },
  computed: {
    valueInt: {
      get() {
        return this.value;
      },
      set(newval) {
        this.$emit('input', newval);
      },
    },
  },
};
</script>
