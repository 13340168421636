<template>
  <form
    v-if="!passwordSuccess"
    @submit.prevent="save"
  >
    <bcf-aside @close="close">
      <bcfAsideHeader :close="close">
        {{ $t('account.settings.changePassword.title') }}
      </bcfAsideHeader>
      <bcfAsideContent>
        <loading-overlay :loading="inProgress">
          <Message
            v-if="passwordError"
            type="error"
            :icon="false"
            :show-close="false"
          >
            {{ $t(passwordError) }}
          </Message>
          <Message
            v-if="passwordSuccess"
            type="success"
            :icon="false"
            :show-close="false"
          >
            {{ $t('account.settings.changePassword.success') }}
          </Message>

          <div class="form-group">
            <label>{{ $t('account.settings.changePassword.currentPassword') }}</label>
            <input
              v-model="currentPassword"
              class="form-control"
              type="password"
              autocomplete="current-password"
              required
              minlength="5"
            >
          </div>
          <div class="form-group">
            <label>{{ $t('account.settings.changePassword.newPassword') }}</label>
            <input
              v-model="newPassword"
              class="form-control"
              type="password"
              autocomplete="new-password"
              required
              minlength="5"
              pattern=".*\d.*"
              :title="$t('account.settings.changePassword.validation')"
            >
          </div>
          <div class="form-group">
            <label>{{ $t('account.settings.changePassword.repeatNewPassword') }}</label>
            <input
              v-model="newPassword2"
              class="form-control"
              type="password"
              autocomplete="new-password"
              required
              minlength="5"
              :pattern="newPassword"
              :title="$t('account.settings.changePassword.newPasswordNotTheSame')"
            >
          </div>
        </loading-overlay>
      </bcfAsideContent>
      <bcfAsideFooter>
        <button
          class="button button--outline"
          :disabled="inProgress"
          @click="close"
        >
          Cancel
        </button>
        <button
          class="button button--primary"
          type="submit"
          :disabled="inProgress"
        >
          {{ $t('account.settings.changePassword.changePassword') }}
        </button>
      </bcfAsideFooter>
    </bcf-aside>
  </form>
</template>

<style scoped>
</style>

<script>
import { mapState } from 'vuex';
import bcfAside from '@/components/bcfAside.vue';
import Message from '@/components/Message.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import loadingOverlay from '@/elements/LoadingOverlay.vue';

import { changePassword } from '@/api/user';

export default {
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideFooter,
    bcfAsideContent,
    loadingOverlay,
    Message,
  },
  data() {
    return {
      currentPassword: null,
      newPassword: null,
      newPassword2: null,
      passwordError: null,
      passwordSuccess: false,
      inProgress: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  methods: {
    async save() {
      this.passwordSuccess = false;
      this.inProgress = true;
      if (this.newPassword === this.newPassword2) {
        if (this.newPassword.length > 4 && /\d/.test(this.newPassword)) {
          try {
            await changePassword(this.currentPassword, this.newPassword);
            this.passwordSuccess = true;
          } catch (err) {
            this.passwordError = err.apiMessage;
          } finally {
            this.inProgress = false;
          }
        } else {
          this.passwordError = 'account.settings.changePassword.validation';
        }
      } else {
        this.passwordError = 'account.settings.changePassword.newPasswordNotTheSame';
      }
    },
    close() {
      this.$router.push({ name: 'account' });
    },
  },
};
</script>
