<template>
  <bcfAside
    @close="closeMethod"
  >
    <bcfAsideHeader
      :close="closeMethod"
    >
      {{ feedDetail.from.name }}
    </bcfAsideHeader>
    <bcfAsideContent v-if="!loaded">
      <div>
        <loader class="m-b-2" />
      </div>
      <div v-if="errStatus">
        <Message
          :type="objMessage.type"
          :icon="false"
          :content="objMessage.content"
          :show-close="false"
        >
          <strong>{{ $t(`api.${objMessage.title}`) }}</strong>
        </Message>
      </div>
    </bcfAsideContent>
    <bcfAsideContent v-else>
      <div
        class="blog__mainimage"
        :style="`background-image:url(${ feedDetail.full_picture });`"
      >
        <a
          v-if="feedDetail.permalink_url"
          :href="feedDetail.permalink_url"
          target="_blank"
        >
          <img
            :src="require('@/assets/img/icons/Facebook_logo.svg')"
            width="20"
            height="20"
            alt="Logo"
            class="logo"
          >
        </a>
      </div>

      <time
        v-if="feedDetail.created_time"
        class="blog__date"
      >
        {{ formatDate(feedDetail.created_time) }}
      </time>
      <div
        class="blog__content"
        v-html="feedDetail.message"
      />
    </bcfAsideContent>
    <bcfAsideFooter
      v-if="feedDetail.permalink_url"
      style="justify-content: center;"
    >
      <a
        target="_blank"
        :href="`https://www.facebook.com/sharer/sharer.php?u=${feedDetail.permalink_url}`"
        class="button button--outline"
      >
        <img :src="require('@/assets/img/icons/Facebook_logo.svg')">
        {{ $t('account.about.socialFeed.share') }}
      </a>
    </bcfasidefooter>
  </bcfAside>
</template>
<script>
import { mapState } from 'vuex';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import loader from '@/elements/loader.vue';
import { NoResponseApiError } from '@/api/apiErrors';
import Message from '@/components/Message.vue';
import { formatDate } from '@/utils/dateUtils';
import { getSocialFeedDetail } from '@/api/socialmedia';

function urlify(text) {
  if (text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, url => `<a href="${url}" target="_blank">${url}</a>`);
  }
  return null;
}

function newline(text) {
  if (text) {
    const urlRegex = /[\r\n]/g;
    return text.replace(urlRegex, () => '<br>');
  }
  return null;
}

function hashtags(text) {
  if (text) {
    const urlRegex = /#[a-zA-Z.0-9_]*/g;
    return text.replace(urlRegex, hashtag => `<span class="fbhashtag">${hashtag}</span>`);
  }
  return null;
}

function tags(text) {
  if (text) {
    const urlRegex = /@[a-zA-Z.0-9_]*/g;
    return text.replace(urlRegex, tag => `<span class="fbusername">${tag}</span>`);
  }
  return null;
}

export default {
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    bcfAsideFooter,
    loader,
    Message,
  },
  props: {
    close: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      id: 0,
      feedDetail: {
        from: {
          name: '',
        },
        created_time: '',
        full_picture: '',
        message: '',
        permalink_url: '',
      },
      errStatus: false,
      objMessage: {},
      loaded: false,
    };
  },
  computed: {
    ...mapState({
      selectedCustomer: state => state.customer.selectedCustomer,
    }),
  },
  async mounted() {
    try {
      this.loaded = false;
      this.feedDetail = await getSocialFeedDetail(this.$route.params.id);
      if (this.feedDetail === undefined) {
        this.feedDetail = {};
        throw new Error('Unexpected');
      } else {
        this.feedDetail.message = tags(hashtags(newline(urlify(this.feedDetail.message))));
      }
      this.loaded = true;
    } catch (error) {
      if (error instanceof NoResponseApiError) {
        this.errStatus = true;
        this.objMessage = {
          type: 'error',
          title: 'apiNoResponseTitle',
          content: error.apiMessage,
        };
      } else {
        this.errStatus = true;
        this.objMessage = {
          type: 'error',
          title: error.message,
          content: error.message,
        };
      }
    }
  },
  methods: {
    closeMethod() {
      if (this.close) {
        this.close();
      } else {
        this.$router.push(this.$store.state.global.previousRoute ? this.$store.state.global.previousRoute : { name: 'socialFeeds' });
      }
    },
    formatDate,
  },
};
</script>

<style lang="scss">
.fbusername {
  color: gray;
}
.fbhashtag {
  color: gray;
}
</style>
