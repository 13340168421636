<template>
  <form
    enctype="multipart/form-data"
    @submit.prevent="sendFiles"
  >
    <template
      v-if="!isUploaded"
    >
      <div
        class="form-control-file"
      >
        <input
          ref="files"
          multiple
          type="file"
          @change="selectFile"
        >
      </div>
      <small
        v-if="!$v.files.required"
        class="form-text--error"
      >{{ $t(`api.multiupload`) }}</small>
      <div
        v-if="files.length > 0"
        class="upload__queue"
      >
        <div
          v-for="(file, index) in files"
          :key="index"
          class="upload__queue__item"
        >
          {{ file.name }}
          <span
            v-if="file.invalidMessage"
            :class="{'form-text--error':file.invalidMessage}"
          >&nbsp;- {{ file.invalidMessage }}</span>
          <a
            style="cursor: pointer;"
            @click.prevent="removeFile(index)"
          >&nbsp; <i class="uil uil-trash" /></a>
        </div>
      </div>
      <div v-if="message && !error">
        <small class="form-text--success"> {{ $t(`api.${message}`) }}</small>
      </div>
      <div v-else-if="message && error">
        <small class="form-text--error"> {{ $t(`api.${message}`) }}</small>
      </div>
    </template>
    <template v-else>
      <div
        v-if="defect.length > 0"
      >
        <div
          v-for="(file, index) in defect"
          :key="index"
        >
          <small>{{ file }}</small>
        </div>
      </div>
      <div v-if="message && !error">
        <small class="form-text--success"> {{ $t(`api.${message}`) }}</small>
      </div>
      <div v-else-if="message && error">
        <small class="form-text--error"> {{ $t(`api.${message}`) }}</small>
      </div>
    </template>

    <!--     <button class="w-100 button button--small button--primary">
      Upload file(s)
    </button> -->
  </form>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'MultipleUploads',
  props: {
    cusId: {
      type: String,
      default: '',
    },
    uuid: {
      type: String,
      default: '',
    },
    isUploaded: {
      type: Boolean,
      default: false,
    },
    defect: {
      type: Array,
      default: () => [],
    },
    simpleUploadFilename: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      files: [],
      response: {},
      message: '',
      error: false,
      // isUploaded: false,
      arFilenames: [],
    };
  },
  validations: {
    files: {
      required,
    },
  },
  methods: {
    removeFile(idx) {
      this.files.splice(idx, 1);
      this.arFilenames.splice(idx, 1);
      this.$emit('file-data', 'multiple', this.files);
    },
    selectFile() {
      // eslint-disable-next-line prefer-destructuring
      const files = this.$refs.files.files;
      const newFiles = [];
      // const resultFiles = [];

      for (let j = 0; j < files.length; j += 1) {
        const fileExt = files[j].name.slice(files[j].name.lastIndexOf('.'));
        const blob = files[j].slice(0, files[j].size, files[j].type);
        const newFile = new File([blob], `${this.cusId}_${uuidv4().toString()}${fileExt}`, { type: files[j].type });
        newFiles[j] = newFile;
        newFiles[j].invalidMessage = this.validate(newFile);
      }
      this.files = [...this.files, ...newFiles];
      this.$emit('file-data', 'multiple', this.files);
    },
    validate(file) {
      const allowedTypes = process.env.VUE_APP_MIMETYPE.split(',');
      // remove allowed filetype pdf for multiple upload
      allowedTypes.splice(allowedTypes.indexOf('application/pdf'), 1);
      const MAX_SIZE = process.env.VUE_APP_MAX_SIZE;
      // check filesize
      const tooLarge = file.size > MAX_SIZE;
      if (tooLarge) {
        return this.$i18n.t('api.LIMIT_FILE_SIZE');
      }
      // check for double filename in proof of purchase
      const filename = file.name;
      let hasDoubleFilenames = false;
      hasDoubleFilenames = this.arFilenames.includes(filename.toLowerCase());
      if (filename.toLowerCase() === this.simpleUploadFilename.toLowerCase() || hasDoubleFilenames) {
        return this.$i18n.t('api.LIMIT_SAME_FILENAME');
      }
      this.arFilenames.push(filename.toLowerCase());
      this.$emit('setArrMultipleFiles', this.arFilenames);
      // check for allowed filetypes
      if (!allowedTypes.includes(file.type)) {
        return this.$i18n.t('api.LIMIT_FILE_TYPES');
      }
      return '';
    },
  },
};
</script>
